const getReadingTime = (text: string) => {
  const wordCount = text
    .replace(/<[^>]*>/g, ' ')
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ').length;
  const wordsPerMinute = 225;
  const readingTime = wordCount && +(wordCount / wordsPerMinute).toFixed(0);
  const minutes = (n: number) => {
    if (n === 1) return 'minutę';
    let rest10 = n % 10;
    let rest100 = n % 100;
    if (rest10 > 4 || rest10 < 2 || (rest100 < 15 && rest100 > 11))
      return 'minut';
    return 'minuty';
  };
  return `${readingTime} ${minutes(readingTime)}`;
};
export default getReadingTime;
