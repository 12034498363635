import { Box, Typography, Button } from 'components';
import React from 'react';
import theme from 'theme';
import { convertSeconds } from 'utils/convertSeconds';
import { PlayerContext } from 'utils/PlayerContext';
import { MobileContext } from 'utils/mobileContext';
import SVG from 'react-inlinesvg';

export interface Audition {
  time?: string;
  title: string;
  src: string;
}

export interface IProps {
  data: Audition[];
  hasPlaylist: boolean;
}

export interface IAudio {
  item: Audition;
}

const RenderAudio: React.FC<IAudio> = ({ item }) => {
  const playerContext = React.useContext(PlayerContext);
  const { isMobile } = React.useContext(MobileContext);
  const [isOnPlaylist, setIsOnPlaylist] = React.useState<boolean>(false);
  const playertrack = playerContext?.player?.track;
  const track = {
    title: item.title,
    url: item.src,
    cover: '/images/cover.png',
  };
  const isNowPlaying =
    track.url === playertrack?.url && playerContext.player.isPlaying;

  const onPlayClick = () => {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        track,
        isPlaying:
          track.url === playertrack?.url
            ? !playerContext.player.isPlaying
            : true,
        isVisible: true,
      },
    });
  };

  const addToPlaylist = () => {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        playlist:
          playerContext.player.playlist.findIndex(
            (x) => x.url === track.url
          ) === -1
            ? [...playerContext.player.playlist, track]
            : playerContext.player.playlist,
      },
    });
    localStorage.setItem(
      'playlist',
      JSON.stringify([...playerContext.player.playlist, track])
    );
  };

  React.useEffect(() => {
    setIsOnPlaylist(
      playerContext.player.playlist.some((x) => x.url === track.url)
    );
  }, [playerContext.player.playlist]);

  return (
    <>
      <Box
        display="flex"
        gridGap={3}
        pb={2}
        borderBottom="1px solid"
        borderBottomColor="neutral.lightGray"
      >
        <Box borderLeft="4px solid" borderLeftColor="main.primary" />
        <Typography variant="labelBig" as="h2">
          POSŁUCHAJ
        </Typography>
      </Box>
      <Box
        display="flex"
        gridGap={3}
        borderBottom="2px solid"
        borderBottomColor="main.primary"
        py={2}
      >
        <Box
          display="flex"
          gridGap={3}
          flexDirection="column"
          alignItems="center"
          width={isMobile ? '20%' : '10%'}
          p={3}
          borderRight="1px solid"
          borderRightColor="neutral.lightGray"
        >
          <Box
            borderRadius="50%"
            width="50px"
            height="50px"
            position="relative"
            style={{ cursor: 'pointer' }}
          >
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate3d(-50%,-50%,0)"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                onClick={onPlayClick}
                as="button"
                border="none"
                width="50px"
                backgroundColor="transparent"
                cursor="pointer"
              >
                <img
                  src={isNowPlaying ? '/icons/pause.svg' : '/icons/play.svg'}
                  alt={isNowPlaying ? 'pause button' : 'play button'}
                  width="50px"
                />
              </Box>
            </Box>
          </Box>
          {item?.time ? (
            <Box>
              <Typography variant="listCaption" color="main.primary">
                {convertSeconds(+item.time)}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={isMobile ? '75%' : '70%'}
          overflow="hidden"
          borderRight={isMobile ? 'initial' : '1px solid'}
          borderRightColor="neutral.lightGray"
        >
          <Typography variant="lead" as="h3">
            {item.title}
          </Typography>
        </Box>
        {!isMobile ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="20%%"
          >
            {isOnPlaylist ? (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                py={2}
                px={20}
                backgroundColor="neutral.mediumLightGray"
              >
                <Typography variant="button" textAlign="center">
                  Dodano do playlisty
                </Typography>
              </Box>
            ) : (
              <Button
                color="main.primary"
                onClick={addToPlaylist}
                style={{ fontSize: 11, whiteSpace: 'nowrap' }}
                decorator={(isHover: boolean) => (
                  <Box position="relative" width="15px" height="15px" ml={3}>
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate3d(-50%,-50%,0)"
                      width="15px"
                      height="3px"
                      transition="background-color 0.2s"
                      backgroundColor={
                        isHover ? 'main.primary' : 'neutral.white'
                      }
                    />
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate3d(-50%,-50%,0)"
                      height="15px"
                      width="3px"
                      transition="background-color 0.2s"
                      backgroundColor={
                        isHover ? 'main.primary' : 'neutral.white'
                      }
                    />
                  </Box>
                )}
              >
                Dodaj do playlisty
              </Button>
            )}
          </Box>
        ) : null}
      </Box>
      {isMobile ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          right={0}
          bottom={0}
          width="30px"
          height="30px"
          transform="translate3d(0,50%,0)"
        >
          <Box
            onClick={addToPlaylist}
            as="button"
            border="none"
            backgroundColor="transparent"
          >
            <SVG src={isOnPlaylist ? '/icons/added.svg' : '/icons/add.svg'} />
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export const ArticlePlayer: React.FC<IProps> = ({ data, hasPlaylist }) => {
  const [currentAudition, setCurrentAudition] = React.useState(0);
  const currentColor = (currentAudition: number, change: number) => {
    if (change === 0) {
      return 'main.primary';
    }
    if (
      currentAudition + change >= 0 &&
      currentAudition + change <= data.length - 1
    ) {
      return 'neutral.white';
    }
    return 'neutral.gray';
  };
  const currentAuditionFunc = (
    setCurrentAudition: React.Dispatch<React.SetStateAction<number>>,
    currentAudition: number,
    change: number
  ) => {
    if (
      change === 0 ||
      currentAudition + change < 0 ||
      currentAudition + change >= data.length
    ) {
      return currentAudition;
    }
    if (currentAudition + change >= 0) {
      setCurrentAudition(currentAudition + change);
    } else if (currentAudition + change <= data.length) {
      setCurrentAudition(currentAudition + change);
    }
  };

  let bullets: JSX.Element[] = [];

  for (let i = -2; i < 3; i++) {
    bullets.push(
      <Box
        backgroundColor={currentColor(currentAudition, i)}
        border="1px solid"
        borderColor={theme.palette.neutral.gray}
        width={10}
        height={10}
        borderRadius="50%"
        onClick={() => {
          currentAuditionFunc(setCurrentAudition, currentAudition, i);
        }}
      />
    );
  }

  return (
    <Box as="section">
      {data.map((item, id) => {
        if (id !== currentAudition) return null;
        return (
          <Box
            display="flex"
            flexDirection="column"
            my={3}
            mt={5}
            position="relative"
            key={item.src}
          >
            <RenderAudio item={item} />
            {hasPlaylist ? (
              <Box display="flex" gridGap={3} pt={3} justifyContent="center">
                {bullets.map((el) => {
                  return el;
                })}
              </Box>
            ) : null}
          </Box>
        );
      })}
    </Box>
  );
};
