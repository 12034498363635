/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, Typography } from 'components';
import { PlayerContext } from 'utils/PlayerContext';
import styled, { css } from 'styled-components';
import Slider from 'react-slider';
import { convertSeconds } from 'utils/convertSeconds';

interface IProgress {
  progress: number;
  setProgress: (x: number) => void;
}

const StyledStreamSlider = styled(Slider)`
  ${({ theme }) => css`
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    .progress_track {
      height: 4px;
      background-color: ${theme.palette.neutral.placeholder};
    }
    .progress_track.progress_track-0 {
      background-color: ${theme.palette.main.primary};
    }
    .progress_track.progress_track-1 {
      background-color: ${theme.palette.neutral.lighterGray};
    }
    .progress_track.progress_track-1:before {
      display: block;
      content: ' ';
      position: absolute;
      left: 0;
      top: -2px;
      transform: translate(0, -100%);
      background-image: url('/icons/live_icon.svg');
      background-size: cover;
      height: 23px;
      width: 37px;
      z-index: 2;
    }
  `}
`;

const StyledSlider = styled(Slider)`
  ${({ theme }) => css`
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    .progress_track {
      height: 4px;
      background-color: ${theme.palette.neutral.placeholder};
    }
    .progress_track.progress_track-0 {
      background-color: ${theme.palette.main.primary};
    }
    .progress_track.progress_track-1 {
      background-color: ${theme.palette.neutral.lighterGray};
    }
  `}
`;

const ProgressBar: React.FC<IProgress> = ({ progress, setProgress }) => {
  const playerContext = React.useContext(PlayerContext);
  const isStream = !playerContext.player.track;
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        width="100%"
        height="10px"
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        pb={isStream ? 0 : 2}
      >
        <Box
          color="main.primary"
          cursor={isStream ? 'auto' : 'pointer'}
          display="flex"
          alignItems="center"
          height="100%"
          width="100%"
        >
          {isStream ? (
            <StyledStreamSlider
              ariaLabel="Postęp odtwarzania"
              value={progress}
              orientation="horizontal"
              trackClassName="progress_track"
              disabled
              onChange={(val) => {
                if (!isStream) {
                  setProgress(val as number);
                }
              }}
              min={0}
              max={1}
              step={0.000000000000000001}
            />
          ) : (
            <StyledSlider
              ariaLabel="Postęp odtwarzania"
              value={progress}
              orientation="horizontal"
              trackClassName="progress_track"
              onChange={(val) => {
                if (!isStream) {
                  setProgress(val as number);
                }
              }}
              min={0}
              max={1}
              step={0.000000000000000001}
            />
          )}
        </Box>
      </Box>
      {!isStream && (
        <Box display="flex" justifyContent="space-between" opacity={0.8}>
          <Typography variant="date">
            {playerContext.player.duration
              ? convertSeconds(progress * Number(playerContext.player.duration))
              : '00:00'}
          </Typography>
          <Typography variant="date">
            {playerContext.player.duration
              ? convertSeconds(Number(playerContext.player.duration))
              : '00:00'}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProgressBar;
