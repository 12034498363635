/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable radix */
import { useState, useEffect } from 'react';

const useWindowWidth = (): {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
} => {
  const hasWindow = typeof window !== 'undefined';
  const [width, setWidth] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  const mobileMedia = hasWindow && window.matchMedia('(max-width: 768px)');
  const tabletMedia =
    hasWindow &&
    window.matchMedia('(min-width: 769px) and (max-width: 1199px)');
  const desktopMedia = hasWindow && window.matchMedia('(min-width: 1200px)');

  const handleMobileChange = (e: { matches: any }) => {
    if (e.matches) {
      setWidth({ isMobile: true, isTablet: false, isDesktop: false });
    }
  };
  const handleTabletChange = (e: { matches: any }) => {
    if (e.matches) {
      setWidth({ isMobile: false, isTablet: true, isDesktop: false });
    }
  };
  const handleDesktopChange = (e: { matches: any }) => {
    if (e.matches) {
      setWidth({ isMobile: false, isTablet: false, isDesktop: true });
    }
  };
  useEffect(() => {
    if (mobileMedia) {
      handleMobileChange(mobileMedia);
      mobileMedia.addEventListener('change', handleMobileChange);
    }
    if (tabletMedia) {
      handleTabletChange(tabletMedia);
      tabletMedia.addEventListener('change', handleTabletChange);
    }
    if (desktopMedia) {
      handleDesktopChange(desktopMedia);
      desktopMedia.addEventListener('change', handleDesktopChange);
    }

    return () => {
      if (mobileMedia) {
        mobileMedia.removeEventListener('change', handleMobileChange);
      }
      if (tabletMedia) {
        tabletMedia.removeEventListener('change', handleTabletChange);
      }
      if (desktopMedia) {
        desktopMedia.removeEventListener('change', handleDesktopChange);
      }
    };
  }, []);

  return {
    ...width,
  };
};

export default useWindowWidth;
