/* eslint-disable object-curly-newline */
import { Typography, Box } from 'components';
import React from 'react';

import styled from 'styled-components';
import SVG from 'react-inlinesvg';

interface IProps {
  href?: string;
  name?: string;
  decorator?: string;
}

const MobileLi = styled.li`
  overflow: hidden;
  height: 36px;
  margin: 0;
  display: flex;
`;
const MobileLink = styled.a`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  display: table;
  width: 100%;
  height: 36px;
`;

export const PRNavbarItem: React.FC<IProps> = ({ href, name, decorator }) => {
  if (!href || !name) return null;
  return (
    <>
      <MobileLi>
        <MobileLink href={href}>
          {decorator && <SVG src={decorator} />}
          <Box
            display="table-cell"
            width="100%"
            verticalAlign="middle"
            borderBottom="1px solid"
            borderColor="neutral.lighterGray"
          >
            <Typography color="neutral.white" letterSpacing="1px">
              {name}
            </Typography>
          </Box>
        </MobileLink>
      </MobileLi>
    </>
  );
};

export default PRNavbarItem;
