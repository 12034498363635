export const convertSeconds = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  const minutesString = String(minutes).length < 2 ? `0${minutes}` : minutes;
  const secondsString = String(seconds).length < 2 ? `0${seconds}` : seconds;

  return `${hours ? `${hours}:` : ''}${minutesString}:${secondsString}`;
};
