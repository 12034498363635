import { Box, Typography, Link } from 'components';
import React from 'react';
import SVG from 'react-inlinesvg';

interface IProps {
  src: string;
  title: string;
  href: string;
}

export const HeaderDropdownItem: React.FC<IProps> = ({ src, title, href }) => {
  return (
    <Link href={href}>
      <Box display="flex" width="100%" alignItems="center" marginBottom="40px">
        <Box
          width="20%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={20}
        >
          {src && <SVG style={{ height: '100%' }} src={src} />}
        </Box>
        <Box width="60%">
          <Typography
            variant="headerDropdownItem"
            color="neutral.light"
            textAlign="left"
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};
