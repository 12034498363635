import { SpecialBroadcastBox, Box } from 'components';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { ShowSound } from 'models';
import { MobileContext } from 'utils/mobileContext';

export interface ISpecialBroacastSection {
  matysiakowieContent: ShowSound[];
  jezioranyContent: ShowSound[];
}

export const SpecialBroacastSection: React.FC<ISpecialBroacastSection> = ({
  matysiakowieContent,
  jezioranyContent,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <section>
      <Container fluid md>
        <Box pt={isMobile ? 0 : 4} pb={0}>
          {isMobile ? (
            <>
              <Row>
                <Col xs={12}>
                  <Box height={200}>
                    <SpecialBroadcastBox
                      background="jeziorany-bg-mobile.png"
                      href="https://www.polskieradio.pl/357,Teatr-Polskiego-Radia/7245,W-Jezioranach-odcinki/"
                      audio={jezioranyContent}
                    />
                  </Box>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Box height={200} mt={4} mb={5}>
                    <SpecialBroadcastBox
                      background="matysiakowie-bg-mobile.png"
                      href="https://www.polskieradio.pl/241"
                      matysiakowie
                      audio={matysiakowieContent}
                    />
                  </Box>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col xs={6}>
                <Box height={231}>
                  <SpecialBroadcastBox
                    background="jeziorany-bg.png"
                    href="https://www.polskieradio.pl/357,Teatr-Polskiego-Radia/7245,W-Jezioranach-odcinki/"
                    audio={jezioranyContent}
                  />
                </Box>
              </Col>
              <Col xs={6}>
                <Box height={231}>
                  <SpecialBroadcastBox
                    background="matysiakowie-bg.png"
                    href="https://www.polskieradio.pl/241"
                    matysiakowie
                    audio={matysiakowieContent}
                  />
                </Box>
              </Col>
            </Row>
          )}
        </Box>
      </Container>
    </section>
  );
};

export default SpecialBroacastSection;
