import React from 'react';
import styled, { css } from 'styled-components';
import { Box, HeaderDropdownItem } from 'components';
import { Row, Col, Container } from 'react-grid-system';
import { system, BackgroundColorProps } from 'styled-system';

interface IDivide extends BackgroundColorProps {
  backgroundColor: string;
}

const Divide = styled.span<IDivide>(
  system({
    backgroundColor: {
      property: 'backgroundColor',
      scale: 'palette',
    },
  }),
  ({ backgroundColor }) => css`
    width: 2px;
    height: calc(100% - 40px);
    opacity: 0.5;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${backgroundColor};
  `
);

export const HeaderDropdown: React.FC = () => {
  return (
    <Box
      position="fixed"
      paddingTop="60px"
      paddingBottom="20px"
      width="100vw"
      left="0"
      top="139px"
      backgroundColor="neutral.dark"
      zIndex={100}
      borderBottom="4px solid"
      borderColor="main.primary"
      display="flex"
      alignItems="center"
    >
      <Container style={{ width: '100%' }}>
        <Row>
          <Col xs={2.8}>
            <HeaderDropdownItem
              src="/icons/kanalyinternetowe.svg"
              title="KANAŁY INTERNETOWE"
              href="https://moje.polskieradio.pl/"
            />
            <HeaderDropdownItem
              src="/icons/historia.svg"
              title="HISTORIA"
              href="https://www.polskieradio.pl/39,Historia"
            />
            <HeaderDropdownItem
              src="/icons/serwisyhistoryczne.svg"
              title="SERWISY HISTORYCZNE"
              href="https://www.polskieradio.pl/219,Serwisy-Specjalne-Polskiego-Radia"
            />
            <Divide backgroundColor="neutral.light" />
          </Col>
          <Col xs={2.8}>
            <HeaderDropdownItem
              src="/icons/rckl.svg"
              title="RADIOWE CENTRUM KULTURY LUDOWEJ"
              href="https://www.polskieradio.pl/377"
            />
            <HeaderDropdownItem
              src="/icons/studioreportazu.svg"
              title="STUDIO REPORTAŻU I DOKUMENTU POLSKIEGO RADIA"
              href="https://reportaz.polskieradio.pl/"
            />
            <HeaderDropdownItem
              src="/icons/teatr_ikona.svg"
              title="TEATR POLSKIEGO RADIA W WARSZAWIE"
              href="https://www.polskieradio.pl/357"
            />
            <HeaderDropdownItem
              src="/icons/orkiestra.svg"
              title="ORKIESTRA POLSKIEGO RADIA W WARSZAWIE"
              href="https://www.polskieradio.pl/79,Polska-Orkiestra-Radiowa"
            />
            <Divide backgroundColor="neutral.light" />
          </Col>
          <Col xs={2.8}>
            <HeaderDropdownItem
              src="/icons/lektury.svg"
              title="LEKTURY DO SŁUCHANIA"
              href="https://www.polskieradio.pl/211,Powiesci-na-antenie"
            />
            <HeaderDropdownItem
              src="/icons/ramowka.svg"
              title="RAMÓWKA"
              href="https://polskieradio.pl/ramowka"
            />
            <HeaderDropdownItem
              src="/icons/czestotliwosci.svg"
              title="CZĘSTOTLIWOŚCI"
              href="https://www2.polskieradio.pl/czestotliwosci/"
            />
            <Divide backgroundColor="neutral.light" />
          </Col>
          <Col xs={2.8}>
            <HeaderDropdownItem
              src="/icons/sklep.svg"
              title="SKLEP"
              href="https://sklep.polskieradio.pl"
            />
            <HeaderDropdownItem
              src="/icons/szkolenia.svg"
              title="SZKOLENIA"
              href="https://cem.polskieradio.pl"
            />
            <HeaderDropdownItem
              src="/icons/akademia.svg"
              title="AKADEMIA RADIOWA"
              href="https://www.polskieradio.pl/275/8869,Akademia"
            />
          </Col>
        </Row>
      </Container>
    </Box>
  );
};
