/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from 'components';
import { ReactNode } from 'react';
import 'simplebar/dist/simplebar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import { Navigation, Scrollbar } from 'swiper';
import { v4 as uuid } from 'uuid';
import SVG from 'react-inlinesvg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import styled, { css } from 'styled-components';

export interface IProps {
  id: string;
  rounded?: 'tr' | 'tl';
  title?: string;
  height?: string | number;
  data: any[];
  render: (...args: any[]) => ReactNode;
  onSwiper?: ((swiper: SwiperType) => void) | undefined;
}
const SwiperOverrides = styled.div`
  width: 100%;
  height: 100%;
  .swiper-scrollbar {
    background: none;
    .swiper-scrollbar-drag {
      width: 2px;
      margin: 0 0 0 auto;
      background-color: ${({ theme }) => theme.palette.main.primary};
      border-radius: 0;
    }
  }
`;

const StyledButton = styled.button`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    transition: 0.3s;
    cursor: pointer;
    transform: translate3d(0%, -50%, 0%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.neutral.white};
    border: 1px solid ${theme.palette.main.primary};
    svg,
    svg * {
      fill: ${theme.palette.main.primary} !important;
    }
    &:hover {
      border-color: ${theme.palette.neutral.white};
      background-color: ${theme.palette.main.primary};
      svg,
      svg * {
        fill: ${theme.palette.neutral.white} !important;
      }
    }
  `}
`;

const ArrowButton: React.FC<{
  className: string;
  right?: string;
  direction: 'up' | 'down';
}> = ({ className, direction }) => {
  return (
    <StyledButton
      className={className}
      aria-label={direction === 'up' ? 'W górę' : 'W dół'}
    >
      <SVG
        src={
          direction === 'up' ? '/images/Arrow_up.svg' : '/images/Arrow_down.svg'
        }
      />
    </StyledButton>
  );
};

export const SwiperBox: React.FC<IProps> = ({
  rounded,
  title,
  height = 300,
  data = [],
  render,
  onSwiper,
  id,
}) => {
  return (
    <Box
      height={{ tablet: height, mobile: 170 }}
      width={{ tablet: 'auto', mobile: 300 }}
      ml={{ tablet: 0, mobile: 15 }}
    >
      <Box
        backgroundColor="main.primary"
        borderTopLeftRadius={{
          tablet: rounded === 'tl' ? '16px' : 0,
          mobile: '16px',
        }}
        borderTopRightRadius={{
          tablet: rounded === 'tr' ? '16px' : 0,
          mobile: '16px',
        }}
        height="48px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pl={3}
        pr={3}
      >
        <Typography as="p" variant="caption" color="neutral.white">
          {title}
        </Typography>
        <Box
          height="100%"
          display={{ tablet: 'flex', mobile: 'none' }}
          alignItems="center"
        >
          <ArrowButton direction="up" className={`${id}_swiper_prev`} />
          <ArrowButton direction="down" className={`${id}_swiper_next`} />
        </Box>
      </Box>
      <Box
        border="1px solid"
        borderColor="neutral.gray"
        borderTop="none"
        height={{ tablet: +height - 48, mobile: 122 }}
      >
        <SwiperOverrides>
          <Swiper
            direction="vertical"
            slidesPerView="auto"
            spaceBetween={5}
            slidesPerGroup={1}
            style={{ maxHeight: '100%' }}
            onSwiper={onSwiper}
            modules={[Navigation, Scrollbar]}
            scrollbar={{ hide: false }}
            navigation={{
              prevEl: `.${id}_swiper_prev`,
              nextEl: `.${id}_swiper_next`,
            }}
          >
            {data?.map((elem) => (
              <SwiperSlide key={uuid()}>{render(elem)}</SwiperSlide>
            ))}
          </Swiper>
        </SwiperOverrides>
      </Box>
    </Box>
  );
};
