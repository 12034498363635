import { ScheduleFetchBox, NowPlayingBox, PlaylistFetchBox } from 'components';
import { ScheduleProgram, ShowPlaylist } from 'models';

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { MobileContext } from 'utils/mobileContext';
import styled from 'styled-components';
import theme from 'theme';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

export const ScrollContainer = styled.div`
  width: 100%;
  display: flex;
  .simplebar-track.simplebar-horizontal {
    background: ${theme.palette.neutral.grayWithOpacity};
    height: 2px;
    right: 0;
    bottom: 0;
    overflow: visible;
  }
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 2px;
    right: 0;
    top: 0;
    background: ${theme.palette.main.primary};
  }
`;

export const MainSchedulePlaylist: React.FC<{
  scheduleContent: ScheduleProgram[];
  playlistContent: ShowPlaylist[];
}> = ({ scheduleContent, playlistContent }) => {
  const { isDesktop } = React.useContext(MobileContext);

  return (
    <Container fluid md>
      {isDesktop ? (
        <Row>
          <Col xs={3} style={{ paddingRight: 9 }}>
            {scheduleContent && <ScheduleFetchBox list={scheduleContent} />}
          </Col>
          <Col xs={6} style={{ paddingRight: 9, paddingLeft: 9 }}>
            <NowPlayingBox />
          </Col>
          <Col xs={3} style={{ paddingLeft: 9 }}>
            <PlaylistFetchBox list={playlistContent} />
          </Col>
        </Row>
      ) : (
        <ScrollContainer>
          <SimpleBar style={{ width: '100%' }}>
            <Row
              style={{
                display: 'inline-flex',
                flexWrap: 'nowrap',
                marginLeft: 0,
                marginRight: 0,
                marginBottom: `${theme.space[3]}px`,
              }}
            >
              <NowPlayingBox />
              {scheduleContent && <ScheduleFetchBox list={scheduleContent} />}
              {/* <PlaylistFetchBox list={playlistContent} /> */}
            </Row>
          </SimpleBar>
        </ScrollContainer>
      )}
    </Container>
  );
};
