import { Box, Typography } from 'components';
import React from 'react';
import styled from 'styled-components';
import { getTimeOfTheDay } from 'utils/date';

interface ITimeBox {
  isActive: boolean;
}

const TimeBox = styled.div<ITimeBox>`
  cursor: pointer;
  user-select: none;

  span {
    transition: color 0.15s;
    color: ${({ isActive, theme }) =>
      isActive && theme.palette.main.primary} !important;
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.palette.neutral.lighterGray};
    }
  }
`;

interface ITimePicker {
  selectedTime: string;
  setSelectedTime: (time: string) => void;
}

export const TimePicker: React.FC<ITimePicker> = ({
  selectedTime,
  setSelectedTime,
}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box
        borderBottom="1px solid"
        borderRight="1px solid"
        borderColor="neutral.grayWithOpacity"
        p={1}
      >
        <Typography variant="sectionTitle" color="main.primary" lineHeight={1}>
          GODZINA
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mx={4}
      >
        {getTimeOfTheDay().map((num) => (
          <TimeBox
            isActive={selectedTime === num}
            onClick={() => setSelectedTime(num)}
          >
            <Typography
              as="span"
              variant="lead3"
              fontWeight={700}
              color="neutral.grayWithOpacity"
            >
              {num}
            </Typography>
          </TimeBox>
        ))}
      </Box>
    </Box>
  );
};
