/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { PlayerContext } from 'utils/PlayerContext';
import { BaseReactPlayerProps } from 'react-player/base';
import browserStorage from 'store';
import { Row, Col, Container } from 'react-grid-system';
import { Box, Typography } from 'components';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { motion } from 'framer-motion';
import { convertFileExtension } from 'utils/convertFileExtension';
import {
  onPlaylistItemClick,
  deleteFromPlaylist,
  onEnded,
  onPlayClick,
  closePlayer,
  setLocalPlayerState,
} from 'utils/player';
import theme from 'theme';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import useHover from 'utils/useHover';
import isLast from 'utils/isLast';
import { MobileContext } from 'utils/mobileContext';
import usePersistState from 'utils/usePersistState';
import Image from 'next/image';
import GrapPlayer from 'components/organisms/RMF/GrapPlayer';
import PlayerVolumeControls from '../VolumeControls';
import ProgressBar from '../ProgressBar';

interface ITrack {
  id?: number;
  title?: string;
  artist?: string;
  cover?: string;
  url: string;
  airTime?: string;
}

export const PlayerAlbumCover = styled.img`
  height: 47px;
  border: 1px solid white;
`;

export const StyledSvg = styled(SVG)`
  width: 11px;
  height: auto;
`;

interface IButton {
  cursor: string;
}

export const BoxWithSvg = styled.button<IButton>`
  display: flex;
  align-items: center;
  border: transparent;
  shadow: none;
  padding: 0;
  background: none;
  cursor: ${({ cursor }) => cursor};
  svg * {
    fill: ${theme.palette.neutral.dark} !important;
  }
`;

export const ScrollContainer = styled.div`
  width: 100%;
  .simplebar-track {
    background: ${theme.palette.neutral.grayWithOpacity};
    width: 3px;
    right: 20px;
    top: 15px;
    bottom: 15px;
    overflow: visible;
    z-index: 9999;
  }
  .simplebar-scrollbar {
    width: 3px;
    right: 0;
    background: ${theme.palette.main.primary};
    z-index: 9999;
  }
  .simplebar-track.simplebar-horizontal {
    display: none;
  }
`;

const RenderPlaylist: React.FC<{ playlistItem: ITrack }> = ({
  playlistItem,
}) => {
  const [hoveredRef, isHover] = useHover<HTMLDivElement>();
  const playerContext = useContext(PlayerContext);
  const track = playerContext?.player?.track;
  const isNowPlaying = playlistItem.url === track?.url;

  if (!playlistItem) return null;
  return (
    <Row
      key={playlistItem.url + playlistItem.artist + playlistItem.title}
      style={{
        height: 49,
        marginRight: 60,
        position: 'relative',
        borderBottom: track
          ? isLast(
              playerContext.player.playlist.indexOf(track),
              playerContext.player.playlist.length
            )
            ? '1px solid transparent'
            : `1px solid ${theme.palette.neutral.grayWithOpacity}`
          : '',
      }}
    >
      <Box
        position="absolute"
        display={isHover ? 'block' : 'none'}
        left="25%"
        top="50%"
        transform="translate(0, -50%)"
        p={12}
        backgroundColor="main.primary"
        zIndex={101}
      >
        <Box
          width="9px"
          height="9px"
          position="absolute"
          backgroundColor="main.primary"
          transform="rotate(45deg) translate(0, -50%)"
          left="-8px"
          top="50%"
          zIndex={100}
        />
        <Typography variant="caption2" color="neutral.white">
          {playlistItem.title}
        </Typography>
      </Box>
      <Col xs={9} style={{ display: 'flex' }}>
        <Box
          width={60}
          height={49}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            as="button"
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            border="none"
            backgroundColor="transparent"
            p="0"
            onClick={() => onPlaylistItemClick(playlistItem, playerContext)}
            aria-label={playlistItem.title}
          >
            <SVG
              src={
                playerContext.player.isPlaying && isNowPlaying
                  ? '/icons/player_playlist_pause.svg'
                  : '/icons/player_playlist_play.svg'
              }
            />
          </Box>
        </Box>
        <Box
          ml={3}
          display="flex"
          alignItems="center"
          opacity={0.8}
          ref={hoveredRef}
        >
          <span
            style={{
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              display: '-webkit-inline-box',
            }}
          >
            <Typography
              variant="caption"
              color={isNowPlaying ? 'main.primary' : 'neutral.dark'}
              opacity={isNowPlaying ? 1 : 0.8}
            >
              {playlistItem.title}
            </Typography>
          </span>
        </Box>
      </Col>
      <Col xs={2.2}>
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="listCaption">{playlistItem.airTime}</Typography>
        </Box>
      </Col>
      <Col xs={0.8}>
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginLeft="-8px"
        >
          <Box
            as="button"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            border="none"
            backgroundColor="transparent"
            p="0"
            onClick={() => deleteFromPlaylist(playlistItem, playerContext)}
            aria-label="usuń z playlisty"
          >
            <SVG src="/icons/remove_track.svg" />
          </Box>
        </Box>
      </Col>
    </Row>
  );
};

interface Props {
  isHotTopic: boolean;
}

export const PlayerDesktop: React.FC<Props> = ({ isHotTopic }) => {
  const [volume, setVolume] = useState<number>(0.5);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [isPlaylistOpen, setIsPlaylistOpen] = useState<boolean>(false);
  const [hoveredRef, isHovered] = useHover<HTMLDivElement>();
  const playerContext = React.useContext(PlayerContext);
  const playerRef = React.useRef<null | ReactPlayer>(null);
  const { isTablet } = React.useContext(MobileContext);
  const [isFirstPlay, setIsFirstPlay] = React.useState(true);
  const [isPlayerPlaying, setIsPlayerPlaying] = React.useState(false);
  const [sessionVisible, setSessionVisible] = usePersistState(
    'isVisible',
    browserStorage.get('isVisible') ? true : false
  );
  const track = playerContext?.player?.track;
  const stream = playerContext?.player?.stream;
  const isStreamPlaying = !track && playerContext.player.isPlaying;
  const getSeconds = (hms: string): number => {
    const [hours, minutes, seconds] = hms?.split(':') || ['0', '0', '0'];
    return +hours * 60 * 60 + +minutes * 60 + +seconds;
  };
  const getCurrentTime = () => {
    let d = new Date();
    let h = d.getHours();
    let m = d.getMinutes();
    let s = d.getSeconds();
    return `${h}:${m}:${s}`;
  };
  const streamDuration =
    getSeconds(playerContext.player.stream.stopHour) -
    getSeconds(playerContext.player.stream.startHour);
  const streamProgress =
    getSeconds(playerContext.player.stream.stopHour) -
    getSeconds(getCurrentTime());
  const currentStreamProgress =
    streamProgress > 0 ? 1 - streamProgress / streamDuration : 0.99;
  const gemiusPlayer = useRef<any>(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.GemiusPlayer) {
      const player = new window.GemiusPlayer(
        'Jedynka - Polskie Radio',
        'ByNF1isdtexh7lOTa2Lgr3YZXkEZHq_fvaoXHc3hfgP.y7',
        {
          volume: 100,
        }
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      player.setVideoObject(playerRef.current.wrapper.children[0]);
      gemiusPlayer.current = player;

      return () => {
        player.dispose();
      };
    }
  }, [playerContext]);

  const onReady = (x: BaseReactPlayerProps) => {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        duration: x.getDuration(),
      },
    });
  };

  useEffect(() => {
    if (isFirstPlay) {
      // const playButton = document.querySelector('#gr-ad-playButton');
      // if (playButton) {
      //   // @ts-ignore
      //   playButton?.click();
      // }
      if (typeof window !== 'undefined' && 'grapTrigger' in window) {
        // @ts-ignore
        window.grapTrigger('toggle');
      }
    } else if (playerContext.player.isPlaying) {
      setIsPlayerPlaying(true);
    } else {
      setIsPlayerPlaying(false);
    }
  }, [playerContext.player.isPlaying, isFirstPlay]);

  useEffect(() => {
    if (
      gemiusPlayer.current &&
      playerContext.player.isStream &&
      playerContext.player.isPlaying
    ) {
      gemiusPlayer.current.newProgram('stream', {
        programName: playerContext.player.stream.title,
        programType: 'audio',
        duration: -1,
      });
    } else if (gemiusPlayer.current && playerContext.player.isPlaying) {
      gemiusPlayer.current.newProgram('track', {
        programName: playerContext.player.track?.title,
        duration: playerContext.player.duration,
        programType: 'audio',
      });
    }
  }, [playerContext, gemiusPlayer.current]);

  const onProgress = (progress: { played: number }) => {
    if (!track) {
      setProgress(currentStreamProgress);
    } else if (progress?.played) {
      setProgress(progress.played);
    }
  };

  const togglePlaylist = () => {
    setIsPlaylistOpen(!isPlaylistOpen);
  };

  useEffect(() => {
    setProgress(0);
  }, [track]);

  useEffect(() => {
    setSessionVisible(playerContext.player.isVisible);
  }, [playerContext.player.isVisible]);

  useEffect(() => {
    setLocalPlayerState(playerContext, sessionVisible, false);
  }, []);

  const handlePlay = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'play', {
          autoPlay: false,
        });
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'play', {
          autoPlay: false,
        });
      }
    }
  };

  const handlePause = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'pause');
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'pause');
      }
    }
  };

  const handleEnded = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'complete');
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'complete');
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    track && onEnded(playerContext, track);
  };

  const handleStop = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'stop');
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'stop');
      }
    }
  };

  const handleSeek = (e: any) => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', e.playedSeconds, 'seek');
      } else {
        gemiusPlayer.current.programEvent('track', e.playedSeconds, 'seek');
      }
    }
  };

  const handleBuffer = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'buffer');
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'buffer');
      }
    }
  };

  const handleClose = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'close');
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'close');
      }
    }
    closePlayer(playerContext, setIsPlaylistOpen);
  };

  return (
    <Box width="100%" backgroundColor="neutral.gray">
      <ReactPlayer
        ref={playerRef}
        volume={volume}
        width={0}
        height={0}
        progressInterval={200}
        playing={isPlayerPlaying}
        onReady={onReady}
        onProgress={onProgress}
        onEnded={handleEnded}
        onPlay={handlePlay}
        onPause={handlePause}
        onBuffer={handleBuffer}
        onSeek={handleSeek}
        onClick={handleStop}
        url={
          convertFileExtension(track?.url, 'mp3') ||
          playerContext.player.stream.url
        }
      />
      {typeof window !== 'undefined' && (
        <GrapPlayer
          onPlay={() => {
            setTimeout(() => {
              setIsFirstPlay(false);
              setIsPlayerPlaying(true);
            }, 0);
          }}
          onChange={(state: any) => console.log(state)}
        />
      )}
      <motion.div
        style={{
          position: 'fixed',
          bottom: isHotTopic ? '30px' : 0,
          zIndex: 99,
          width: '100%',
          maxHeight: 300,
          height: 0,
        }}
        animate={
          playerContext.player.isVisible
            ? {
                height: isPlaylistOpen
                  ? 60 + 49 * playerContext.player.playlist.length
                  : 60,
              }
            : { height: 0 }
        }
        transition={{ ease: 'easeOut', duration: 0.3 }}
      >
        <Box backgroundColor="neutral.gray" overflowY="hidden">
          <Container>
            <Row style={{ position: 'relative' }}>
              <Box
                position="absolute"
                display={isHovered ? 'block' : 'none'}
                left="25%"
                top="50%"
                transform="translate(0, -50%)"
                p={12}
                backgroundColor="main.primary"
                zIndex={101}
              >
                <Box
                  width="9px"
                  height="9px"
                  position="absolute"
                  backgroundColor="main.primary"
                  transform="rotate(45deg) translate(0, -50%)"
                  left="-8px"
                  top="50%"
                  zIndex={100}
                />
                <Typography variant="caption2" color="neutral.white">
                  {track?.title || stream?.title}
                </Typography>
              </Box>
              <Col xs={3.2} style={{ display: 'flex' }}>
                <Box
                  width={60}
                  height={60}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="neutral.playerGray"
                >
                  <Box
                    cursor="pointer"
                    height={32}
                    p={0}
                    as="button"
                    border="none"
                    backgroundColor="transparent"
                  >
                    <SVG
                      src={
                        playerContext.player.isPlaying
                          ? '/icons/pause_player.svg'
                          : '/icons/play_player.svg'
                      }
                      onClick={() => {
                        onPlayClick(playerContext, isStreamPlaying);
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  ml={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  opacity={0.8}
                  ref={hoveredRef}
                >
                  <span
                    style={{
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      display: '-webkit-inline-box',
                    }}
                  >
                    <Typography
                      variant="playerTitle"
                      color="neutral.dark"
                      maxWidth={250}
                    >
                      {track?.title || stream?.title}
                    </Typography>
                  </span>
                  <Typography variant="playerHour" color="neutral.dark">
                    {track?.airTime}
                  </Typography>
                </Box>
              </Col>
              <Col xs={4}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                >
                  <ProgressBar
                    progress={progress}
                    setProgress={(val) => playerRef?.current?.seekTo(val)}
                  />
                </Box>
              </Col>
              <Col xs={3}>
                <Box height="100%" display="flex" alignItems="center">
                  <Image
                    src={track?.cover || '/images/cover.png'}
                    alt="cover"
                    height={47}
                    width={47}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    ml={3}
                    opacity={0.8}
                  >
                    {track?.artist && (
                      <Typography
                        variant="playerArtist"
                        color="neutral.dark"
                        maxWidth={200}
                      >
                        {track?.artist}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Col>
              <Col xs={1.8} style={{ paddingLeft: 0 }}>
                <Box height="100%" display="flex" alignItems="center">
                  {!isTablet && (
                    <PlayerVolumeControls
                      volume={isMuted ? 0 : volume}
                      setVolume={setVolume}
                      isMuted={isMuted}
                      setIsMuted={setIsMuted}
                    />
                  )}
                  <Box
                    height="100%"
                    py={10}
                    alignSelf="end"
                    display="flex"
                    alignItems="center"
                    px={2}
                    cursor="pointer"
                    backgroundColor={
                      isPlaylistOpen && playerContext.player.playlist.length > 0
                        ? 'neutral.light'
                        : 'transparent'
                    }
                    onClick={
                      playerContext.player.playlist.length > 0
                        ? togglePlaylist
                        : () => null
                    }
                    transition="0.3s"
                  >
                    <BoxWithSvg
                      cursor={
                        playerContext.player.playlist.length > 0
                          ? 'pointer'
                          : 'auto'
                      }
                    >
                      <Typography
                        variant="caption3"
                        color="neutral.dark"
                        mr={2}
                        opacity={
                          playerContext.player.playlist.length > 0 ? 0.8 : 0.3
                        }
                      >
                        PLAYLISTA
                      </Typography>
                      {playerContext.player.playlist.length > 0 && (
                        <StyledSvg src="/images/Arrow_down.svg" />
                      )}
                    </BoxWithSvg>
                  </Box>
                  <Box
                    ml={3}
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    opacity={0.8}
                    as="button"
                    border="none"
                    backgroundColor="transparent"
                  >
                    <SVG src="/icons/close_player.svg" onClick={handleClose} />
                  </Box>
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
        <Box width="100%" height="100%" backgroundColor="neutral.light">
          <Container>
            <ScrollContainer>
              <SimpleBar style={{ height: 240, width: '100%' }}>
                {playerContext?.player?.playlist?.map((track: ITrack) => (
                  <RenderPlaylist
                    key={track.url + track.artist + track.title}
                    playlistItem={track}
                  />
                ))}
              </SimpleBar>
            </ScrollContainer>
          </Container>
        </Box>
      </motion.div>
    </Box>
  );
};

export default PlayerDesktop;
