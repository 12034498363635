/* eslint-disable object-curly-newline */
import React from 'react';

import styled, { css } from 'styled-components';

import {
  system,
  borderColor,
  BorderColorProps,
  LayoutProps,
  layout,
} from 'styled-system';

interface InputProps extends BorderColorProps, LayoutProps {
  isSearch?: boolean;
  borderColor: string;
  pLeft?: number;
  pRight?: number;
}

const Input = styled.input<InputProps>(
  borderColor,
  layout,
  system({
    borderColor: {
      property: 'borderColor',
      scale: 'palette',
    },
    pLeft: {
      property: 'paddingLeft',
      scale: 'space',
    },
    pRight: {
      property: 'paddingRight',
      scale: 'space',
    },
  }),
  () => {
    return css`
      border-radius: 20px;
      height: 35px;
      flex: 1;
      display: flex;
      align-items: center;
      width: 100%;
      transition: 0.3s;

      border-width: 1px;
      border-style: solid;
    `;
  }
);

const InputContainer = styled.div<InputProps>(borderColor, () => {
  return css<InputProps>`
    width: 100%;
    display: flex;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 2px;
      height: 80%;
      right: 50px;
      top: 10%;

      display: ${({ isSearch }) => (isSearch ? 'block' : 'none')};
      ${system({
        borderColor: {
          property: 'backgroundColor',
          scale: 'palette',
        },
      })}
    }
  `;
});

const InputLabel = styled.label`
  visibility: hidden;
  width: 0;
`;
interface IProps {
  id: string;
  label: string;
  isSearch: boolean;
  borderColor: string;
  placeholder: string;
  pLeft: number;
  pRight: number;
  onChange: (x: string) => void;
  inputValue: string;
  onKeyPress: (x: string) => void;
}

export const RoundedInput: React.FC<IProps> = ({
  id,
  label,
  isSearch,
  borderColor,
  placeholder,
  pLeft,
  pRight,
  inputValue,
  onChange,
  onKeyPress,
}) => {
  return (
    <InputContainer borderColor={borderColor} isSearch={isSearch}>
      <Input
        aria-label={label}
        id={id}
        borderColor={borderColor}
        placeholder={placeholder}
        pLeft={pLeft}
        pRight={pRight}
        value={inputValue}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => onKeyPress(e.key)}
      />
      <InputLabel htmlFor={id}>{label}</InputLabel>
    </InputContainer>
  );
};

export default RoundedInput;
