import { Box, Typography, Link } from 'components';
import { ShowItem } from 'models';
import React from 'react';
import useHover from 'utils/useHover';

interface IProps {
  color?: string;
  hoveredColor?: string;
  show: ShowItem;
}

export const ShowArticleBox: React.FC<IProps> = ({
  color = 'neutral.dark',
  hoveredColor = 'main.primary',
  show,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  return (
    <Link href={`/audycje/${show?.categoryId}`}>
      <Box ref={hoverRef} transition="0.3s" height="100%">
        <Box
          height="80%"
          position="relative"
          overflow="hidden"
          backgroundImage={`url(${show?.photo || '/images/shareCard.jpg'})`}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          aspectRatio="16 / 9"
        >
          <Box
            height="100%"
            width="100%"
            position="absolute"
            overflow="hidden"
            backgroundColor={isHover ? 'rgba(0, 0, 0, 0.4)' : 'transparent'}
            transition="0.3s"
          />
        </Box>
        <Box mt={2}>
          <Typography
            variant="lead"
            color={isHover ? hoveredColor : color}
            display="inline"
            transition="0.3s"
            fill={isHover ? hoveredColor : color}
          >
            <span
              style={{
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-inline-box',
              }}
            >
              {show?.name}
            </span>
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default ShowArticleBox;
