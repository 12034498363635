import {
  Box,
  SectionTitle,
  ArticleBox,
  ArticleMobileBox,
  NewsAudioBox,
  Dropdown,
  Link,
  Button,
} from 'components';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { ITab } from 'components/organisms/NewsSection/NewsSection';
import { ArticleItem, ShowSound } from 'models';
import { MobileContext } from 'utils/mobileContext';
import { noPadding } from 'helpers';
import SVG from 'react-inlinesvg';

interface IOption {
  name: string;
  content: ArticleItem[];
  id?: number;
  isRadioShow?: boolean;
}
interface INewsMosaic {
  tabs: ITab[];
  audio: ShowSound[];
}

export const NewsMosaic: React.FC<INewsMosaic> = ({ tabs, audio }) => {
  const [activeOption, setActiveOption] = React.useState<IOption>(
    tabs[tabs.length - 1]
  );
  const articles = activeOption.content;
  const newsBoxAudio = React.useState<ShowSound[]>(audio);
  const { isMobile } = React.useContext(MobileContext);

  if (!articles) return null;

  return (
    <Box
      p={3}
      backgroundColor="neutral.white"
      border="1px solid"
      borderColor={{ tablet: 'neutral.mediumGray', mobile: 'transparent' }}
      height="100%"
    >
      <Box
        mx={isMobile ? 0 : 15}
        borderBottom="1px solid"
        borderColor="neutral.mediumGray"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        position="relative"
      >
        <SectionTitle
          fontSize={isMobile ? 3 : 4}
          borderWidth={5}
          borderColor="main.primary"
          display="flex"
        >
          publicystyka
        </SectionTitle>
        <Dropdown
          options={tabs}
          activeOption={activeOption}
          setActiveOption={setActiveOption}
        />
      </Box>
      <Box pt={20}>
        {isMobile ? (
          <Container fluid md style={isMobile ? noPadding : {}}>
            <Row>
              <Col xs={12}>
                <Box>
                  <ArticleBox article={articles[0]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={articles[1]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={articles[2]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={articles[3]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                  position={isMobile && 'relative'}
                  mb={isMobile ? 24 : 0}
                  pb={isMobile ? 24 : 0}
                >
                  <ArticleMobileBox article={articles[4]} />
                  {isMobile && (
                    <Box
                      width={120}
                      position="absolute"
                      bottom={0}
                      left="50%"
                      transform="translate(-50%, 50%)"
                    >
                      <Link
                        href={
                          activeOption.isRadioShow
                            ? `/audycje/${activeOption.id}`
                            : `/artykuly/${activeOption.id}`
                        }
                      >
                        <Button
                          color="main.primary"
                          decorator={<SVG src="/icons/arrow_right.svg" />}
                        >
                          Więcej
                        </Button>
                      </Link>
                    </Box>
                  )}
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box height={180} mt={3}>
                  <NewsAudioBox audio={newsBoxAudio[0]} />
                </Box>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container fluid md>
            <Row>
              <Col xs={5.5}>
                <Row>
                  <Col xs={12}>
                    <Box mb={3}>
                      <ArticleBox article={articles[0]} />
                    </Box>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Box height={150}>
                      <NewsAudioBox audio={newsBoxAudio[0]} />
                    </Box>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={6.5}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Row>
                  <Col xs={6}>
                    <Box pb={4}>
                      <ArticleBox lineClamp={4} article={articles[1]} />
                    </Box>
                  </Col>
                  <Col xs={6}>
                    <Box pb={4}>
                      <ArticleBox lineClamp={4} article={articles[2]} />
                    </Box>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Box pb={4}>
                      <ArticleBox lineClamp={4} article={articles[3]} />
                    </Box>
                  </Col>
                  <Col xs={6}>
                    <Box pb={4}>
                      <ArticleBox lineClamp={4} article={articles[4]} />
                    </Box>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        )}
      </Box>
      {!isMobile && (
        <Box
          width={120}
          position="absolute"
          bottom={0}
          left="50%"
          transform="translate(-50%, 50%)"
        >
          <Link
            href={
              activeOption.isRadioShow
                ? `/audycje/${activeOption.id}`
                : `/artykuly/${activeOption.id}`
            }
          >
            <Button
              color="main.primary"
              decorator={<SVG src="/icons/arrow_right.svg" />}
            >
              Więcej
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default NewsMosaic;
