import { Box, Typography, Link, Button } from 'components';
import React from 'react';
import { Book } from 'models';
import useHover from 'utils/useHover';
import Image from 'next/image';
import { MobileContext } from 'utils/mobileContext';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const StyledSvg = styled(SVG)<{ color: string }>`
  polygon {
    fill: ${({ color }) => color};
  }
`;

export const WasRead: React.FC<{ book: Book; currentBook?: boolean }> = ({
  book,
  currentBook = false,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const { isMobile } = React.useContext(MobileContext);

  return (
    <Box px={3} height="100%" display="flex">
      <Box
        width={141}
        height={245}
        border="1px solid"
        borderColor="neutral.dark"
        flexShrink={0}
        position="relative"
      >
        <Image
          src={book?.photo || '/images/shareCard.jpeg'}
          alt={book?.title}
          layout="fill"
          objectFit="cover"
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        ml={4}
        height={245}
      >
        <Box>
          {isMobile ? (
            <Link
              href={`/artykul/${book?.id},${book?.title
                .replace(/[.,:?!/"]/g, '')
                .split(' ')
                .join('-')}`}
              ariaLabel={book?.title}
            >
              <Typography
                variant={isMobile ? 'bookTitleSmall' : 'bookTitle'}
                mb={2}
                color={isHover ? 'main.primary' : 'neutral.dark'}
              >
                {book.title}
              </Typography>
            </Link>
          ) : (
            <Typography
              variant={isMobile ? 'bookTitleSmall' : 'bookTitle'}
              mb={2}
              color={isHover ? 'main.primary' : 'neutral.dark'}
            >
              {book.title}
            </Typography>
          )}
          {currentBook ? (
            <>
              {!isMobile && (
                <Typography
                  variant="body3"
                  color={isHover ? 'main.primary' : 'neutral.dark'}
                >
                  {book.lead}
                </Typography>
              )}
            </>
          ) : (
            <>
              {book.customFields[0] && (
                <Typography
                  variant="body2"
                  color={isHover ? 'main.primary' : 'neutral.dark'}
                >
                  {book.customFields[0]?.value}
                </Typography>
              )}
            </>
          )}
        </Box>
        {!currentBook && (
          <>
            {book.customFields[1] && book.customFields[2] && (
              <Box my={3}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Box
                    height={26}
                    width={26}
                    backgroundColor="main.primary"
                    borderRadius="5px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="listCaption" color="neutral.white">
                      {book.customFields[2]?.value.match(/\d+/g)}
                    </Typography>
                  </Box>
                  <Typography variant="listCaption" ml={2}>
                    {book.customFields[2]?.value.split(' ').slice(-1)}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="baseline">
                  <Typography variant="bookReading">czyta:&nbsp;</Typography>
                  <Typography variant="bookLector">
                    {book.customFields[1]?.value.split('czyta')}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}
        {!isMobile && (
          <Box ref={hoverRef} width="fit-content" position="relative">
            <Link
              href={`/artykul/${book?.id},${book?.title
                .replace(/[.,:?!/"]/g, '')
                .split(' ')
                .join('-')}`}
              ariaLabel={book?.title}
            >
              {currentBook ? (
                <Button
                  color="main.primary"
                  decorator={<SVG src="/icons/arrow_right.svg" />}
                >
                  Więcej
                </Button>
              ) : (
                <>
                  <SVG
                    src={
                      isHover
                        ? '/icons/listen-btn-hover.svg'
                        : '/icons/listen-btn.svg'
                    }
                  />
                  <Box
                    position="absolute"
                    top="16px"
                    right="10px"
                    display="flex"
                    alignItems="center"
                    cursor="pointer"
                  >
                    <Typography
                      color={isHover ? 'main.primary' : 'neutral.white'}
                      variant="sectionTitle"
                      fontSize={13}
                      pr={2}
                    >
                      posłuchaj
                    </Typography>
                    <StyledSvg
                      src="/icons/arrow_right.svg"
                      color={isHover ? 'main.primary' : 'neutral.white'}
                    />
                  </Box>
                </>
              )}
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WasRead;
