import { Box, MusicMosaic } from 'components';
import { noPadding } from 'helpers';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { ArticleItem } from 'models';
import { MobileContext } from 'utils/mobileContext';
import { ITab } from '../NewsSection/NewsSection';

export interface IMusicMosaicSection {
  tabs: ITab[];
  recordOfTheWeek: ArticleItem[];
  recommendedRecords: ArticleItem[];
}

export const MusicMosaicSection: React.FC<IMusicMosaicSection> = ({
  tabs,
  recordOfTheWeek,
  recommendedRecords,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <section>
      <Box pt={3} pb={50} backgroundColor="neutral.light">
        <Container fluid md style={isMobile ? noPadding : {}}>
          <Row>
            <Col xs={12} style={isMobile ? noPadding : {}}>
              <MusicMosaic
                tabs={tabs}
                recordOfTheWeek={recordOfTheWeek}
                recommendedRecords={recommendedRecords}
              />
            </Col>
          </Row>
        </Container>
      </Box>
    </section>
  );
};

export default MusicMosaicSection;
