import { Box, BannerSpecialService } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { MobileContext } from 'utils/mobileContext';

export const SpecialServiceSection: React.FC<{ content: ArticleItem }> = ({
  content,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <section>
      <Box py={isMobile ? 3 : 5}>
        <Container fluid md>
          <Row gutterWidth={0}>
            <Col xs={12}>
              <Box width="100%" height="100%">
                <BannerSpecialService content={content} />
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
    </section>
  );
};

export default SpecialServiceSection;
