/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  StyledTextarea,
  StyledInput,
  Typography,
  Loader,
} from 'components';
import React from 'react';
import SVG from 'react-inlinesvg';
import { useForm, Controller } from 'react-hook-form';
import { API } from 'API';
import parser from 'ua-parser-js';

interface Form {
  message: string;
  signature: string;
  email: string;
  title: string;
}

export const ContactForm: React.FC = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<Form>();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);

  const onSubmit = async (data: Form) => {
    setLoading(true);

    const p = new parser.UAParser();
    const browser = p.getBrowser();
    const os = p.getOS();
    let userIp = 'unknown';
    const browserData = browser
      ? `${browser?.name} ${browser.version}`
      : navigator?.userAgent;
    const osData = os ? `${os?.name} ${os.version}` : navigator?.platform;

    const askIp = await Promise.allSettled([
      fetch('https://geolocation-db.com/json/'),
    ]);
    if (askIp[0].status === 'fulfilled') {
      const askIpJson = await Promise.allSettled([askIp[0].value.json()]);
      if (askIpJson[0].status === 'fulfilled') {
        userIp = askIpJson[0]?.value?.IPv4 || 'unknown';
      }
    }

    API.contactStation(
      data.signature,
      data.email,
      data.message,
      data.title,
      userIp,
      browserData,
      'computerName',
      osData,
      'userName',
      'sourcePort'
    )
      .then(() => {
        setLoading(false);
      })
      .finally(() => {
        setSuccess(true);
        reset({ message: '', email: '', signature: '', title: '' });
      });
  };

  const formValid = Object.keys(errors).length === 0;
  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        width={{
          desktop: 'calc(100% - 316px )',
          mobile: '100%',
        }}
        flexDirection="column"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width={{ desktop: '43%', mobile: '100%' }} my={4}>
            <Controller
              control={control}
              name="title"
              rules={{
                required: { value: true, message: 'Pole jest wymagane' },
              }}
              render={({ field: { onChange, value } }) => (
                <StyledInput
                  width="100%"
                  variant="sectionTitle"
                  height="24px"
                  fontSize={3}
                  color="neutral.dark"
                  placeholderColor="neutral.dark"
                  borderLeft="5px solid"
                  isTitleInput
                  borderBottom={value ? 'neutral.placeholder' : 'none'}
                  pl={2}
                  borderColor={
                    errors.title
                      ? 'main.secondary'
                      : value
                      ? 'main.primary'
                      : 'neutral.placeholder'
                  }
                  name="title"
                  placeholder="Wpisz Tytuł"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {errors.title && (
              <Typography variant="error" color="main.secondary">
                {errors.title?.message}
              </Typography>
            )}
          </Box>
          <Box my={3}>
            <Controller
              control={control}
              name="message"
              rules={{
                required: { value: true, message: 'Pole jest wymagane' },
              }}
              render={({ field: { onChange, value } }) => (
                <StyledTextarea
                  placeholder="Treść wiadomości"
                  resize="vertical"
                  onChange={onChange}
                  value={value}
                  border={errors.message ? '2px solid red' : ''}
                />
              )}
            />
            {errors.message && (
              <Typography variant="error" color="main.secondary">
                {errors.message.message}
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            width="100%"
            flexDirection={{ desktop: 'row', mobile: 'column' }}
            justifyContent="space-between"
            my={{ desktop: 3, mobile: 0 }}
          >
            <Box
              width={{ desktop: '43%', mobile: '100%' }}
              my={{ desktop: 0, mobile: 3 }}
            >
              <Controller
                control={control}
                name="signature"
                rules={{
                  required: { value: true, message: 'Pole jest wymagane' },
                }}
                render={({ field: { onChange, value } }) => (
                  <StyledInput
                    width="100%"
                    variant="input"
                    color="neutral.dark"
                    placeholderColor="neutral.placeholder"
                    borderBottom="2px solid"
                    borderColor={
                      errors.signature
                        ? 'main.secondary'
                        : value
                        ? 'main.primary'
                        : 'neutral.placeholder'
                    }
                    name="signature"
                    placeholder="Twój Podpis"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors.signature && (
                <Typography variant="error" color="main.secondary">
                  {errors.signature.message}
                </Typography>
              )}
            </Box>
            <Box
              width={{ desktop: '43%', mobile: '100%' }}
              my={{ desktop: 0, mobile: 3 }}
              mb={{ desktop: 0, mobile: 4 }}
            >
              <Controller
                control={control}
                name="email"
                rules={{
                  required: { value: true, message: 'Pole jest wymagane' },
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: 'Nieprawidłowy format',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <StyledInput
                    width="100%"
                    variant="input"
                    color="neutral.dark"
                    placeholderColor="neutral.placeholder"
                    borderBottom="2px solid"
                    borderColor={
                      errors.email
                        ? 'main.secondary'
                        : value
                        ? 'main.primary'
                        : 'neutral.placeholder'
                    }
                    name="email"
                    placeholder="Twój Email"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors.email && (
                <Typography variant="error" color="main.secondary">
                  {errors.email.message}
                </Typography>
              )}
            </Box>
          </Box>

          <Box width={120} height="50px" mt={3} mb={{ desktop: 5, mobile: 0 }}>
            {loading ? (
              <Loader />
            ) : (
              <Button
                color="main.primary"
                disabled={!formValid || loading}
                decorator={<SVG src="/icons/arrow_right.svg" />}
              >
                wyślij
              </Button>
            )}
          </Box>
          {success && (
            <Typography marginTop={3} variant="h3">
              Wiadomość wysłana poprawnie
            </Typography>
          )}
        </form>
      </Box>
    </>
  );
};
