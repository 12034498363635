/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
import { Slider, ArticleBox, Box } from 'components';
import React from 'react';
import { ArticleItem } from 'models';
import { MobileContext } from 'utils/mobileContext';

export const ActionsSlider: React.FC<{
  content: ArticleItem[];
  title: string;
  seeMore: string;
}> = ({ content, title, seeMore }) => {
  const { isMobile, isTablet, isDesktop } = React.useContext(MobileContext);
  if (!content) return null;
  return (
    <Slider
      header={title}
      headerSize={isDesktop ? 3 : 1}
      seeMore={seeMore}
      slides={isMobile ? 1 : isTablet ? 2 : 4}
      mobileScrollBar
      headline
      navigation
      bgUrl="/images/actions_bg.png"
      paddingLeft={4}
      paddingRight={4}
      paddingContainer={isMobile ? 1 : 0}
      data={content}
      render={(article) => (
        <Box
          height={!isDesktop ? 'auto' : 210}
          width={!isDesktop ? '100%' : undefined}
        >
          <ArticleBox
            article={article}
            color="neutral.white"
            hoveredColor="neutral.gray"
            isSlider
          />
        </Box>
      )}
    />
  );
};
