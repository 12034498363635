import { StyledButton } from 'components';
import React from 'react';
import useHover from 'utils/useHover';

interface IProps {
  color: string;
  baseColor?: string;
  decorator?:
    | ((isHover: boolean) => React.ReactNode)
    | React.ReactElement
    | null;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const Button: React.FC<IProps> = ({
  children,
  baseColor = 'white',
  color,
  decorator = null,
  onClick,
  disabled,
  style,
}) => {
  const [hoverRef, isHover] = useHover<HTMLButtonElement>();

  return (
    <StyledButton
      ref={hoverRef}
      color={isHover ? color : baseColor}
      borderColor={isHover ? color : 'transparent'}
      backgroundColor={isHover ? baseColor : color}
      justifyContent="center"
      fill={isHover ? color : baseColor}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      <span>{children}</span>
      {decorator &&
        (typeof decorator === 'function' ? decorator(isHover) : decorator)}
    </StyledButton>
  );
};
