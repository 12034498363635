import { ActionsSlider, Box } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import { MobileContext } from 'utils/mobileContext';

export const ActionsSection: React.FC<{
  actionsContent: ArticleItem[];
  title: string;
  seeMore: string;
}> = ({ actionsContent, title, seeMore }) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <Box pt={3} px={0} mx={isMobile ? '-15px' : undefined} as="section">
      <ActionsSlider content={actionsContent} title={title} seeMore={seeMore} />
    </Box>
  );
};

export default ActionsSection;
