import { Box, TheaterSlider, ReportageSlider } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import { Row, Col } from 'react-grid-system';
import { MobileContext } from 'utils/mobileContext';

export const AudioSlidersSection: React.FC<{
  seriesContent: ArticleItem[];
  exclusivesContent: ArticleItem[];
}> = ({ seriesContent, exclusivesContent }) => {
  const { isDesktop } = React.useContext(MobileContext);
  return (
    <section>
      <Box pb={4}>
        <Row style={{ marginLeft: 0, marginRight: 0 }}>
          <Col
            lg={12}
            xl={6}
            style={{
              paddingRight: 3,
              paddingLeft: !isDesktop ? 0 : undefined,
              marginBottom: !isDesktop ? 50 : 0,
            }}
          >
            <TheaterSlider content={seriesContent} />
          </Col>
          <Col
            lg={12}
            xl={6}
            style={{ paddingLeft: 3, paddingRight: !isDesktop ? 0 : undefined }}
          >
            <ReportageSlider content={exclusivesContent} />
          </Col>
        </Row>
      </Box>
    </section>
  );
};

export default AudioSlidersSection;
