/* eslint-disable react/no-danger */
import { LiveEvent } from 'models';
import Moment from 'react-moment';
import 'moment/locale/pl';
import { Box, Typography } from 'components/atoms';
import { MotionProps } from 'framer-motion';
import { Tweet } from 'react-twitter-widgets';
import { EventContainer } from './styles';

interface ITickerEvent extends MotionProps {
  evt: LiveEvent;
}

export const TickerEvent: React.FC<ITickerEvent> = ({
  evt,
  initial,
  animate,
  exit,
}) => {
  const isTweet = evt?.body?.includes('twitter-tweet');
  const ttId = evt?.body
    ?.match(/status\/\d+/gm)?.[0]
    ?.split('/')
    ?.slice(-1)?.[0];
  return (
    <EventContainer initial={initial} animate={animate} exit={exit}>
      <Typography variant="liveTickerTimestamp" color="main.primary" mb={1}>
        <Moment
          locale="pl"
          local
          date={evt?.publishedAt}
          fromNow
          element="span"
          interval={10000}
        />
      </Typography>
      {!isTweet && <div dangerouslySetInnerHTML={{ __html: evt?.body }} />}
      {isTweet && ttId && (
        <Box width="100%">
          <Tweet options={{ align: 'left' }} tweetId={ttId} />
        </Box>
      )}
    </EventContainer>
  );
};
