/* eslint-disable import/no-cycle */
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { API } from 'API';
import { AnimatePresence } from 'framer-motion';
import { LiveEvent } from 'models';
import React from 'react';
import { LiveTickerHeader } from './styles';
import { TickerEvent } from './TickerEvent';

interface ILiveTicker {
  id: string;
}

export const LiveTicker: React.FC<ILiveTicker> = ({ id }) => {
  const [events, setEvents] = React.useState<LiveEvent[] | undefined>();
  const [hubConnection, setHubConnection] = React.useState<HubConnection>();
  const [initialConnect, setInitialConnect] = React.useState(true);

  const addMessage = (evt: LiveEvent) => {
    setEvents((prevState) => [evt, ...(prevState || [])]);
  };
  const addMultipleMessages = (evt: LiveEvent[]) => {
    setEvents((prevState) => [...evt, ...(prevState || [])]);
  };
  const updateMessage = (evt: LiveEvent) => {
    setEvents((prevState) =>
      prevState?.map((prevEvent) => {
        if (prevEvent.guid === evt.guid) return evt;
        return prevEvent;
      })
    );
  };
  const deleteMessage = (guid: string) => {
    setEvents((prevState) =>
      prevState?.filter((prevEvent) => prevEvent.guid !== guid)
    );
  };

  React.useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl('https://relacjelive.polskieradio.pl/relations/', {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Critical)
      .withAutomaticReconnect()
      .build();

    setHubConnection(connection);
  }, []);

  React.useEffect(() => {
    if (hubConnection && events && initialConnect) {
      const start = async () => {
        try {
          await hubConnection.start().then(() => {
            hubConnection.invoke('Joingroup', +id);
            setInitialConnect(false);
          });
          console.log('live connected');
        } catch (err) {
          console.log(err);
        }
      };
      start();

      hubConnection.onreconnected(() => {
        if (events?.slice(-1)?.[0]?.publishedAt) {
          hubConnection.invoke(
            'Synchronize',
            +id,
            events?.slice(-1)?.[0]?.publishedAt
          );
        }
      });
      hubConnection.on('receiveMessage', (message: LiveEvent) => {
        addMessage(message);
      });
      hubConnection.on('receiveMessages', (messages: LiveEvent[]) => {
        const existingGuids = events.map((evt) => evt.guid);
        const toUpdate = messages.filter((evt) =>
          existingGuids.includes(evt.guid)
        );
        const toAdd = messages.filter(
          (evt) => !existingGuids.includes(evt.guid)
        );
        if (toUpdate.length) toUpdate.map((evt) => updateMessage(evt));
        if (toAdd.length) addMultipleMessages(toAdd);
      });
      hubConnection.on('UpdateMessage', (message: LiveEvent) => {
        updateMessage(message);
      });
      hubConnection.on('DeleteMessage', (guid: string) => {
        deleteMessage(guid);
      });
    }
  }, [hubConnection, events]);

  React.useEffect(() => {
    return () => {
      hubConnection?.stop();
      hubConnection?.off('receiveMessage');
      hubConnection?.off('receiveMessages');
      hubConnection?.off('DeleteMessage');
      hubConnection?.off('UpdateMessage');
    };
  }, [hubConnection]);

  const fetchTickerData = async () => {
    const res = await API.updateLiveTicker(id);
    setEvents(res?.data);
    return res;
  };

  React.useEffect(() => {
    fetchTickerData();
  }, []);

  return (
    <div>
      <LiveTickerHeader>RELACJA LIVE</LiveTickerHeader>
      <AnimatePresence>
        {events?.map((evt) => (
          <TickerEvent
            key={evt.guid}
            evt={evt}
            initial={{ opacity: 0, translateY: 30 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: 30 }}
          />
        ))}
      </AnimatePresence>
    </div>
  );
};
