import { Box, SectionTitle, ArticleBox, ArticleMobileBox } from 'components';
import React from 'react';
import { ArticleItem } from 'models';
import { MobileContext } from 'utils/mobileContext';

export interface IProps {
  articlesData: ArticleItem[] | undefined[];
  recommendedvariant?: boolean;
  bannerData?: ArticleItem;
}

export const ReadAlsoSideRecommended: React.FC<IProps> = ({
  articlesData,
  recommendedvariant,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  const isHidden = articlesData.length === 1 && recommendedvariant;
  if (isHidden) {
    return null;
  }
  return (
    <Box as="section">
      <SectionTitle
        borderWidth={recommendedvariant ? 5 : 1}
        borderColor={
          recommendedvariant ? 'main.primary' : 'neutral.grayWithOpacity'
        }
        decorator={!recommendedvariant}
        decoratorHeight="12px"
        decoratorColor="main.primary"
        fontSize={recommendedvariant ? 3 : 2}
      >
        {recommendedvariant ? 'Polecane' : 'Najczęściej czytane'}
      </SectionTitle>
      <Box
        py={3}
        display={recommendedvariant ? 'grid' : 'block'}
        gridGap={3}
        flexDirection={isMobile ? 'column' : 'row'}
        gridTemplateColumns={
          recommendedvariant && isMobile ? '1fr' : 'repeat(4,1fr)'
        }
        width="100%"
        pb={5}
      >
        {articlesData?.map((article, index) => {
          if (!article) {
            return null;
          }
          if ((index >= 3 && !recommendedvariant) || index >= 4) {
            return null;
          }

          if (!article) {
            return null;
          }
          return (
            <Box
              height={!isMobile ? 250 : 'auto'}
              maxWidth="100%"
              key={article.id}
            >
              {recommendedvariant && isMobile ? (
                <ArticleMobileBox article={article} />
              ) : null}
              {(recommendedvariant && !isMobile) || !recommendedvariant ? (
                <ArticleBox article={article} isMosaic />
              ) : null}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ReadAlsoSideRecommended;
