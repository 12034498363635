import { Box, StreamBox } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';

export const StreamingSection: React.FC<{
  streamContent: ArticleItem;
  streamFallback?: boolean;
}> = ({ streamContent, streamFallback }) => {
  return (
    <Box pt={3} as="section">
      <Container fluid md>
        <Row>
          <Col xs={12}>
            <StreamBox
              streamContent={streamContent}
              streamFallback={streamFallback}
            />
          </Col>
        </Row>
      </Container>
    </Box>
  );
};
