import { Box, Typography } from 'components';
import React from 'react';
import { MobileContext } from 'utils/mobileContext';

interface IProps {
  borderWidth?: number;
  borderColor?: string;
  decorator?: boolean;
  decoratorColor?: string;
  decoratorHeight?: number | string;
  decoratorTop?: string;
  fontSize?: number;
  shadow?: boolean;
  shadowFontSize?: number;
  shadowOffset?: number;
  lineHeight?: string;
  sideText?: string;
  display?: string;
}

export const SectionTitle: React.FC<IProps> = ({
  children,
  borderWidth = 0,
  borderColor = 'neutral.grayWithOpacity',
  decorator = false,
  decoratorColor = 'main.primary',
  decoratorHeight = 12,
  decoratorTop = '20%',
  fontSize,
  shadow,
  shadowFontSize,
  shadowOffset,
  lineHeight,
  sideText,
  display = 'inline-block',
}) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <Box display={display} position="relative" width="100%">
      {decorator && (
        <Box
          position="absolute"
          left="0px"
          top={decoratorTop}
          width="3px"
          height={decoratorHeight}
          backgroundColor={decoratorColor}
        />
      )}
      <Box
        borderBottomWidth={borderWidth}
        borderBottomStyle="solid"
        borderColor={borderColor}
        display="inline-block"
      >
        <Typography
          as="h2"
          variant="sectionTitle"
          fontSize={fontSize || (isMobile ? 14 : 20)}
          lineHeight={lineHeight}
          paddingLeft={decorator ? 2 : 0}
        >
          {children}
        </Typography>
      </Box>
      {sideText && (
        <Box display="inline-block" transform="translateY(9px)" ml={3}>
          <Typography
            variant="titleSideText"
            color="main.primary"
            style={isMobile ? { fontSize: '36px' } : undefined}
          >
            {sideText}
          </Typography>
        </Box>
      )}
      {shadow && (
        <Box
          position="absolute"
          bottom="0"
          zIndex={-1}
          borderBottom="1px solid"
          borderColor="neutral.grayWithOpacity"
          width="100%"
          pl={shadowOffset || 5}
        >
          <Typography
            as="h2"
            variant={
              isMobile ? 'sectionTitleShadowMobile' : 'sectionTitleShadow'
            }
            fontSize={shadowFontSize}
          >
            {children}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
