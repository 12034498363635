import {
  TabsBox,
  Box,
  ArticleBox,
  ArticleMobileBox,
  Link,
  Button,
} from 'components';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import SVG from 'react-inlinesvg';
import { IMosaicTab } from 'components/organisms/TabMosaicSection/TabMosaicSection';
import { ITab } from 'components/organisms/NewsSection/NewsSection';
import { BoxInstanceId } from 'models';
import { MobileContext } from 'utils/mobileContext';
import { WasReadSlider } from '../WasReadSlider';

export const TabMosaic: React.FC<{
  tabs: IMosaicTab[];
  readBooks: BoxInstanceId[];
  currentReadBooks: BoxInstanceId[];
}> = ({ tabs, readBooks, currentReadBooks }) => {
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [activeOption, setActiveOption] = React.useState<ITab>(
    tabs[0].options[tabs[0].options.length - 1]
  );
  const { isMobile, isDesktop } = React.useContext(MobileContext);

  React.useEffect(() => {
    setActiveOption(tabs[tabIndex].options[tabs[tabIndex].options.length - 1]);
  }, [tabIndex]);

  return (
    <Box
      p={3}
      backgroundColor="neutral.white"
      height="100%"
      position="relative"
    >
      <TabsBox
        tabIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
        onPrevClick={() => tabIndex > 0 && setTabIndex(tabIndex - 1)}
        onNextClick={() =>
          tabIndex < tabs.length - 1 && setTabIndex(tabIndex + 1)
        }
        titles={tabs.map((tab) => tab.tabName)}
        dropdown
        options={tabs[tabIndex].options}
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        borderRight
      >
        {activeOption.content && (
          <>
            {tabs[tabIndex].tabName === 'Literatura' ? (
              <Container fluid md>
                {!isDesktop ? (
                  <>
                    <Row>
                      <Col xs={12}>
                        <Box mt={4}>
                          <ArticleBox article={activeOption.content[0]} />
                        </Box>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Box
                          borderBottom="1px solid"
                          borderColor="neutral.grayWithOpacity"
                        >
                          <ArticleMobileBox article={activeOption.content[1]} />
                        </Box>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Box
                          borderBottom="1px solid"
                          borderColor="neutral.grayWithOpacity"
                        >
                          <ArticleMobileBox article={activeOption.content[2]} />
                        </Box>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Box
                          borderBottom="1px solid"
                          borderColor="neutral.grayWithOpacity"
                        >
                          <ArticleMobileBox article={activeOption.content[3]} />
                        </Box>
                      </Col>
                    </Row>
                    <Row style={{ position: 'relative' }}>
                      <Col xs={12}>
                        <Box>
                          <ArticleMobileBox article={activeOption.content[4]} />
                        </Box>
                      </Col>
                      <Box
                        width={120}
                        position="absolute"
                        bottom="-30px"
                        left="50%"
                        transform="translate(-50%, 50%)"
                      >
                        <Link href={`/artykuly/${activeOption.id}`}>
                          <Button
                            color="main.primary"
                            decorator={<SVG src="/icons/arrow_right.svg" />}
                          >
                            Więcej
                          </Button>
                        </Link>
                      </Box>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                      <Col xs={12}>
                        <Box mt={3}>
                          <WasReadSlider
                            readBooks={currentReadBooks}
                            header="BIEŻĄCE LEKTURY"
                            currentBook
                          />
                        </Box>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Box mt={3}>
                          <WasReadSlider
                            readBooks={readBooks}
                            header="BYŁO CZYTANE"
                          />
                        </Box>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={6}>
                        <Box height={400} pb={isMobile ? 0 : 4}>
                          <WasReadSlider
                            readBooks={currentReadBooks}
                            header="BIEŻĄCE LEKTURY"
                            currentBook
                          />
                        </Box>
                      </Col>
                      <Col xs={6}>
                        <Box height={400} pb={isMobile ? 0 : 4}>
                          <WasReadSlider
                            readBooks={readBooks}
                            header="BYŁO CZYTANE"
                          />
                        </Box>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>
                        <Box pb={3}>
                          <ArticleBox article={activeOption.content[0]} />
                        </Box>
                      </Col>
                      <Col xs={3}>
                        <Box pb={3}>
                          <ArticleBox article={activeOption.content[1]} />
                        </Box>
                      </Col>
                      <Col xs={3}>
                        <Box pb={3}>
                          <ArticleBox article={activeOption.content[2]} />
                        </Box>
                      </Col>
                      <Col xs={3}>
                        <Box pb={3}>
                          <ArticleBox article={activeOption.content[4]} />
                        </Box>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            ) : (
              <Container>
                {!isDesktop ? (
                  <>
                    <Row>
                      <Col xs={12}>
                        <Box mt={4}>
                          <ArticleBox article={activeOption.content[0]} />
                        </Box>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Box
                          borderBottom="1px solid"
                          borderColor="neutral.grayWithOpacity"
                        >
                          <ArticleMobileBox article={activeOption.content[1]} />
                        </Box>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Box
                          borderBottom="1px solid"
                          borderColor="neutral.grayWithOpacity"
                        >
                          <ArticleMobileBox article={activeOption.content[2]} />
                        </Box>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Box
                          borderBottom="1px solid"
                          borderColor="neutral.grayWithOpacity"
                        >
                          <ArticleMobileBox article={activeOption.content[3]} />
                        </Box>
                      </Col>
                    </Row>
                    <Row style={{ position: 'relative' }}>
                      <Col xs={12}>
                        <Box>
                          <ArticleMobileBox article={activeOption.content[4]} />
                        </Box>
                      </Col>
                      <Box
                        width={120}
                        position="absolute"
                        bottom="-30px"
                        left="50%"
                        transform="translate(-50%, 50%)"
                      >
                        <Link
                          href={
                            activeOption.isRadioShow
                              ? `/audycje/${activeOption.id}`
                              : `/artykuly/${activeOption.id}`
                          }
                        >
                          <Button
                            color="main.primary"
                            decorator={<SVG src="/icons/arrow_right.svg" />}
                          >
                            Więcej
                          </Button>
                        </Link>
                      </Box>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={6}>
                        <Box pb={3}>
                          <ArticleBox article={activeOption.content[0]} />
                        </Box>
                      </Col>
                      <Col xs={6}>
                        <Box pb={3}>
                          <ArticleBox article={activeOption.content[1]} />
                        </Box>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>
                        <Box pb={3}>
                          <ArticleBox article={activeOption.content[2]} />
                        </Box>
                      </Col>
                      <Col xs={3}>
                        <Box pb={3}>
                          <ArticleBox article={activeOption.content[3]} />
                        </Box>
                      </Col>
                      <Col xs={3}>
                        <Box pb={3}>
                          <ArticleBox article={activeOption.content[4]} />
                        </Box>
                      </Col>
                      <Col xs={3}>
                        <Box pb={3}>
                          <ArticleBox article={activeOption.content[5]} />
                        </Box>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            )}
          </>
        )}
      </TabsBox>
      {isDesktop && (
        <Box
          width={120}
          position="absolute"
          bottom={0}
          left="50%"
          transform="translate(-50%, 50%)"
        >
          <Link
            href={
              activeOption.isRadioShow
                ? `/audycje/${activeOption.id}`
                : `/artykuly/${activeOption.id}`
            }
          >
            <Button
              color="main.primary"
              decorator={<SVG src="/icons/arrow_right.svg" />}
            >
              Więcej
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default TabMosaic;
