import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { PRNavbarItem, Box } from 'components';

const PRNavbarUl = styled.ul`
  padding-top: 30px;
`;

interface IProps {
  setPrOpen: (arg0: boolean) => void;
}

const items = [
  { href: '//polskieradio24.pl', name: 'Polskie Radio 24' },
  { href: '//jedynka.polskieradio.pl', name: 'Jedynka' },
  { href: '//polskieradio.pl/8,Dwojka', name: 'Dwójka' },
  { href: '//trojka.polskieradio.pl', name: 'Trójka' },
  { href: '//polskieradio.pl/10,Czworka', name: 'Czwórka' },
  { href: '//radiokierowcow.pl', name: 'Polskie Radio Kierowców' },
  { href: '//polskieradio.pl/18,Dzieci', name: 'Polskie Radio Dzieciom' },
  { href: '//chopin.polskieradio.pl', name: 'Chopin' },
  { href: '//polskieradio.pl/395', name: 'Radio Poland' },
  { href: '//podcasty.polskieradio.pl/antena/jedynka', name: 'Podcasty' },
  { href: '//moje.polskieradio.pl', name: 'Kanały Internetowe' },
  {},
  {
    href: '//www.polskieradio.pl/39,Historia',
    name: 'Historia',
  },
  {
    href: '//polskieradio.pl/219,Serwisy-Specjalne-Polskiego-Radia',
    name: 'Serwisy Historyczne',
  },
  {},
  { href: '//polskieradio.pl/377', name: 'Radiowe Centrum Kultury Ludowej' },
  {
    href: '//polskieradio.pl/80,Reportaz',
    name: 'studio reportażu i dokumentu polskiego radia',
  },
  { href: '//polskieradio.pl/357', name: 'teatr polskiego radia' },
  {
    href: '//polskieradio.pl/79,Polska-Orkiestra-Radiowa',
    name: 'Orkiestra Polskiego Radia w Warszawie',
  },
  {
    href: '//polskieradio.pl/211,Powiesci-na-antenie',
    name: 'lektury do słuchania',
  },
  { href: '//polskieradio.pl/ramowka', name: 'ramówka' },
  { href: '//sklep.polskieradio.pl/', name: 'sklep' },
  { href: '//cem.polskieradio.pl', name: 'szkolenia' },
  { href: '/polskieradio.pl/275/8869,Akademia', name: 'Akademia Radiowa' },
];

export const PRNavbar: React.FC<IProps> = ({ setPrOpen }) => {
  const indices = items.reduce<number[]>(
    (array, item, index) => {
      if (!item.href && !item.name) {
        // pusty obiekt oznacza separator
        array.push(index);
      }
      return array;
    },
    [0]
  );

  return (
    <Box
      width="80vw"
      height="100vh"
      position="relative"
      backgroundColor="neutral.dark"
      zIndex={2000}
      overflow="scroll"
    >
      <Box
        position="absolute"
        color="neutral.light"
        padding="8px 25px 30px 25px"
      >
        <Box mt={3}>
          <SVG src="/icons/close.svg" onClick={() => setPrOpen(false)} />
        </Box>
        {indices.map((ix, ixIndex) => {
          if (ixIndex === indices.length) {
            return (
              <PRNavbarUl>
                {items.slice(ix, items.length - 1).map((item) => {
                  return (
                    <PRNavbarItem
                      key={item.name}
                      href={item.href}
                      name={item.name}
                    />
                  );
                })}
              </PRNavbarUl>
            );
          }
          return (
            <PRNavbarUl>
              {items.slice(ix, indices[ixIndex + 1]).map((item) => {
                return (
                  <PRNavbarItem
                    key={item.name}
                    href={item.href}
                    name={item.name}
                  />
                );
              })}
            </PRNavbarUl>
          );
        })}
      </Box>
    </Box>
  );
};
