import * as React from 'react';

export enum ColorMode {
  default = 'DEFAULT',
  contrast = 'CONTRAST',
}

interface IPaletteContext {
  mode: ColorMode;
  setMode?: (colorMode: ColorMode) => void;
}

export const PaletteContext = React.createContext<IPaletteContext>({
  mode: ColorMode.default,
  setMode: () => undefined,
});

export const PaletteProvider: React.FC = ({ children }) => {
  const [mode, setMode] = React.useState(ColorMode.default);
  return (
    <PaletteContext.Provider
      value={{ mode, setMode: (colorMode: ColorMode) => setMode(colorMode) }}
    >
      {children}
    </PaletteContext.Provider>
  );
};
