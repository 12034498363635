/* eslint-disable no-nested-ternary */
import { Box, ProgressBar } from 'components';
import Image from 'next/image';
import { ShowSound, StreamSound } from 'models';
import React from 'react';
import { PlayerContext } from 'utils/PlayerContext';
import { MobileContext } from 'utils/mobileContext';

interface IProps {
  show?: ShowSound | null;
  stream?: StreamSound | null;
  color?: string;
  barColor?: string;
  progressBar?: boolean;
}

export const Audio: React.FC<IProps> = ({
  show,
  stream,
  color,
  barColor,
  progressBar = false,
}) => {
  const { isTablet } = React.useContext(MobileContext);
  const playerContext = React.useContext(PlayerContext);
  const track = playerContext?.player?.track;
  const isTrackPlaying =
    track?.url === show?.file && playerContext.player.isPlaying;
  const isStreamPlaying = !track && playerContext.player.isPlaying;

  const onPlayClick = () => {
    if (show) {
      playerContext.setPlayerContext({
        type: 'SET_PLAYER',
        payload: {
          track: {
            title: show?.title,
            url: show?.file,
            cover: '/images/cover.png',
            airTime: `${new Date(show?.datePublic).toLocaleString('pl-pl', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}`,
          },
          isPlaying:
            show?.file === playerContext?.player?.track?.url
              ? !playerContext.player.isPlaying
              : true,
          isVisible: true,
        },
      });
    }
    if (stream) {
      playerContext.setPlayerContext({
        type: 'SET_PLAYER',
        payload: {
          track: null,
          isPlaying: track
            ? playerContext.player.isPlaying
            : !playerContext.player.isPlaying,
          duration: 0,
          isVisible: true,
          wasStreamPlaying: !isStreamPlaying,
        },
      });
    }
  };

  return (
    <Box display="flex" alignItems="center" overflow="hidden">
      {show && (
        <Box
          cursor="pointer"
          as="button"
          p={0}
          border="none"
          backgroundColor="transparent"
          onClick={() => show?.file && onPlayClick()}
        >
          <Image
            src={isTrackPlaying ? '/icons/pause_audio.svg' : '/icons/play.svg'}
            height={
              isTablet && progressBar ? '30px' : !progressBar ? '40px' : '60px'
            }
            width={
              isTablet && progressBar ? '30px' : !progressBar ? '40px' : '60px'
            }
            alt={isTrackPlaying ? 'Zatrzymaj' : 'Odtwarzaj'}
          />
        </Box>
      )}
      {stream && (
        <Box
          cursor="pointer"
          as="button"
          p={0}
          border="none"
          backgroundColor="transparent"
          onClick={() => stream?.url && onPlayClick()}
        >
          <Image
            src={isStreamPlaying ? '/icons/pause_audio.svg' : '/icons/play.svg'}
            height={
              isTablet && progressBar ? '30px' : !progressBar ? '40px' : '60px'
            }
            width={
              isTablet && progressBar ? '30px' : !progressBar ? '40px' : '60px'
            }
            alt={isStreamPlaying ? 'Zatrzymaj' : 'Odtwarzaj'}
          />
        </Box>
      )}
      {progressBar && (
        <ProgressBar progress={1} color={color} barColor={barColor} />
      )}
    </Box>
  );
};
