import { Box, Typography, Link } from 'components';
import React from 'react';
import { MobileContext } from 'utils/mobileContext';

export interface IArticle {
  title: string;
  articleId: number;
  fullLinkHref?: string;
}

export interface IProps {
  data: IArticle[];
}

export const ReadAlso: React.FC<IProps> = ({ data }) => {
  const { isMobile } = React.useContext(MobileContext);
  const [isHover, setIsHover] = React.useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
  });
  if (data.length === 0) {
    return null;
  }
  return (
    <Box
      as="section"
      display="flex"
      flexDirection="column"
      width="100%"
      my={4}
      backgroundColor="neutral.light"
      padding={3}
      borderBottom="1px dashed"
      borderColor="neutral.dark"
    >
      <Typography variant={isMobile ? 'header1mobile' : 'lead4'}>
        CZYTAJ TAKŻE:
      </Typography>
      {data?.map((article, index) => {
        if (
          index !== 0 &&
          index !== 1 &&
          index !== 2 &&
          index !== 3 &&
          index !== 4 &&
          index !== 5 &&
          index !== 6 &&
          index !== 7 &&
          index !== 8 &&
          index !== 9
        ) {
          return null;
        }
        return (
          <Box display="flex" alignItems="center" my={3}>
            <Box
              borderRadius="50%"
              backgroundColor="main.primary"
              width={10}
              height={10}
              mr={2}
            />
            <Link
              href={
                article?.fullLinkHref
                  ? article?.fullLinkHref
                  : `/artykul/${article?.articleId},${article?.title
                      .replace(/[.,:?!/"]/g, '')
                      .split(' ')
                      .join('-')}`
              }
              key={article.articleId}
              style={{ textDecoration: 'none' }}
            >
              <Typography
                variant={isMobile ? 'header1mobile' : 'lead3'}
                fontWeight={isMobile ? 500 : 700}
                onMouseEnter={() => setIsHover({ ...isHover, [index]: true })}
                onMouseLeave={() => setIsHover({ ...isHover, [index]: false })}
                color={isHover?.[index] ? 'main.primary' : 'neutral.dark'}
                style={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                {article.title}
              </Typography>
            </Link>
          </Box>
        );
      })}
    </Box>
  );
};
