import { Box, Link } from 'components';
import React from 'react';

interface IProps {
  link: string;
  src: string;
  alt?: string;
}

export const Banner: React.FC<IProps> = ({ link, src, alt }) => {
  return (
    <Box width="100%" height="100%">
      <Link href={link} passHref>
        <a href={link} target="_blank" rel="noreferrer">
          <img src={src} alt={alt} style={{ width: '100%' }} />
        </a>
      </Link>
    </Box>
  );
};

export default Banner;
