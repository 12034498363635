import { LazyComponent } from 'components/atoms/LazyComponent/LazyComponent';
import dynamic from 'next/dynamic';
import React from 'react';
import styled from 'styled-components';

const FooterDesktop = dynamic(() => import('./desktop'));
const FooterMobile = dynamic(() => import('./mobile'));

const MobileView = styled.footer`
  display: none;
  @media (max-width: 768px) {
    display: initial;
  }
`;
const DesktopView = styled.footer`
  display: none;
  @media (min-width: 769px) {
    display: initial;
  }
`;

export const Footer: React.FC = () => {
  return (
    <>
      <DesktopView>
        <LazyComponent height={793}>
          <FooterDesktop />
        </LazyComponent>
      </DesktopView>
      <MobileView>
        <LazyComponent height={392}>
          <FooterMobile />
        </LazyComponent>
      </MobileView>
    </>
  );
};
