import React from 'react';
import SVG from 'react-inlinesvg';
import { Box, Typography, Audio, Button, Link } from 'components';
import { ShowSound } from 'models';
import Image from 'next/image';

interface IProps {
  background: string;
  href: string;
  audio: ShowSound[];
  matysiakowie?: boolean;
}

export const SpecialBroadcastBox: React.FC<IProps> = ({
  matysiakowie,
  background,
  href,
  audio,
}) => {
  const broadcastAudio = audio[0];

  return (
    <Box
      height="100%"
      overflow="hidden"
      backgroundColor="neutral.grayWithOpacity"
      position="relative"
      p={3}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      pt={{ desktop: 90, mobile: 85 }}
    >
      <Image
        src={`/images/${background}`}
        alt={matysiakowie ? 'matysiakowie' : 'jeziorany'}
        layout="fill"
        objectFit="cover"
        objectPosition="top left"
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="baseline"
        width="100%"
        position="absolute"
        zIndex={2}
      >
        <Box display="flex" alignItems="center">
          <Box mr={3}>
            <Audio show={broadcastAudio} />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="date" letterSpacing="0.6px">
              Ostatni odcinek:&nbsp;
            </Typography>
            <Typography
              variant="date"
              letterSpacing="0.6px"
              fontWeight="normal"
            >
              {new Date(broadcastAudio?.datePublic)
                .toLocaleString('pl-pl', {
                  day: 'numeric',
                  month: 'long',
                  hour: 'numeric',
                  minute: 'numeric',
                })
                .split(',')
                .join(' godz. ')}
            </Typography>
          </Box>
        </Box>
        <Box mt={{ desktop: 20, mobile: 10 }} width={120}>
          <Link href={href}>
            <Button
              color="main.primary"
              decorator={<SVG src="/icons/arrow_right.svg" />}
            >
              więcej
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default SpecialBroadcastBox;
