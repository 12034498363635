import { IProps as HoverableImageProps } from 'components/atoms/HoverableImage/HoverableImage';
import { HoverableImage, Link } from 'components';

interface IProps extends HoverableImageProps {
  href: string;
  target?: string;
}

export const ImageLink: React.FC<IProps> = ({
  href,
  imgOff,
  imgOn,
  alt,
  target,
}) => {
  return (
    <Link href={href} ariaLabel={alt} target={target}>
      <HoverableImage imgOff={imgOff} imgOn={imgOn} alt={alt} />
    </Link>
  );
};
