// import { relative } from 'path';
import React from 'react';
// import LiteYoutubeEmbed from 'react-lite-youtube-embed';
// import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

interface IProps {
  link: string;
  aspectHeight?: number;
}

export const Embed: React.FC<IProps> = ({ link, aspectHeight }) => {
  console.warn(aspectHeight);
  return (
    // <LiteYoutubeEmbed
    //   id={link.split('/').slice(-1).join('')}
    //   title="Stream"
    //   aspectHeight={aspectHeight}
    // />
    <div
      style={{
        width: '100%',
        paddingBottom: '56.25%',
        position: 'relative',
      }}
    >
      <iframe
        id="yt"
        title="Relacja"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
        src={link}
      />
    </div>
  );
};
