/* eslint-disable jsx-a11y/anchor-is-valid */
import NextLink, { LinkProps } from 'next/link';
import { CSSProperties } from 'react';

interface IProps extends LinkProps {
  ariaLabel?: string;
  width?: string;
  newCard?: boolean;
  style?: CSSProperties;
  tabIndex?: number;
  target?: string;
}

export const Link: React.FC<IProps> = ({
  children,
  href,
  ariaLabel,
  width = '100%',
  newCard = false,
  target,
  style,
  tabIndex,
}) => {
  const isExternal =
    !href?.toString().includes('jedynka') && href.toString().includes('http');
  return (
    <NextLink href={href} passHref prefetch={false}>
      <a
        aria-label={ariaLabel}
        style={{ width, ...style }}
        target={target || (newCard || isExternal ? '_blank' : '_self')}
        tabIndex={tabIndex}
        rel={isExternal ? 'noopener noreferrer' : undefined}
      >
        {children}
      </a>
    </NextLink>
  );
};
