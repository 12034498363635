import { Box, SectionTitle, PromoBox, ArticleMobileBox } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import { MobileContext } from 'utils/mobileContext';
import { Container, Row, Col } from 'react-grid-system';

export const PromoSection: React.FC<{
  promo1?: ArticleItem;
  promo2?: ArticleItem;
}> = ({ promo1, promo2 }) => {
  const { isDesktop } = React.useContext(MobileContext);
  return (
    <Box height="100%" backgroundColor="neutral.lightGray" py={27} px={4}>
      {isDesktop ? (
        <>
          {promo1 && (
            <Box>
              <SectionTitle
                borderWidth={1}
                borderColor="neutral.grayWithOpacity"
                decorator
                decoratorHeight="14px"
                decoratorTop="13%"
                decoratorColor="main.primary"
                fontSize={2}
              >
                polecamy
              </SectionTitle>
              <PromoBox
                src={promo1?.attachments?.[0]?.file || '/images/shareCard.jpeg'}
                alt={
                  promo1?.customFields?.find((field) => field.name === 'alt')
                    ?.value
                }
                link={
                  promo1?.customFields?.find((field) => field.name === 'link')
                    ?.value || ''
                }
              />
            </Box>
          )}
          {promo2 && (
            <Box mt={4}>
              <SectionTitle
                borderWidth={1}
                borderColor="neutral.grayWithOpacity"
                decorator
                decoratorHeight="14px"
                decoratorTop="13%"
                decoratorColor="main.primary"
                fontSize={2}
              >
                posłuchaj
              </SectionTitle>
              <PromoBox
                src={promo2?.attachments?.[0]?.file || '/images/shareCard.jpeg'}
                alt={
                  promo2?.customFields?.find((field) => field.name === 'alt')
                    ?.value
                }
                link={
                  promo2?.customFields?.find((field) => field.name === 'link')
                    ?.value || ''
                }
                mb={0}
              />
            </Box>
          )}
        </>
      ) : (
        <Container fluid md style={{ paddingLeft: 0, paddingRight: 0 }}>
          <SectionTitle
            borderWidth={1}
            borderColor="neutral.grayWithOpacity"
            decorator
            decoratorHeight="14px"
            decoratorTop="13%"
            decoratorColor="main.primary"
            fontSize={2}
          >
            polecamy
          </SectionTitle>
          {promo1 && (
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox promoArticle={promo1} />
                </Box>
              </Col>
            </Row>
          )}
          {promo2 && (
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox promoArticle={promo2} />
                </Box>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </Box>
  );
};

export default PromoSection;
