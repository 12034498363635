import Image from 'next/image';
import React from 'react';
import { Box } from 'components';
import { CustomField } from 'models';
import Link from 'next/link';

export const ArticleTopBanner = ({
  isMobile,
  customFields,
}: {
  isMobile: boolean;
  customFields: CustomField[];
}) => {
  const desktopBannerSrc = customFields?.find(
    (el) => el.name === 'desktopBannerSrc'
  )?.value;
  const mobileBannerSrc = customFields?.find(
    (el) => el.name === 'mobileBannerSrc'
  )?.value;

  const linkBanner = customFields?.find(
    (el) => el.name === 'linkBanner'
  )?.value;

  const altBanner =
    customFields?.find((el) => el.name === 'altBanner')?.value || 'banner';
  const isBannerVisible = desktopBannerSrc && mobileBannerSrc;

  if (!isBannerVisible) return null;

  const LinkBanner = ({ children }: { children: React.ReactNode }) => {
    if (linkBanner) {
      return <Link href={linkBanner}>{children}</Link>;
    }
    return <>{children}</>;
  };

  if (isMobile) {
    return (
      <Box pt={4} display="flex" justifyContent="center">
        <LinkBanner>
          <Image
            src={mobileBannerSrc}
            width={500}
            height={150}
            alt={altBanner}
          />
        </LinkBanner>
      </Box>
    );
  }

  return (
    <Box pb={4} display="flex" justifyContent="center">
      <LinkBanner>
        <Image
          src={desktopBannerSrc}
          width={1190}
          height={150}
          alt={altBanner}
        />
      </LinkBanner>
    </Box>
  );
};
