import { Box, Typography } from 'components/atoms';
import { ArticleItem } from 'models';
import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuid } from 'uuid';
import Image from 'next/image';
import styled from 'styled-components';
import { MobileContext } from 'utils/mobileContext';

const Styles = styled.div`
  .swiper-button-prev,
  .swiper-button-next {
    width: 50px;
    height: 50px;
    border: 1px solid;
    border-color: transparent;
    top: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.palette.neutral.white};
    color: ${({ theme }) => theme.palette.main.primary};
    transition: 0.2s;
  }
  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    background-color: ${({ theme }) => theme.palette.main.primary};
    color: ${({ theme }) => theme.palette.neutral.white};
  }

  .swiper-button-prev {
    left: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
  .swiper-button-next {
    right: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 20px;
    font-weight: 700;
  }
  .swiper-button-disabled {
    pointer-events: all;
  }
`;

export const Gallery: React.FC<{ data: Partial<ArticleItem> }> = ({ data }) => {
  const [currentSlide, setCurrentSlide] = React.useState<number>();
  const { isMobile } = React.useContext(MobileContext);
  return (
    <Box pb={5} px={isMobile ? 0 : 6}>
      <Typography
        variant={isMobile ? 'galleryTitleMobile' : 'galleryTitle'}
        mb={3}
      >
        {data?.title}
      </Typography>
      <Styles>
        <Swiper
          direction="horizontal"
          slidesPerView={1}
          spaceBetween={20}
          slidesPerGroup={1}
          style={{ maxHeight: '100%' }}
          modules={[Navigation]}
          navigation
          loop
          autoHeight
          onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex + 1)}
        >
          {data?.attachments?.map((elem) => (
            <SwiperSlide key={uuid()}>
              <Box height={isMobile ? 322 : 588}>
                <Image
                  src={elem.photo || '/images/shareCard.jpg'}
                  alt={elem.description}
                  layout="fill"
                  objectFit="contain"
                  placeholder="empty"
                  blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Styles>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        height="2rem"
        mt={3}
        px={3}
      >
        <Typography
          ml={3}
          variant="playlistItemTime"
          color="main.primary"
          style={{ whiteSpace: 'nowrap' }}
        >
          {currentSlide} / {data?.attachments?.length}
        </Typography>
        <Box
          borderRight="1px solid"
          borderColor="neutral.light"
          height="100%"
          mx={3}
        />
        <Typography variant="listCaption" color="neutral.dark">
          {data.title}
        </Typography>
      </Box>
    </Box>
  );
};
