import { Box, NewsMosaic, NewsList } from 'components';
import { noPadding } from 'helpers';
import { ArticleItem, ShowSound } from 'models';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { MobileContext } from 'utils/mobileContext';

export interface ITab {
  name: string;
  content: ArticleItem[];
  id?: number;
  isRadioShow?: boolean;
}
export interface INewsSectionProps {
  tabs: ITab[];
  audio: ShowSound[];
  highlight: ArticleItem[];
  allNews: ArticleItem[];
}

export const NewsSection: React.FC<INewsSectionProps> = ({
  tabs,
  audio,
  highlight,
  allNews,
}) => {
  const { isMobile, isDesktop } = React.useContext(MobileContext);
  return (
    <Box py={4} as="section">
      <Container fluid md>
        <Row>
          <Col xs={isDesktop ? 9 : 12} style={isMobile ? noPadding : {}}>
            <NewsMosaic tabs={tabs} audio={audio} />
          </Col>
          {isDesktop && (
            <Col xs={3} style={{ paddingLeft: 0 }}>
              <NewsList highlight={highlight} content={allNews} />
            </Col>
          )}
        </Row>
        {!isDesktop && (
          <Row style={{ marginTop: isMobile ? 20 : 50 }}>
            <Col xs={12} style={isMobile ? noPadding : {}}>
              <NewsList highlight={highlight} content={allNews} />
            </Col>
          </Row>
        )}
      </Container>
    </Box>
  );
};

export default NewsSection;
