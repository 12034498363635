/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import { Typography, StyledButton, Box } from 'components';
import SVG from 'react-inlinesvg';
import theme, { ThemeType } from 'theme';
import useHover from 'utils/useHover';
import isLast from 'utils/isLast';
import { ArticleItem } from 'models';

interface IOption {
  name: string;
  content: ArticleItem[];
}

interface IOptions {
  name: string;
  borderColor: string;
  onClick: () => void;
  isActive: boolean;
}

interface IDropdown {
  theme: ThemeType;
  options: Array<IOption>;
  activeOption: IOption;
  setActiveOption: (x: IOption) => void;
  borderRight?: boolean;
}

interface IList {
  color: string;
  isOpen: boolean;
  height: string;
}

interface IDropH {
  fillColor: string;
  strokeColor: string;
  fillColorHover: string;
  strokeColorHover: string;
  isOpen: boolean;
  onClick: () => void;
}

const DropDownHeader = styled.div<IDropH>`
  display: flex;
  align-items: center;
  justify-items: center;
  width: fit-content;
  svg * {
    fill: ${({ fillColor }) => fillColor};
    stroke: ${({ strokeColor }) => strokeColor};
  }
  &:hover svg * {
    fill: ${({ fillColorHover }) => fillColorHover};
    stroke: ${({ strokeColorHover }) => strokeColorHover};
  }
`;

const DropDownList = styled.ul<IList>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${({ color }) => color};
  margin-block-start: 0;
  padding: 0;
  max-height: ${({ isOpen, height }) => (isOpen ? height : 0)};
  overflow: hidden;
  transition: 0.8s;
`;

const ListItem = styled.li`
  list-style: none;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
`;

const StyledSvg = styled(SVG)`
  transition: 0.3s;
`;

interface IProps {
  color: string;
  baseColor?: string;
  decorator?: React.ReactNode | null;
  isOpen: boolean;
  borderRight?: boolean;
}

const ButtonD: React.FC<IProps> = ({
  children,
  baseColor = 'white',
  color,
  decorator = null,
  isOpen,
  borderRight = false,
}) => {
  const [hoverRef, isHover] = useHover<HTMLButtonElement>();
  const isMenuOpen = isHover || isOpen;

  return (
    <StyledButton
      ref={hoverRef}
      color={isMenuOpen ? color : baseColor}
      border="transparent"
      borderLeft={
        isMenuOpen
          ? theme.palette.main.primary
          : theme.palette.neutral.mediumGray
      }
      borderBottom={
        isMenuOpen
          ? theme.palette.main.primary
          : theme.palette.neutral.mediumGray
      }
      borderRight={
        borderRight
          ? isMenuOpen
            ? theme.palette.main.primary
            : theme.palette.neutral.mediumGray
          : 'transparent'
      }
      backgroundColor={isMenuOpen ? 'main.primary' : color}
      justifyContent="center"
    >
      <span>{children}</span>
      {decorator}
    </StyledButton>
  );
};

const Option: React.FC<IOptions> = ({
  name,
  borderColor,
  onClick,
  isActive,
}) => {
  const [hoverRef, isHover] = useHover<HTMLLIElement>();
  return (
    <ListItem ref={hoverRef} onClick={onClick}>
      <Box
        borderBottom="1px solid"
        borderColor={borderColor}
        display="flex"
        justifyContent={{ desktop: 'flex-end', mobile: 'center' }}
      >
        <Typography
          pr={4}
          variant="caption3"
          py={2}
          lineHeight={1.7}
          textAlign="right"
          color="neutral.dark"
          opacity={isHover || isActive ? 0.5 : 1}
          paddingRight={{ desktop: '32px', mobile: '0px' }}
        >
          {name}
        </Typography>
      </Box>
    </ListItem>
  );
};

const DropdownComponent: React.FC<IDropdown> = ({
  theme,
  options,
  activeOption,
  setActiveOption,
  borderRight = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  useEffect(() => {
    const listenForClicks = () => {
      setIsOpen(false);
      document.removeEventListener('click', listenForClicks);
    };
    if (isOpen) {
      document.addEventListener('click', listenForClicks);
    }
    return () => {
      document.removeEventListener('click', listenForClicks);
    };
  }, [isOpen]);

  return (
    <Box width="100%">
      <Box
        position={{ desktop: 'relative', mobile: 'unset' }}
        height="2rem"
        display="flex"
        justifyContent={borderRight ? 'center' : 'flex-end'}
      >
        <DropDownHeader
          isOpen={isOpen}
          strokeColor={
            isOpen ? theme.palette.neutral.white : theme.palette.neutral.dark
          }
          strokeColorHover={theme.palette.neutral.white}
          fillColor={
            isOpen ? theme.palette.main.primary : theme.palette.neutral.white
          }
          fillColorHover={theme.palette.main.primary}
          onClick={toggling}
        >
          <ButtonD
            isOpen={isOpen}
            baseColor="neutral.dark"
            color="neutral.white"
            borderRight={borderRight}
            decorator={
              <StyledSvg
                src="/icons/Arrow_up.svg"
                style={{
                  transform: `${isOpen ? 'rotateZ(270deg)' : 'rotateZ(90deg)'}`,
                }}
              />
            }
          >
            <Typography variant="caption3">Wybierz</Typography>
          </ButtonD>
          <Box
            width={{ desktop: '250px', mobile: '100%' }}
            position="absolute"
            zIndex={2}
            right="0"
            top={{ desktop: 33, tablet: 35, mobile: 33 }}
            px={borderRight ? '15px' : 0}
          >
            {options && (
              <DropDownList
                isOpen={isOpen}
                color={theme.palette.neutral.mediumLightGray}
                height={`${35.69 * options.length}px`}
              >
                {options.map((option: IOption) => (
                  <Option
                    key={option.name}
                    name={option.name}
                    borderColor={
                      isLast(options.indexOf(option), options.length)
                        ? 'transparent'
                        : 'neutral.gray'
                    }
                    onClick={() => {
                      setActiveOption(option);
                    }}
                    isActive={option === activeOption}
                  />
                ))}
              </DropDownList>
            )}
          </Box>
        </DropDownHeader>
      </Box>
    </Box>
  );
};
export const Dropdown = withTheme(DropdownComponent);
