import * as React from 'react';
import useWindowWidth from './useWindowWidth';

export const MobileContext = React.createContext({
  isMobile: false,
  isTablet: false,
  isDesktop: true,
});

export const MobileProvider: React.FC = ({ children }) => {
  const width = useWindowWidth();
  return (
    <MobileContext.Provider
      value={{
        isMobile: width.isMobile,
        isTablet: width.isTablet,
        isDesktop: width.isDesktop,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};
