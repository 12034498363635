import themeConfig from 'theme';
import React from 'react';
import styled from 'styled-components';
import {
  space,
  SpaceProps,
  variant,
  typography,
  TypographyProps,
  color,
  ColorProps,
  system,
} from 'styled-system';

export type IStyledLabel = {
  variant?: keyof typeof themeConfig.textStyles;
  children?: React.ReactNode;
  fluidFontSize?: number;
} & SpaceProps &
  TypographyProps &
  ColorProps;

export const StyledLabel = styled.label<IStyledLabel>(
  variant({
    scale: 'textStyles',
  }),
  typography,
  space,
  color,
  system({
    color: {
      property: 'color',
      scale: 'palette',
    },
    backgroundColor: {
      property: 'backgroundColor',
      scale: 'palette',
    },
    fluidFontSize: {
      property: 'fontSize',
      scale: 'fluidFontSizes',
    },
  })
);
