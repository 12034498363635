/* eslint-disable no-nested-ternary */
import { Box, SectionTitle, NewsListItem, Link, Button } from 'components';
import React from 'react';
import SVG from 'react-inlinesvg';
import { ArticleItem } from 'models';
import isLast from 'utils/isLast';
import { MobileContext } from 'utils/mobileContext';

export const NewsList: React.FC<{
  highlight: ArticleItem[];
  content: ArticleItem[];
}> = ({ highlight, content }) => {
  const { isDesktop, isMobile } = React.useContext(MobileContext);
  const parseDate = (date: string) => {
    return date.slice(11, 16);
  };
  return (
    <Box
      borderTopRightRadius="7px"
      backgroundColor="neutral.mediumGray"
      backgroundImage="url(/images/newsBox_bg.png)"
      backgroundRepeat="no-repeat"
      backgroundPosition="top right"
      backgroundSize="30%"
      px={isDesktop ? 20 : isMobile ? 3 : 4}
      py={26}
      height="100%"
    >
      <SectionTitle
        borderWidth={1}
        borderColor="neutral.grayWithOpacity"
        decorator
        decoratorHeight="13px"
        decoratorTop="14%"
        decoratorColor="main.primary"
        fontSize={2}
      >
        wiadomości
      </SectionTitle>
      <Box py={20}>
        {highlight?.[0] && (
          <NewsListItem
            key={highlight?.[0].id}
            href={`https://polskieradio24.pl/5/${
              highlight?.[0].categoryId
            }/artykul/${highlight?.[0].id},${highlight?.[0].title
              .replace(/[.,:?!/"]/g, '')
              .split(' ')
              .join('-')}`}
            title={highlight?.[0].title}
            borderColor="neutral.grayWithOpacity"
            image={highlight?.[0].photo}
            newCard
          />
        )}
        {content?.map((article, index) => {
          return (
            <NewsListItem
              key={article.id}
              href={`https://polskieradio24.pl/5/${
                article.categoryId
              }/artykul/${article?.id},${article?.title
                .replace(/[.,:?!/"]/g, '')
                .split(' ')
                .join('-')}`}
              hour={parseDate(article.datePublic)}
              title={article.title}
              borderColor={
                isLast(index, content?.length)
                  ? 'transparent'
                  : 'neutral.grayWithOpacity'
              }
              newCard
            />
          );
        })}
      </Box>
      <Box width={120} margin="0 auto">
        <Link href="https://polskieradio24.pl" newCard>
          <Button
            color="main.primary"
            decorator={<SVG src="/icons/arrow_right.svg" />}
          >
            Więcej
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default NewsList;
