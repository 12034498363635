/* eslint-disable no-nested-ternary */
import { Box, Typography, Link } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import SVG from 'react-inlinesvg';
import useHover from 'utils/useHover';
import Image from 'next/image';
import { MobileContext } from 'utils/mobileContext';

interface IProps {
  decoratorTop?: JSX.Element;
  decoratorBottom?: boolean;
  color?: string;
  hoveredColor?: string;
  article: ArticleItem;
  ultrawide?: boolean;
  lineClamp?: number;
  isMosaic?: boolean;
  isSlider?: boolean;
}

export const ArticleBoxLatest: React.FC<IProps> = ({
  decoratorTop = true,
  decoratorBottom = false,
  color = 'neutral.dark',
  hoveredColor = 'main.primary',
  article,
  ultrawide,
  lineClamp = 3,
  isMosaic = false,
  isSlider = false,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const { isMobile, isDesktop } = React.useContext(MobileContext);
  const imgSrc = article.attachments?.find((x) => x.fileType === 'Image')?.file;
  const customFieldsExtra = article?.customFields?.find(
    (el) => el.name === 'extra'
  );
  const link = article?.customFields?.find(
    (customField) => customField.name === 'link'
  )?.value;

  const isOnAir = article?.customFields?.find((el) => el.name === 'OnAir');
  return link ? (
    <Link href={link || ''}>
      <Box ref={link ? hoverRef : null} transition="0.3s" height="100%">
        <Box
          height={
            (isMosaic && isDesktop) || isSlider
              ? undefined
              : isMobile
              ? '100%'
              : '80%'
          }
          maxWidth="100%"
          position="relative"
          overflow="hidden"
          backgroundSize="cover"
          backgroundPosition={ultrawide ? 'top center' : 'center'}
          backgroundRepeat="no-repeat"
          aspectRatio={ultrawide ? '62/26' : '16/9'}
        >
          <Image
            src={imgSrc || '/images/shareCard.jpeg'}
            alt="miniatura artykułu"
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
            objectPosition={ultrawide ? 'top center' : 'center'}
          />
          <Box
            height="100%"
            width="100%"
            position="absolute"
            overflow="hidden"
            backgroundColor={isHover ? 'rgba(0, 0, 0, 0.4)' : 'tranparent'}
            transition="0.3s"
          />
          {decoratorTop && article?.customFields && customFieldsExtra && (
            <Typography
              variant="caption3"
              fontFamily="Montserrat"
              backgroundColor="main.secondary"
              color="neutral.white"
              position="absolute"
              py={2}
              px={3}
              top={0}
              left={0}
            >
              {customFieldsExtra.value}
            </Typography>
          )}
          {decoratorBottom && (
            <Box
              display="flex"
              position="absolute"
              bottom={0}
              width="100%"
              left={0}
              backgroundColor={isOnAir?.value ? '#e8ecf6' : ''}
            >
              <Typography
                variant="caption3"
                fontFamily="Montserrat"
                backgroundColor="main.primary"
                color="neutral.white"
                py={2}
                px={3}
                height="fit-content"
              >
                {article?.categoryName?.toUpperCase()}
              </Typography>
              {isOnAir?.value && (
                <Typography variant="caption3" py={2} px={3}>
                  {isOnAir?.value}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <Typography
            variant={isMobile ? 'leadMobile' : 'lead'}
            color={isHover ? hoveredColor : color}
            display="inline"
            transition="0.3s"
            fill={isHover ? hoveredColor : color}
          >
            <span
              style={{
                WebkitLineClamp: lineClamp,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-inline-box',
              }}
            >
              <Box display="inline-flex !important" alignItems="center">
                {article?.hasAudio && (
                  <span style={{ marginRight: 10 }}>
                    <SVG src="/icons/audio.svg" />
                  </span>
                )}
                {article?.hasVideo && (
                  <span style={{ marginRight: 10 }}>
                    <SVG src="/icons/video.svg" />
                  </span>
                )}
              </Box>
              {article?.title}
            </span>
          </Typography>
        </Box>
      </Box>
    </Link>
  ) : (
    <Box height="100%">
      <Box
        height={
          (isMosaic && isDesktop) || isSlider
            ? undefined
            : isMobile
            ? '100%'
            : '80%'
        }
        maxWidth="100%"
        position="relative"
        overflow="hidden"
        backgroundSize="cover"
        backgroundPosition={ultrawide ? 'top center' : 'center'}
        backgroundRepeat="no-repeat"
        aspectRatio={ultrawide ? '62/26' : '16/9'}
      >
        <Image
          src={imgSrc || '/images/shareCard.jpeg'}
          alt="miniatura artykułu"
          layout="fill"
          objectFit="cover"
          placeholder="blur"
          blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
          objectPosition={ultrawide ? 'top center' : 'center'}
        />
        <Box
          height="100%"
          width="100%"
          position="absolute"
          overflow="hidden"
          backgroundColor={isHover ? 'rgba(0, 0, 0, 0.4)' : 'tranparent'}
          transition="0.3s"
        />
        {decoratorTop && article?.customFields && customFieldsExtra && (
          <Typography
            variant="caption3"
            fontFamily="Montserrat"
            backgroundColor="main.secondary"
            color="neutral.white"
            position="absolute"
            py={2}
            px={3}
            top={0}
            left={0}
          >
            {customFieldsExtra.value}
          </Typography>
        )}
        {decoratorBottom && (
          <Box
            display="flex"
            position="absolute"
            bottom={0}
            width="100%"
            left={0}
            backgroundColor={isOnAir?.value ? '#e8ecf6' : ''}
          >
            <Typography
              variant="caption3"
              fontFamily="Montserrat"
              backgroundColor="main.primary"
              color="neutral.white"
              py={2}
              px={3}
              height="fit-content"
            >
              {article?.categoryName?.toUpperCase()}
            </Typography>
            {isOnAir?.value && (
              <Typography variant="caption3" py={2} px={3}>
                {isOnAir?.value}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <Box mt={2}>
        <Typography
          variant={isMobile ? 'leadMobile' : 'lead'}
          color={isHover ? hoveredColor : color}
          display="inline"
          transition="0.3s"
          fill={isHover ? hoveredColor : color}
        >
          <span
            style={{
              WebkitLineClamp: lineClamp,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              display: '-webkit-inline-box',
            }}
          >
            <Box display="inline-flex !important" alignItems="center">
              {article?.hasAudio && (
                <span style={{ marginRight: 10 }}>
                  <SVG src="/icons/audio.svg" />
                </span>
              )}
              {article?.hasVideo && (
                <span style={{ marginRight: 10 }}>
                  <SVG src="/icons/video.svg" />
                </span>
              )}
            </Box>
            {article?.title}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default ArticleBoxLatest;
