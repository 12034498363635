/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useRef } from 'react';
import { PlayerContext } from 'utils/PlayerContext';
import { BaseReactPlayerProps } from 'react-player/base';
import { Box, Typography } from 'components';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { motion } from 'framer-motion';
import { convertFileExtension } from 'utils/convertFileExtension';
import {
  onPlaylistItemClick,
  deleteFromPlaylist,
  onEnded,
  onPlayClick,
  setLocalPlayerState,
} from 'utils/player';
import theme from 'theme';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import isLast from 'utils/isLast';

interface ITrack {
  id?: number;
  title?: string;
  artist?: string;
  cover?: string;
  url: string;
  airTime?: string;
}

export const PlayerAlbumCover = styled.img`
  height: 47px;
  border: 1px solid white;
`;

export const StyledSvg = styled(SVG)`
  width: 11px;
  height: auto;
`;

export const BoxWithSvg = styled.div`
  display: flex;
  svg * {
    fill: ${theme.palette.neutral.dark} !important;
  }
`;

export const ScrollContainer = styled.div`
  width: 100%;
  .simplebar-track {
    background: ${theme.palette.neutral.grayWithOpacity};
    width: 3px;
    right: 15px;
    top: 15px;
    bottom: 15px;
    overflow: visible;
    z-index: 9999;
  }
  .simplebar-scrollbar {
    width: 3px;
    right: 0;
    background: ${theme.palette.main.primary};
    z-index: 9999;
  }
`;

const RenderPlaylist: React.FC<{ playlistItem: ITrack }> = ({
  playlistItem,
}) => {
  const playerContext = React.useContext(PlayerContext);
  const track = playerContext?.player?.track;
  const isNowPlaying = playlistItem.url === track?.url;

  if (!playlistItem) return null;
  return (
    <Box
      key={playlistItem.url + playlistItem.artist + playlistItem.title}
      display="flex"
      justifyContent="space-between"
      height={40}
      marginLeft={3}
      marginRight={3}
      borderColor={
        isLast(
          playerContext.player.playlist.indexOf(playlistItem),
          playerContext.player.playlist.length
        )
          ? 'transparent'
          : theme.palette.neutral.grayWithOpacity
      }
      borderBottom="1px solid"
    >
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        onClick={() => onPlaylistItemClick(playlistItem, playerContext)}
      >
        <span
          style={{
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            display: '-webkit-inline-box',
          }}
        >
          <Typography
            variant="caption"
            color={isNowPlaying ? 'main.primary' : 'neutral.dark'}
            opacity={0.8}
            fontSize={0}
          >
            {playlistItem.title}
          </Typography>
        </span>
      </Box>
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={() => deleteFromPlaylist(playlistItem, playerContext)}
        pr={2}
        as="button"
        border="none"
        backgroundColor="transparent"
      >
        <SVG src="/icons/remove_track.svg" />
      </Box>
    </Box>
  );
};

interface Props {
  isHotTopic: boolean;
}

export const PlayerMobile: React.FC<Props> = ({ isHotTopic }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const playerContext = React.useContext(PlayerContext);
  const playerRef = React.useRef<null | ReactPlayer>(null);
  const track = playerContext?.player?.track;
  const stream = playerContext?.player?.stream;
  const isStreamPlaying = !track && playerContext.player.isPlaying;
  const [progress, setProgress] = React.useState(0);
  const gemiusPlayer = useRef<any>(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.GemiusPlayer) {
      const player = new window.GemiusPlayer(
        'Jedynka - Polskie Radio',
        'ByNF1isdtexh7lOTa2Lgr3YZXkEZHq_fvaoXHc3hfgP.y7',
        {
          volume: 100,
        }
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      player.setVideoObject(playerRef.current.wrapper.children[0]);
      gemiusPlayer.current = player;

      return () => {
        player.dispose();
      };
    }
  }, [playerContext]);

  useEffect(() => {
    if (
      gemiusPlayer.current &&
      playerContext.player.isStream &&
      playerContext.player.isPlaying
    ) {
      gemiusPlayer.current.newProgram('stream', {
        programName: playerContext.player.stream.title,
        programType: 'audio',
        duration: -1,
      });
    } else if (gemiusPlayer.current && playerContext.player.isPlaying) {
      gemiusPlayer.current.newProgram('track', {
        programName: playerContext.player.track?.title,
        duration: playerContext.player.duration,
        programType: 'audio',
      });
    }
  }, [playerContext, gemiusPlayer.current]);

  const handleSeek = (initialPlayingFlag: boolean) => {
    if (initialPlayingFlag) {
      playerContext.setPlayerContext({
        type: 'SET_PLAYER',
        payload: {
          isPlaying: false,
        },
      });
    }

    const handleMove = (e: TouchEvent) => {
      const draggedProgress = Math.min(
        1,
        Math.max(e.touches[0].clientX / window.innerWidth)
      );
      playerRef.current?.seekTo(draggedProgress, 'fraction');
    };

    const restoreInitialState = () => {
      playerContext.setPlayerContext({
        type: 'SET_PLAYER',
        payload: {
          isPlaying: initialPlayingFlag,
        },
      });
      document.removeEventListener('touchend', restoreInitialState);
      document.removeEventListener('touchmove', handleMove);
    };

    document.addEventListener('touchend', restoreInitialState);
    document.addEventListener('touchmove', handleMove);
  };

  const onReady = (x: BaseReactPlayerProps) => {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        duration: x.getDuration(),
      },
    });
  };

  const togglePlaylist = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    setLocalPlayerState(playerContext, true);
  }, []);

  const handlePlay = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'play', {
          autoPlay: false,
        });
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'play', {
          autoPlay: false,
        });
      }
    }
  };

  const handlePause = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'pause');
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'pause');
      }
    }
  };

  const handleEnded = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'complete');
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'complete');
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    track && onEnded(playerContext, track);
  };

  const handleStop = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'stop');
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'stop');
      }
    }
  };

  const handleBuffer = () => {
    if (gemiusPlayer.current) {
      if (playerContext.player.isStream) {
        gemiusPlayer.current.programEvent('stream', 0, 'buffer');
      } else {
        gemiusPlayer.current.programEvent('track', 0, 'buffer');
      }
    }
  };

  return (
    <Box width="100%" backgroundColor="neutral.gray">
      <ReactPlayer
        key="player"
        ref={playerRef}
        width={0}
        height={0}
        playing={playerContext.player.isPlaying}
        onReady={onReady}
        onEnded={handleEnded}
        onPlay={handlePlay}
        onPause={handlePause}
        onBuffer={handleBuffer}
        onClick={handleStop}
        progressInterval={50}
        playsinline
        onProgress={(progress) => setProgress(progress.played)}
        config={{
          file: {
            forceAudio: true,
          },
        }}
        url={
          convertFileExtension(track?.url, 'mp3') ||
          playerContext.player.stream.url
        }
      />
      <motion.div
        style={{
          position: 'fixed',
          bottom: isHotTopic ? '30px' : 0,
          zIndex: 99,
          width: '100%',
          maxHeight: 300,
          height: 0,
        }}
        animate={
          playerContext.player.isVisible
            ? {
                height: isOpen
                  ? 60 + 40 * playerContext.player.playlist.length
                  : 60,
              }
            : { height: 0 }
        }
        transition={{ ease: 'easeOut', duration: 0.3 }}
      >
        <Box
          backgroundColor="neutral.gray"
          display="flex"
          justifyContent="space-between"
          position="relative"
        >
          {track && (
            <>
              <Box
                position="absolute"
                height="4px"
                width={10}
                left={0}
                top={0}
                backgroundColor="main.primary"
              />
              <Box
                marginX={10}
                width="calc(100% - 20px)"
                position="absolute"
                height={4}
              >
                <Box
                  backgroundColor="main.primary"
                  position="absolute"
                  top={0}
                  left={0}
                  width={`${100 * progress}%`}
                  height={4}
                >
                  <Box
                    position="absolute"
                    width="20px"
                    height="20px"
                    border="4px solid"
                    borderColor="main.primary"
                    backgroundColor="neutral.white"
                    borderRadius="50%"
                    zIndex="999"
                    top={0}
                    left="100%"
                    transform="translate3d(-50%, -40%, 0)"
                  />
                  <Box
                    position="absolute"
                    width="30px"
                    height="30px"
                    zIndex="999"
                    top={0}
                    borderRadius="50%"
                    left="100%"
                    transform="translate3d(-50%, -45%, 0)"
                    onTouchStart={() =>
                      handleSeek(playerContext.player.isPlaying)
                    }
                  />
                </Box>
              </Box>
            </>
          )}
          <Box display="flex">
            <Box
              width={60}
              height={60}
              display="flex"
              justifyContent="center"
              alignItems="center"
              backgroundColor="neutral.playerGray"
            >
              <Box
                cursor="pointer"
                height={32}
                as="button"
                border="none"
                backgroundColor="transparent"
              >
                <SVG
                  src={
                    playerContext.player.isPlaying
                      ? '/icons/pause_player.svg'
                      : '/icons/play_player.svg'
                  }
                  onClick={() => onPlayClick(playerContext, isStreamPlaying)}
                />
              </Box>
            </Box>
            <Box
              ml={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              opacity={0.8}
            >
              <span
                style={{
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  display: '-webkit-inline-box',
                }}
              >
                <Typography
                  variant="playerTitle"
                  color="neutral.dark"
                  fontSize={0}
                  lineHeight="16px"
                  maxWidth={250}
                >
                  {track?.title || stream?.title}
                </Typography>
              </span>
              <Typography
                variant="playerHour"
                color="neutral.dark"
                fontSize="9px"
              >
                {playerContext.player.track?.airTime}
              </Typography>
            </Box>
          </Box>
          <Box
            width={60}
            height={60}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              cursor="pointer"
              opacity={playerContext.player.playlist.length > 0 ? 1 : 0.3}
              as="button"
              border="none"
              backgroundColor="transparent"
            >
              <SVG
                src="/icons/playlist.svg"
                onClick={
                  playerContext.player.playlist.length > 0
                    ? togglePlaylist
                    : () => null
                }
              />
            </Box>
          </Box>
        </Box>
        <Box width="100%" height="100%" backgroundColor="neutral.light">
          <ScrollContainer>
            <SimpleBar style={{ height: 240, width: '100%' }}>
              {playerContext?.player?.playlist?.map((track: ITrack) => (
                <RenderPlaylist
                  key={track.url + track.artist + track.title + Math.random()}
                  playlistItem={track}
                />
              ))}
            </SimpleBar>
          </ScrollContainer>
        </Box>
      </motion.div>
    </Box>
  );
};

export default PlayerMobile;
