import styled, { css } from 'styled-components';
import themeConfig from 'theme';

import {
  space,
  SpaceProps,
  color,
  ColorProps,
  border,
  BorderProps,
  system,
  BackgroundColorProps,
  borderColor,
  BorderColorProps,
  LayoutProps,
  layout,
  variant,
  typography,
  TypographyProps,
} from 'styled-system';

export interface IStyledInput
  extends ColorProps,
    BorderProps,
    SpaceProps,
    BackgroundColorProps,
    BorderColorProps,
    LayoutProps,
    TypographyProps {
  isTitleInput?: boolean;
  placeholderColor?: string;
  variant?: keyof typeof themeConfig.textStyles;
  fluidFontSize?: number;
}

export const StyledInput = styled.input<IStyledInput>(
  variant({
    scale: 'textStyles',
  }),
  typography,
  space,
  color,
  border,
  borderColor,
  layout,
  system({
    color: {
      property: 'color',
      scale: 'palette',
    },
    backgroundColor: {
      property: 'backgroundColor',
      scale: 'palette',
    },
    fluidFontSize: {
      property: 'fontSize',
      scale: 'fluidFontSizes',
    },
    borderColor: {
      property: 'borderColor',
      scale: 'palette',
    },
  }),
  ({ isTitleInput }) => {
    return css`
      border-top: none;
      border-left: ${isTitleInput ? '5px solid' : 'none'};
      border-left-color: ${({ theme }) =>
        isTitleInput ? theme.palette.main.primary : 'none'};
      border-right: none;
      outline: none;
      :: placeholder {
        ${system({
          placeholderColor: {
            property: 'color',
            scale: 'palette',
          },
        })}
      }
    `;
  }
);
