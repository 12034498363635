/* eslint-disable no-nested-ternary */
import { Box, Typography } from 'components';
import { PlaylistItem } from 'models';
// import SVG from 'react-inlinesvg';
// import { convertSeconds } from 'utils/convertSeconds';
// import { PlayerContext } from 'utils/PlayerContext';
// import useHover from 'utils/useHover';
import React from 'react';

export const PlaylistBox: React.FC<{
  sound: PlaylistItem;
  mobile?: boolean;
}> = ({ sound, mobile }) => {
  // const [hoverRef, isHovered] = useHover<HTMLImageElement>();
  // const playerContext = React.useContext(PlayerContext);
  // const url = sound?.file?.split('api')[0];
  // const track = {
  //   title: sound?.title,
  //   cover: sound?.cover,
  //   url,
  //   artist: sound?.artist,
  //   airTime: `${new Date(sound?.startTime).toLocaleString('pl-pl', {
  //     day: 'numeric',
  //     month: 'long',
  //     year: 'numeric',
  //     hour: 'numeric',
  //     minute: 'numeric',
  //   })}`,
  // };
  // const isNowPlaying =
  //   url === playerContext?.player?.track?.url && playerContext.player.isPlaying;
  // const [isOnPlaylist, setIsOnPlaylist] = React.useState<boolean>(false);
  // const onPlayClick = () => {
  //   playerContext.setPlayerContext({
  //     type: 'SET_PLAYER',
  //     payload: {
  //       track,
  //       isPlaying:
  //         url === playerContext?.player?.track?.url
  //           ? !playerContext.player.isPlaying
  //           : true,
  //       isVisible: true,
  //     },
  //   });
  // };

  // const addToPlaylist = () => {
  //   playerContext.setPlayerContext({
  //     type: 'SET_PLAYER',
  //     payload: {
  //       playlist:
  //         playerContext.player.playlist.findIndex(
  //           (x) => x.url === track.url
  //         ) === -1
  //           ? [...playerContext.player.playlist, track]
  //           : playerContext.player.playlist,
  //     },
  //   });
  //   localStorage.setItem(
  //     'playlist',
  //     JSON.stringify([...playerContext.player.playlist, track])
  //   );
  // };

  // React.useEffect(() => {
  //   setIsOnPlaylist(
  //     playerContext.player.playlist.some((x) => x.url === track.url)
  //   );
  // }, [playerContext.player.playlist]);
  // if (!sound) return null;

  return (
    <Box
      width="100%"
      py={2}
      display="flex"
      justifyContent="space-between"
      backgroundColor="neutral.light"
      borderBottom="2px solid"
      borderColor="neutral.lightGray"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        // borderRight="1px solid"
        // borderColor="neutral.grayWithOpacity"
        px={mobile ? 1 : 2}
      >
        {/* <Box
          cursor={url ? 'pointer' : 'auto'}
          width={50}
          display="flex"
          justifyContent="center"
          style={{ filter: !url ? 'grayscale(1)' : undefined }}
        >
          <SVG
            src={isNowPlaying ? '/icons/pause_audio.svg' : '/icons/play.svg'}
            height={mobile ? '31px' : '40px'}
            onClick={() => url && onPlayClick()}
          />
        </Box>
        <Typography
          as="p"
          variant={mobile ? 'playlistItemTimeMobile' : 'playlistItemTime'}
          color="main.primary"
          mt={mobile ? 1 : 2}
          style={{ filter: !url ? 'grayscale(1)' : undefined }}
        >
          {convertSeconds(+sound?.duration)}
        </Typography> */}
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        pl={3}
      >
        <Typography
          as="p"
          variant={mobile ? 'playlistItemTitleMobile' : 'playlistItemTitle'}
          width="100%"
        >
          {sound?.title}
        </Typography>
        <Typography
          as="p"
          variant={mobile ? 'playlistItemTitleMobile' : 'playlistItemTime'}
          fontWeight="500"
          textAlign="left"
          mt={2}
        >
          {new Date(sound?.startTime).toLocaleString('pl-pl', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </Typography>
      </Box>
      {/* <Box
        display="flex"
        alignItems="center"
        px={mobile ? 2 : 4}
        borderLeft={mobile ? '' : '1px solid'}
        borderColor="neutral.grayWithOpacity"
        style={{ filter: !url ? 'grayscale(1)' : undefined }}
      >
        <button
          onClick={() => url && addToPlaylist()}
          type="button"
          style={{
            background: 'none',
            border: 'none',
            outline: 'none',
            padding: 0,
          }}
        >
          <img
            src={
              isOnPlaylist
                ? '/icons/added.svg'
                : isHovered && url
                ? '/icons/add_hovered.svg'
                : '/icons/add.svg'
            }
            height={mobile ? '21px' : '30px'}
            ref={hoverRef}
            alt="Dodaj do playlisty"
            style={{ cursor: isOnPlaylist || !url ? 'auto' : 'pointer' }}
          />
        </button>
      </Box> */}
    </Box>
  );
};
