/* eslint-disable object-curly-newline */
import { ScheduleBoxItem, SwiperBox } from 'components';
import { ScheduleProgram } from 'models';
import { Swiper } from 'swiper/types';
import React, { useEffect } from 'react';
import { PlayerContext } from 'utils/PlayerContext';

export const ScheduleFetchBox: React.FC<{ list: ScheduleProgram[] }> = ({
  list,
}) => {
  const [nowPlaying, setNowPlaying] = React.useState<ScheduleProgram[]>([]);
  const { player } = React.useContext(PlayerContext);

  const swiperRef = React.useRef<Swiper>();
  React.useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(list.findIndex((e) => e.isOnAir, 0));
    }
  }, [player.stream, swiperRef.current]);

  useEffect(() => {
    setNowPlaying(list.filter((x) => x.isOnAir));
  }, [player.stream, list]);

  return (
    <SwiperBox
      id="schedule"
      rounded="tl"
      title="RAMÓWKA"
      data={list}
      onSwiper={(swiper: Swiper) => {
        swiperRef.current = swiper;
      }}
      render={(elem) => {
        return (
          <ScheduleBoxItem
            key={elem.id + elem.startTime}
            title={elem.title}
            startTime={elem.startTime}
            isOnAir={nowPlaying[0] === elem}
            href={elem.categoryId ? `/audycje/${elem.categoryId}` : undefined}
          />
        );
      }}
    />
  );
};

export default ScheduleFetchBox;
