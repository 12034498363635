/* eslint-disable no-nested-ternary */
import { Box, Typography } from 'components';
import { ShowSound } from 'models';
import SVG from 'react-inlinesvg';
import { convertSeconds } from 'utils/convertSeconds';
import { PlayerContext } from 'utils/PlayerContext';
import useHover from 'utils/useHover';
import React from 'react';

export const SoundBox: React.FC<{
  sound: ShowSound;
  mobile?: boolean;
  showDate?: boolean;
}> = ({ sound, mobile, showDate = true }) => {
  const [hoverRef, isHovered] = useHover<HTMLImageElement>();
  const playerContext = React.useContext(PlayerContext);
  const playertrack = playerContext?.player?.track;
  const track = {
    id: sound.id,
    title: sound.title,
    url: sound.file,
    airTime: `${new Date(sound.datePublic).toLocaleString('pl-pl', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })}`,
    cover: '/images/cover.png',
  };
  const isNowPlaying =
    sound?.file === playertrack?.url && playerContext.player.isPlaying;
  const [isOnPlaylist, setIsOnPlaylist] = React.useState<boolean>(false);

  const onPlayClick = () => {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        track,
        isPlaying:
          track.url === playerContext?.player?.track?.url
            ? !playerContext.player.isPlaying
            : true,
        isVisible: true,
      },
    });
  };

  const addToPlaylist = () => {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        playlist:
          playerContext.player.playlist.findIndex(
            (x) => x.url === track.url
          ) === -1
            ? [...playerContext.player.playlist, track]
            : playerContext.player.playlist,
      },
    });
    localStorage.setItem(
      'playlist',
      JSON.stringify([...playerContext.player.playlist, track])
    );
  };

  React.useEffect(() => {
    setIsOnPlaylist(
      playerContext.player.playlist.some((x) => x.url === track.url)
    );
  }, [playerContext.player.playlist]);

  return (
    <Box
      width="100%"
      py={2}
      display="flex"
      justifyContent="space-between"
      backgroundColor="neutral.light"
      borderBottom="2px solid"
      borderColor="neutral.lightGray"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        borderRight="1px solid"
        borderColor="neutral.grayWithOpacity"
        px={mobile ? 2 : 4}
      >
        <Box
          cursor={track.url ? 'pointer' : 'auto'}
          width={50}
          display="flex"
          justifyContent="center"
          as="button"
          border="none"
          aria-label="Odtwarzaj"
          backgroundColor="transparent"
          onClick={() => track.url && onPlayClick()}
          style={{ filter: !track.url ? 'grayscale(1)' : undefined }}
        >
          <SVG
            src={isNowPlaying ? '/icons/pause_audio.svg' : '/icons/play.svg'}
            height={mobile ? '31px' : '40px'}
          />
        </Box>
        <Typography
          as="p"
          variant={mobile ? 'playlistItemTimeMobile' : 'caption2'}
          fontFamily="Roboto"
          color="main.primary"
          style={{ filter: !track.url ? 'grayscale(1)' : undefined }}
          mt={2}
        >
          {convertSeconds(+sound.duration)}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        pl={3}
      >
        <Typography
          as="p"
          variant={mobile ? 'playlistItemTitleMobile' : 'lead'}
          fontFamily="Roboto"
        >
          {sound.title}
        </Typography>
        {showDate && (
          <Typography
            as="p"
            variant={mobile ? 'playlistItemTitleMobile' : 'lead'}
            fontFamily="Roboto"
            fontWeight="500"
            textAlign="left"
            mt={2}
          >
            {new Date(sound.datePublic).toLocaleString('pl-pl', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </Typography>
        )}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        pr={mobile ? 3 : ''}
        px={mobile ? 3 : 4}
        borderLeft={mobile ? '' : '1px solid'}
        borderColor="neutral.grayWithOpacity"
        style={{ filter: !track.url ? 'grayscale(1)' : undefined }}
      >
        <Box
          onClick={() => track.url && addToPlaylist()}
          as="button"
          border="none"
          backgroundColor="transparent"
        >
          <img
            src={
              isOnPlaylist
                ? '/icons/added.svg'
                : isHovered
                ? '/icons/add_hovered.svg'
                : '/icons/add.svg'
            }
            height={mobile ? '21px' : '30px'}
            ref={hoverRef}
            alt="Dodaj do playlisty"
            style={{ cursor: isOnPlaylist || !track.url ? 'auto' : 'pointer' }}
          />
        </Box>
      </Box>
    </Box>
  );
};
