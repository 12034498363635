import { API_URL } from 'config';
import Axios, { AxiosError, CancelToken } from 'axios';
import {
  ScheduleProgram,
  ScheduleItem,
  ArticleItem,
  ArticleDetails,
  TeamMember,
  ShowDetails,
  ShowHost,
  ShowEpisode,
  ShowSound,
  HostDetails,
  Podcast,
  Category,
  ShowPlaylist,
  ShowItem,
  BoxInstanceId,
  GalleryDetails,
  ArticlePlaylistModel,
  IHotTopic,
  RelatedGallery,
  ShowBoxConfig,
  LiveEvent,
  ArchivedShowSound,
} from 'models';

const errorHandler = (error: AxiosError) => {
  // eslint-disable-next-line no-console
  console.log(error.message);
};

const API_KEY = process.env.API_KEY || '';

export class API {
  static updateLiveTicker = async (id: string) => {
    try {
      const res = await Axios.get<LiveEvent[]>(
        `https://relacjelive.polskieradio.pl/relation/${id}`
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getSchedule = async (date: string) => {
    try {
      const res = await Axios.get<{ data: ScheduleProgram[] }>(
        `${API_URL}/Schedule/GetByDate?Station=Jedynka&Date=${date}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getScheduleList = async (date: string) => {
    try {
      const res = await Axios.get<{ data: ScheduleItem[] }>(
        `${API_URL}/Schedule/GetList?Date=${date}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getPlaylist = async (date: string) => {
    try {
      const res = await Axios.get<{ data: ShowPlaylist[] }>(
        `${API_URL}/Dalet/GetPlaylist?Station=Jedynka&Date=${date}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getArticles = async (
    categoryId: number,
    pageSize: number,
    skip: number,
    cancelToken?: CancelToken
  ) => {
    try {
      const res = await Axios.get<{ data: ArticleItem[]; total: number }>(
        `${API_URL}/Article/GetListByCategoryId?categoryId=${categoryId}&pageSize=${pageSize}&skip=${skip}&format=700&getCustomFields=true`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
          cancelToken,
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getArticlesBySectionId = async (
    sectionId: number,
    pageSize: number,
    skip: number,
    cancelToken?: CancelToken
  ) => {
    try {
      const res = await Axios.get<{ data: ArticleItem[]; total: number }>(
        `${API_URL}/Article/GetListBySectionId?sectionId=${sectionId}&PageSize=${pageSize}&Skip=${skip}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
          cancelToken,
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static GetPopularList = async (
    pageSize: number,
    cancelToken?: CancelToken
  ) => {
    try {
      const res = await Axios.get<{ data: ArticleItem[]; total: number }>(
        `${API_URL}/PopularArticle/GetListBySectionId?sectionId=7&top=${pageSize}&hours=24`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
          cancelToken,
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static GetPopularArticleList = async (
    categoryId: number,
    pageSize: number,
    cancelToken?: CancelToken
  ) => {
    try {
      const res = await Axios.get<{ data: ArticleItem[]; total: number }>(
        `${API_URL}/PopularArticle/GetListByCategoryIds?categoryIds=${categoryId}&Top=${pageSize}&hours=48`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
          cancelToken,
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getArticlesWithCustomFields = async (
    categoryId: number,
    pageSize: number,
    skip: number,
    cancelToken?: CancelToken
  ) => {
    try {
      const res = await Axios.get<{ data: ArticleItem[]; total: number }>(
        `${API_URL}/Article/GetArticlesWithCustomFieldsByCategoryId?categoryId=${categoryId}&pageSize=${pageSize}&skip=${skip}&format=700`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
          cancelToken,
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getSingleArticle = async (
    id: number,
    getCustomFields: boolean = false,
    isHighQuality: boolean = false
  ) => {
    try {
      const res = await Axios.get<{ data: ArticleDetails }>(
        `${API_URL}/Article/Get?id=${id}&getCustomFields=${getCustomFields}${
          isHighQuality ? '' : '&format=700'
        }`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getTeam = async () => {
    try {
      const res = await Axios.get<{ data: TeamMember[]; total: number }>(
        `${API_URL}/TeamMember/GetList?Station=Jedynka`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getTeamByLetter = async (letter: string | string[]) => {
    try {
      const res = await Axios.get<{ data: TeamMember[]; total: number }>(
        `${API_URL}/TeamMember/GetList?Station=Jedynka&Query=${letter}&IgnoreDiacritics=true`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getShowsByLetter = async (letter: string | string[]) => {
    try {
      const res = await Axios.get<{ data: TeamMember[]; total: number }>(
        `${API_URL}/RadioShow/GetListByStation?Station=Jedynka&Query=${letter}&IgnoreDiacritics=true`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getShowDetails = async (id: number) => {
    try {
      const res = await Axios.get<{ data: ShowDetails }>(
        `${API_URL}/RadioShow/GetDetails?id=${id}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getShowDetailsByCategory = async (
    id: number,
    isHighQuality?: boolean
  ) => {
    try {
      const res = await Axios.get<{ data: ShowDetails }>(
        `${API_URL}/RadioShow/GetDetailsByCategoryId?categoryId=${id}${
          isHighQuality ? '' : '&format=700'
        }`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getShowArticles = async (
    id: number,
    skip: number,
    pageSize: number
  ) => {
    try {
      const res = await Axios.get<{ data: ArticleItem[]; total: number }>(
        `${API_URL}/Article/GetListByRadioShowId?radioShowId=${id}&skip=${skip}&pageSize=${pageSize}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getShowHosts = async (id: number) => {
    try {
      const res = await Axios.get<{ data: ShowHost[] }>(
        `${API_URL}/RadioShow/GetHosts?radioShowId=${id}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getShowHostsByCategory = async (id: number) => {
    try {
      const res = await Axios.get<{ data: ShowHost[] }>(
        `${API_URL}/RadioShow/GetHostsByCategoryId?categoryId=${id}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getHostDetails = async (id: number) => {
    try {
      const res = await Axios.get<{ data: HostDetails }>(
        `${API_URL}/TeamMember/GetDetails?articleId=${id}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getArchivedEpisodes = async (
    id: number,
    skip: number,
    pageSize: number
  ) => {
    try {
      const res = await Axios.get<{ data: ShowEpisode[]; total: number }>(
        `${API_URL}/RadioShow/GetArchivedEpisodesListByRadioShowId?radioShowId=${id}&skip=${skip}&pageSize=${pageSize}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getShowSoundsByCategory = async (
    id: number,
    skip: number,
    pageSize: number
  ) => {
    try {
      const res = await Axios.get<{ data: ShowEpisode[]; total: number }>(
        `${API_URL}/AudioArticle/GetListByCategoryId?categoryId=${id}&skip=${skip}&pageSize=${pageSize}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getRadioShowSounds = async (
    id: number,
    skip: number,
    pageSize: number
  ) => {
    try {
      const res = await Axios.get<{ data: ShowSound[]; total: number }>(
        `${API_URL}/AudioArticle/GetListByRadioShowId?radioShowId=${id}&skip=${skip}&pageSize=${pageSize}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static contactStation = async (
    signature: string,
    email: string,
    message: string,
    title: string,
    ipAddress: string,
    browser: string,
    computerName: string,
    operatingSystem: string,
    userName: string,
    sourcePort: string
  ) => {
    try {
      const res = await Axios.post<{
        signature: string;
        from: string;
        subject: string;
        station: string;
        content: string;
        ipAddress: string;
        browser: string;
        computerName: string;
        operatingSystem: string;
        userName: string;
        sourcePort: string;
      }>('https://lp3test.polskieradio.pl/Email/SendEmailToStation', {
        signature,
        from: email,
        content: message,
        station: 'Jedynka',
        subject: title,
        ipAddress,
        browser,
        computerName,
        operatingSystem,
        userName,
        sourcePort,
      });
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static contactCreator = async (
    signature: string,
    email: string,
    message: string,
    title: string,
    articleId: number,
    ipAddress: string,
    browser: string,
    computerName: string,
    operatingSystem: string,
    userName: string,
    sourcePort: string
  ) => {
    try {
      const res = await Axios.post<{
        signature: string;
        from: string;
        articleId: number;
        content: string;
        ipAddress: string;
        subject: string;
        browser: string;
        computerName: string;
        operatingSystem: string;
        userName: string;
        sourcePort: string;
      }>('https://lp3test.polskieradio.pl/Email/SendEmailToPerson', {
        signature,
        from: email,
        content: message,
        articleId,
        subject: 'Temat',
        ipAddress,
        browser,
        computerName,
        operatingSystem,
        userName,
        sourcePort,
      });
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getPodcasts = async () => {
    try {
      const res = await Axios.get<{ items: Podcast[] }>(
        'https://apipodcasts.polskieradio.pl/api/Podcasts?page=1&pageSize=3&radioStation=7'
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getCategoryName = async (id: number) => {
    try {
      const res = await Axios.get<{ data: Category }>(
        `${API_URL}/Category/Get?id=${id}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getArticleGallery = async (id: number) => {
    try {
      const res = await Axios.get<{ data: GalleryDetails }>(
        `${API_URL}/Gallery/GetByGalleryArticleId?galleryArticleId=${id}&format=700`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getArticlesByCategory = async (
    id: number,
    skip: number,
    pageSize: number
  ) => {
    try {
      const res = await Axios.get<{ data: ArticleItem[]; total: number }>(
        `${API_URL}/Article/GetListByCategoryId?categoryId=${id}&skip=${skip}&pageSize=${pageSize}&format=700`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getRelatedGalleries = async (id: number) => {
    try {
      const res = await Axios.get<{ data: RelatedGallery[] }>(
        `${API_URL}/Gallery/GetRelatedGalleries?articleId=${id}&format=700`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getShowBoxConfig = async (id: number) => {
    try {
      const res = await Axios.get<{ data: ShowBoxConfig[] }>(
        `${API_URL}/RadioShow/GetBoxes?categoryId=${id}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getPopularArticles = async () => {
    try {
      const res = await Axios.get<{ data: ArticleItem[] }>(
        `${API_URL}/PopularArticle/GetListBySectionId?sectionId=7&top=4&format=700`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getArchivedShows = async () => {
    try {
      const res = await Axios.get<{ data: ShowItem[] }>(
        `${API_URL}/RadioShow/GetArchivalListByStation?Station=Jedynka&top=4&format=700`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getArchivedEpisodesList = async (
    id: number,
    skip: number,
    pageSize: number
  ) => {
    try {
      const res = await Axios.get<{ data: ArchivedShowSound[]; total: number }>(
        `${API_URL}/RadioShow/GetArchivedEpisodesListByCategoryId?RadioShowCategoryId=${id}&Skip=${skip}&PageSize=${pageSize}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getArticlesByBoxInstanceId = async (
    boxInstanceId: number,
    pageSize: number,
    skip: number
  ) => {
    try {
      const res = await Axios.get<{ data: BoxInstanceId[] }>(
        `${API_URL}/Article/GetListByBoxInstanceId?boxInstanceId=${boxInstanceId}&pageSize=${pageSize}&skip=${skip}&getCustomFields=true`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getSpecialBroadcastSounds = async (
    id: number,
    skip: number,
    pageSize: number
  ) => {
    try {
      const res = await Axios.get<{ data: ShowSound[]; total: number }>(
        `${API_URL}/AudioArticle/GetListByCategoryId?categoryId=${id}&skip=${skip}&pageSize=${pageSize}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getSearchResults = async (
    query: string,
    pageSize: number,
    skip: number
  ) => {
    try {
      const res = await Axios.get<{
        data: ArticleItem[];
        total: number;
        recommendedCategories: Category[];
      }>(
        `${API_URL}/Article/Search?app=Jedynka&keyword=${query}&pageSize=${pageSize}&skip=${skip}&GetRecommendedCategories=true`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );

      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getTagsResults = async (
    tagId: number,
    pageSize: number,
    skip: number,
    tags: boolean = true
  ) => {
    try {
      const res = await Axios.get<{ data: ArticleItem[]; total: number }>(
        `${API_URL}/Article/GetListBySubjectTagId?subjectTagId=${tagId}&pageSize=${pageSize}&skip=${skip}&getSubjectTags=${tags}`,

        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );

      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getPlaylistByArticleId = async (id: number) => {
    try {
      const res = await Axios.get<{ data: ArticlePlaylistModel[] }>(
        `${API_URL}/Opt/GetPlaylist?articleId=${id}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );

      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getHotTopic = async () => {
    try {
      const res = await Axios.get<{ data: IHotTopic }>(
        `${API_URL}/HotTopic/Get?Station=Jedynka`,
        {}
      );

      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };

  static getGallery = async (id: number) => {
    try {
      const res = await Axios.get<{ data: Partial<ArticleItem> }>(
        `${API_URL}/Gallery/GetByGalleryArticleId?galleryArticleId=${id}`,
        {
          headers: {
            'x-api-key': API_KEY,
          },
        }
      );
      return res;
    } catch (e) {
      return errorHandler(e as AxiosError);
    }
  };
}
