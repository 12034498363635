import { Box, Button, Link, Typography } from 'components';
import { ShowItem } from 'models';
import useHover from 'utils/useHover';
import SVG from 'react-inlinesvg';
import { Col, Row } from 'react-grid-system';
import Image from 'next/image';

export const ShowBox: React.FC<{ data: ShowItem; mobile?: boolean }> = ({
  data,
  mobile,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const { name, info, photo, categoryId } = data;
  return (
    <Link href={`/audycje/${categoryId}`}>
      <Box ref={hoverRef}>
        <Row style={{ minHeight: mobile ? undefined : 165 }}>
          <Col xs={5} md={4}>
            <Box
              backgroundColor="#ddd"
              position="relative"
              aspectRatio="16 / 9"
            >
              <Image
                src={photo || '/images/shareCard.jpg'}
                alt={name}
                layout="fill"
                objectFit="cover"
              />
              <Box
                width="100%"
                height="100%"
                backgroundColor="#000"
                opacity={isHover ? 0.3 : 0}
                transition="0.3s"
              />
            </Box>
          </Col>
          <Col xs={7} lg={8}>
            <Box
              display="flex"
              height="100%"
              flexDirection="column"
              justifyContent={mobile ? 'space-between' : 'flex-start'}
            >
              <Typography
                variant={mobile ? 'h3' : 'h2'}
                as="h2"
                color={isHover ? 'main.primary' : 'neutral.dark'}
                transition="0.3s"
                fontSize={mobile ? 12 : 22}
              >
                {name}
              </Typography>

              {mobile ? (
                <Box width={120}>
                  <Button
                    color="main.primary"
                    decorator={<SVG src="/icons/arrow_right.svg" />}
                  >
                    więcej
                  </Button>
                </Box>
              ) : (
                <>
                  <Box
                    width="100%"
                    borderBottom="1px solid"
                    borderColor="neutral.gray"
                    my={2}
                  />
                  <Typography
                    variant="body"
                    color={isHover ? 'main.primary' : 'neutral.dark'}
                    transition="0.3s"
                  >
                    {info}
                  </Typography>
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Box>
    </Link>
  );
};
