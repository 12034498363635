/* eslint-disable no-nested-ternary */
/* eslint-disable no-nested-ternary */

import { Box, SectionTitle, Link, SinglePodcast, Button } from 'components';
import { Container, Row, Col } from 'react-grid-system';
import React from 'react';
import theme from 'theme';
import { Podcast } from 'models';
import { MobileContext } from 'utils/mobileContext';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled from 'styled-components';
import Image from 'next/image';
import SVG from 'react-inlinesvg';

const ScrollContainer = styled.div`
  width: 100%;
  display: flex;
  .simplebar-track.simplebar-horizontal {
    background: ${theme.palette.neutral.grayWithOpacity};
    height: 2px;
    right: 0;
    bottom: 0;
    overflow: visible;
  }
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 2px;
    right: 0;
    top: 0;
    background: ${theme.palette.main.primary};
  }
`;

export const Podcasts: React.FC<{ content: Podcast[] }> = ({ content }) => {
  const { isMobile } = React.useContext(MobileContext);

  if (!content) return null;

  return (
    <Box py={isMobile ? 40 : 80} position="relative">
      <Image
        src="/images/podcasts_bg.png"
        alt="Podcasty"
        layout="fill"
        objectFit={isMobile ? 'cover' : 'contain'}
        objectPosition="center"
      />
      <Container fluid md>
        <Box px={isMobile ? 0 : 4} position="relative">
          <Box
            position="absolute"
            top={isMobile ? '-40px' : '-50px'}
            left={isMobile ? 0 : theme.space[4]}
            px={4}
            py={2}
            backgroundColor="neutral.white"
            transform="translate(0, -50%)"
          >
            <Link
              href="https://podcasty.polskieradio.pl/"
              ariaLabel="Podcasty Polskiego Radia"
            >
              <SectionTitle fontSize={3}>podcasty</SectionTitle>
            </Link>
          </Box>
          <Row>
            <Col xs={isMobile ? 12 : 6}>
              {isMobile ? (
                <ScrollContainer>
                  <SimpleBar style={{ width: '100%' }}>
                    <Row
                      style={{
                        display: 'inline-flex',
                        flexWrap: 'nowrap',
                        marginLeft: 0,
                        marginRight: 0,
                        marginBottom: `${theme.space[3]}px`,
                      }}
                    >
                      {content?.map((podcast, index) => {
                        return (
                          <Box
                            height="152px"
                            width="128px"
                            mx={index % 2 ? 3 : 'initial'}
                            key={podcast.id}
                          >
                            <SinglePodcast
                              title={podcast.title}
                              href={`https://podcasty.polskieradio.pl/podcast/${podcast.id}`}
                              backgroundImage={podcast.image.thumbnail}
                            />
                          </Box>
                        );
                      })}
                    </Row>
                  </SimpleBar>
                </ScrollContainer>
              ) : (
                <Box px={4}>
                  <Row>
                    {content?.map((podcast) => {
                      return (
                        <Col xs={4} key={podcast.id}>
                          <SinglePodcast
                            title={podcast.title}
                            href={`https://podcasty.polskieradio.pl/podcast/${podcast.id}`}
                            backgroundImage={podcast.image.thumbnail}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Box>
              )}
            </Col>
          </Row>
        </Box>
        <Box
          width="120"
          position="absolute"
          bottom="-50px"
          left="50%"
          transform="translate(-50%, 50%)"
        >
          <Link
            href="https://podcasty.polskieradio.pl/"
            ariaLabel="Podcasty Polskiego Radia"
          >
            <Button
              color="main.primary"
              decorator={<SVG src="/icons/arrow_right.svg" />}
            >
              Więcej
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Podcasts;
