import styled, { css } from 'styled-components';

import {
  flexbox,
  FlexboxProps,
  color,
  ColorProps,
  LayoutProps,
  layout,
  border,
  BorderProps,
  system,
  BackgroundColorProps,
  borderColor,
  BorderColorProps,
} from 'styled-system';

export interface IButton
  extends ColorProps,
    BorderProps,
    BackgroundColorProps,
    BorderColorProps,
    LayoutProps,
    FlexboxProps {
  fill?: string;
  transition?: string;
}

export const StyledButton = styled.button<IButton>(
  flexbox,
  color,
  layout,
  border,
  borderColor,
  system({
    color: {
      property: 'color',
      scale: 'palette',
    },
    backgroundColor: {
      property: 'backgroundColor',
      scale: 'palette',
    },
    borderColor: {
      property: 'borderColor',
      scale: 'palette',
    },
    fluidFontSize: {
      property: 'fontSize',
      scale: 'fluidFontSizes',
    },
    transition: true,
  }),
  () => {
    return css`
      cursor: pointer;
      box-shadow: none;
      border-width: 1px;
      border-style: solid;
      display: flex;
      align-items: center;
      padding: 8px 20px;
      width: 100%;
      transition: 0.3s;
      span {
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        background: transparent;
        text-transform: uppercase;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      svg {
        margin-left: 10px;
      }

      svg,
      svg * {
        transition: 0.3s;
        ${system({
          fill: {
            property: 'fill',
            scale: 'palette',
          },
        })}
      }
    `;
  }
);
