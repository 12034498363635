import { Box, Audio, Typography } from 'components';
import React from 'react';
import { PlayerContext } from 'utils/PlayerContext';
import { MobileContext } from 'utils/mobileContext';
import Image from 'next/image';

export const NowPlayingBox: React.FC = () => {
  const { player } = React.useContext(PlayerContext);
  const { isDesktop } = React.useContext(MobileContext);

  return (
    <Box
      border="1px solid"
      borderColor="neutral.gray"
      borderTopLeftRadius="16px"
      borderTopRightRadius="16px"
      height={{ tablet: '100%', mobile: 170 }}
      position="relative"
      overflow="hidden"
      width={{ tablet: 'auto', mobile: 300 }}
    >
      <Box
        backgroundColor="main.secondary"
        position="absolute"
        zIndex={2}
        py={!isDesktop ? 1 : 2}
        px={!isDesktop ? 3 : 4}
        borderBottomRightRadius="16px"
      >
        <Typography
          color="white"
          variant={!isDesktop ? 'labelSmall' : 'labelBig'}
        >
          TERAZ NA ANTENIE
        </Typography>
      </Box>
      <Box
        px={3}
        pt={!isDesktop ? 4 : 5}
        pb={4}
        position="absolute"
        zIndex={2}
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Box
            borderBottom="2px solid"
            borderColor="main.primary"
            width="fit-content"
            mb={2}
          >
            <Typography
              variant={!isDesktop ? 'detailsSmall' : 'showDuration'}
              color="neutral.dark"
              style={{ mixBlendMode: 'difference' }}
            >
              {`${player?.stream?.startHour?.slice(
                0,
                5
              )} - ${player?.stream?.stopHour?.slice(0, 5)}`}
            </Typography>
          </Box>
          <Typography
            variant={!isDesktop ? 'header1mobile' : 'header1'}
            color="neutral.dark"
            style={{ mixBlendMode: 'difference' }}
            maxWidth={!isDesktop ? 200 : 'fit-content'}
            mb={!isDesktop ? 2 : ''}
          >
            {player?.stream?.title}
          </Typography>
        </Box>
        <Box mb={2} width={!isDesktop ? '130px' : '300px'}>
          <Audio
            stream={player?.stream}
            barColor="neutral.lightGray"
            color="main.secondary"
            progressBar
          />
        </Box>
        {player?.stream?.hosts && (
          <Box>
            {player?.stream?.hosts.map((host) => {
              return (
                <Typography
                  variant={!isDesktop ? 'date' : 'showDetailsTitleMobile'}
                  color="neutral.dark"
                >
                  {host.name} {host.surname}
                </Typography>
              );
            })}

            <Typography variant={!isDesktop ? 'detailsSmall' : 'showDuration'}>
              prowadzący
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        width="100%"
        height="90%"
        position="absolute"
        bottom={0}
        left={0}
        zIndex={1}
      >
        <Image
          src={player?.stream?.photo || '/images/zaslepka.png'}
          layout="fill"
          objectFit="contain"
          objectPosition={player?.stream?.photo ? 'bottom right' : '95%'}
          alt={player?.stream?.title || 'Audycje Jedynki'}
        />
        <Image
          src="/images/waves.png"
          layout="fill"
          objectFit="contain"
          objectPosition="bottom"
          alt="Fale"
        />
      </Box>
    </Box>
  );
};

export default NowPlayingBox;
