import React from 'react';
import { Box, Link, Typography } from 'components';
import { IHotTopic } from 'models';
import SVG from 'react-inlinesvg';
import { API } from 'API';
import { MobileContext } from 'utils/mobileContext';
import { useRouter } from 'next/router';
import { Container } from 'react-grid-system';
import Marquee from 'react-fast-marquee';
import useHover from 'utils/useHover';

interface Props {
  setHotTopic: (value: boolean) => void;
}

export const HotTopic: React.FC<Props> = ({ setHotTopic }) => {
  const { isMobile } = React.useContext(MobileContext);
  const { pathname } = useRouter();

  const [data, setData] = React.useState<IHotTopic | null>(null);
  const [hoverRef, isHover] = useHover<HTMLDivElement>();

  const fetchHotTopic = async () => {
    const response = await API.getHotTopic();
    if (!response) {
      setData(null);
    } else {
      const { data } = response;
      if (data?.data?.title.length && data?.data?.content.length) {
        setData(data?.data);
        setHotTopic(true);
      } else {
        setHotTopic(false);
      }
    }
  };

  React.useEffect(() => {
    fetchHotTopic();
  }, [pathname]);

  if (!data) return null;

  const defineBg = (color: IHotTopic['color']) => {
    if (color === 'bg_yellow') {
      return '#f8e71c';
    }
    return '#e6e6e6';
  };

  if (!data.title.length || !data.content.length) {
    return null;
  }

  return (
    <Link href={data?.link || '/'} width="100%">
      <Box
        position="fixed"
        width="100%"
        left="0"
        bottom="0"
        backgroundColor={defineBg(data.color)}
        zIndex={100}
        display="flex"
        alignItems="center"
        height="30px"
      >
        <Container>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            maxWidth="1280px"
          >
            {!isMobile && (
              <Box width="12%">
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  height="30px"
                  justifyContent="center"
                  backgroundColor="red"
                  backgroundImage="url(/icons/hotTopicLinkBackground.png)"
                  backgroundSize="cover"
                  backgroundPosition="right, bottom right"
                  backgroundRepeat="no-repeat"
                  paddingX="20px"
                >
                  <Typography
                    variant="button"
                    marginRight="10px"
                    color="neutral.white"
                  >
                    więcej
                  </Typography>

                  <SVG src="/icons/arrow_right.svg" />
                </Box>
              </Box>
            )}
            <Box
              display="flex"
              px={isMobile ? undefined : '20px'}
              ref={hoverRef}
            >
              <Typography
                marginLeft="20px"
                variant="body2"
                as="p"
                display="contents"
              >
                <Marquee
                  key={String(isHover)}
                  gradient={false}
                  style={{ maxWidth: isMobile ? '100%' : '1100px' }}
                >
                  <Typography
                    as="span"
                    fontWeight="700"
                    fontSize={isMobile ? '12px' : 0}
                    whiteSpace="nowrap"
                  >
                    {data?.title}
                  </Typography>
                  <Typography
                    as="span"
                    marginLeft="20px"
                    fontSize={isMobile ? '12px' : 0}
                    whiteSpace="nowrap"
                  >
                    {data?.content}
                  </Typography>
                </Marquee>
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Link>
  );
};
