import { Box, Typography, Link } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import useHover from 'utils/useHover';
import Image from 'next/image';

interface IProps {
  record: ArticleItem;
}

export const RecordBox: React.FC<IProps> = ({ record }) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();

  return (
    <Link
      href={`/artykul/${record?.id},${record?.title
        .replace(/[.,:?!/"]/g, '')
        .split(' ')
        .join('-')}`}
    >
      <Box ref={hoverRef} transition="0.3s">
        <Box height={140} width={140} position="relative" overflow="hidden">
          <Image
            src={record?.photo || '/images/shareCard.jpeg'}
            alt={record?.title}
            layout="fill"
            objectFit="cover"
          />
          <Box
            height={140}
            width={140}
            position="absolute"
            overflow="hidden"
            backgroundColor={isHover ? 'rgba(0, 0, 0, 0.4)' : 'tranparent'}
            transition="0.3s"
          />
        </Box>
        <Box mt={2} display="flex" justifyContent="center" width={140}>
          <Typography
            variant="lead"
            color={isHover ? 'main.primary' : 'neutral.dark'}
            display="inline"
            transition="0.3s"
            textAlign="center"
          >
            {record.title}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default RecordBox;
