import styled from 'styled-components';
import theme from 'theme';

export interface IStyledTextarea {
  height?: string;
  width?: string;
  resize?: string;
  border?: string;
}

export const StyledTextarea = styled.textarea<IStyledTextarea>`
  height: ${({ height, theme }) => height || `${theme.space[7] + 10}px`};
  width: ${({ width }) => width || '100%'};
  background: ${({ theme }) => theme.palette.neutral.light};
  resize: ${({ resize }) => resize || 'none'};
  border: none;

  border-bottom: ${({ border }) =>
    border ? `2px solid ${theme.palette.main.secondary}` : 'none'};
  ${({ theme }) => theme.textStyles.lead3}
  padding: ${({ theme }) => `${theme.space[3]}px`};
  position: relative;
  ::placeholder {
    color: ${({ theme }) => theme.palette.neutral.placeholder};
    font-size: 18px;
    font-family: 'Roboto'
    line-height: 28px;
  }
  &::-webkit-resizer {
    background: ${({ border }) =>
      border ? 'none' : 'url(/icons/textarearesize.svg)'};
    background-size: 100%;
  }
`;
