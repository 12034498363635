import React, { useReducer, useState } from 'react';
import { Track, StreamSound } from 'models';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from '@microsoft/signalr';
import browserStorage from 'store';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type PlayerState = {
  isPlaying: boolean;
  isVisible: boolean;
  isStream: boolean;
  wasStreamPlaying: boolean;
  duration: number | null;
  track: Track | null;
  stream: StreamSound;
  playlist: Array<Track>;
};

type PlayerAction = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
};

const defaultPlayerState: PlayerState = {
  track: null,
  stream: {
    id: 0,
    url: 'https://stream11.polskieradio.pl/pr1/pr1.sdp/playlist.m3u8',
    title: '',
    photo: '',
    hosts: [],
    startHour: '',
    stopHour: '',
    subRadioShows: [],
  },
  playlist: [],
  isStream: false,
  isPlaying: false,
  wasStreamPlaying: false,
  isVisible: !!browserStorage.get('isVisible'),
  duration: 0,
};

export const PlayerContext = React.createContext<{
  player: PlayerState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPlayerContext: React.Dispatch<any>;
}>({
  player: defaultPlayerState,
  setPlayerContext: () => null,
});

type Props = {
  children: React.ReactNode;
};

const PlayerContextWrapper: React.FC<Props> = ({ children }): JSX.Element => {
  const [hubConnection, setHubConnection] = React.useState<HubConnection>();
  const [player, setPlayerContext] = useReducer(
    (state: PlayerState, action: PlayerAction) => {
      if (action.type === 'SET_PLAYER') {
        return { ...state, ...action.payload };
      }
      return state;
    },
    defaultPlayerState
  );

  React.useEffect(() => {
    if (player.isPlaying && typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'PlayClick',
        trackTitle: player.track ? player.track.title : player.stream.title,
      });
    }
  }, [player.isPlaying]);

  React.useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl('https://hubs.polskieradio.pl/broadcast/onair', {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Critical)
      .withAutomaticReconnect()
      .build();

    setHubConnection(connection);
  }, []);

  React.useEffect(() => {
    if (hubConnection) {
      hubConnection.on('onAir', (response) => {
        setPlayerContext({
          type: 'SET_PLAYER',
          payload: {
            stream: {
              id: response.data[0]?.id,
              url: 'https://stream11.polskieradio.pl/pr1/pr1.sdp/playresponse.dat.m3u8',
              title: response.data[0]?.name,
              photo: response.data[0]?.photo,
              hosts: response.data[0]?.hosts,
              startHour: response.data[0]?.startHour,
              stopHour: response.data[0]?.stopHour,
            },
          },
        });
      });
      if (hubConnection.state === 'Disconnected') {
        try {
          hubConnection.start().then(() => {
            hubConnection.invoke('Connect', 'jedynka');
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
    return () => {
      hubConnection?.stop();
      hubConnection?.off('onAir');
    };
  }, [hubConnection]);

  return (
    <PlayerContext.Provider value={{ player, setPlayerContext }}>
      {children}
    </PlayerContext.Provider>
  );
};

export default PlayerContextWrapper;
