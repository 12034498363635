/* eslint-disable no-nested-ternary */
import { Box, Typography } from 'components';
import React from 'react';
import { useRouter } from 'next/router';
import useHover from 'utils/useHover';
import isLast from 'utils/isLast';
import qs from 'qs';
import { MobileContext } from 'utils/mobileContext';
import 'simplebar/dist/simplebar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { v4 as uuid } from 'uuid';
import SVG from 'react-inlinesvg';
import { useWindowWidth } from '@react-hook/window-size';
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components';

const SwiperOverrides = styled.div`
  width: 100%;
  height: 100%;
  .swiper-initialized {
    margin-left: ${({ theme }) => `${theme.space[4]}px`};
    margin-right: ${({ theme }) => `${theme.space[4]}px`};
  }
`;

const ArrowLeft = styled.button`
  position: absolute;
  left: 0;
  top: -2px;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  svg {
    cursor: pointer;
    transform: rotate(180deg);
    width: 16px;
    height: 24px;
    fill: ${({ theme }) => theme.palette.main.primary} !important;
    .st0 {
      fill: ${({ theme }) => theme.palette.main.primary} !important;
    }
`;

const ArrowRight = styled.button`
  position: absolute;
  right: 0;
  top: -2px;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  svg {
    cursor: pointer;
    width: 16px;
    height: 24px;
    fill: ${({ theme }) => theme.palette.main.primary} !important;
    .st0 {
      fill: ${({ theme }) => theme.palette.main.primary} !important;
    }
`;

const LetterList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: no-wrap;
  padding-bottom: 16px;
`;

interface ILetter {
  cursor: string;
  isDisabled: boolean;
}

const Letter = styled.li<ILetter>`
  cursor: ${({ cursor }) => cursor};
  user-select: none;
  opacity: ${({ isDisabled }) => isDisabled && '0.4'};
  button {
    border: none;
    background: none;
    padding: 0;
    font-family: inherit;
    cursor: ${({ cursor }) => cursor};
  }
`;

interface IProps {
  letters: string[];
  letter: string;
  setLetter: (x: string | undefined) => void;
  selectedLetter: string | string[];
  activeLetter: boolean;
}

interface IFilter {
  activeLetters: Array<string> | null;
}

export const letters = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'r',
  's',
  't',
  'u',
  'w',
  'y',
  'z',
];

const RenderLetter: React.FC<IProps> = ({
  letters,
  letter,
  setLetter,
  selectedLetter,
  activeLetter,
}) => {
  const [hoverRef, isHover] = useHover<HTMLLIElement>();
  const { isMobile } = React.useContext(MobileContext);

  const handleClick = React.useCallback(
    (activeLetter, letter, selectedLetter) => {
      if (activeLetter) {
        if (letter === selectedLetter) {
          setLetter(undefined);
        } else {
          setLetter(letter);
        }
      }
    },
    [activeLetter, letter, selectedLetter, setLetter]
  );

  return (
    <Letter
      cursor={activeLetter ? 'pointer' : 'auto'}
      isDisabled={!activeLetter}
      onClick={() => handleClick(activeLetter, letter, selectedLetter)}
      ref={hoverRef}
    >
      <Typography
        as="button"
        variant="h3"
        mr={isLast(letters.indexOf(letter), letters.length) ? 0 : 3}
        color={
          letter === selectedLetter
            ? 'main.primary'
            : !activeLetter || isMobile
            ? 'neutral.gray'
            : isHover
            ? 'main.primary'
            : 'neutral.gray'
        }
      >
        {letter.toUpperCase()}
      </Typography>
    </Letter>
  );
};

export const LetterFilter: React.FC<IFilter> = ({
  activeLetters,
  children,
}) => {
  const router = useRouter();
  const windowWidth = useWindowWidth();
  const { isMobile } = React.useContext(MobileContext);
  const selectedLetter = router.query.filter ? router.query.filter : '';
  const setLetter = (letter: string | undefined) => {
    if (!letter) return router.push(`${router.pathname}`);
    router.push(
      `${router.pathname}?${qs.stringify({ ...router.query, filter: letter })}`
    );
  };

  return (
    <Box>
      <Box position="relative">
        <Box
          as="button"
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="none"
          backgroundColor="transparent"
          cursor="pointer"
          position="absolute"
          top="-55px"
          right="-5px"
          onClick={() => setLetter(undefined)}
        >
          <SVG src="/icons/close-letter-filter.svg" />
        </Box>
        {isMobile ? (
          <Box width="100%" position="relative">
            <ArrowLeft className="letterfilter_swiper_prev">
              <SVG src="/icons/arrow_right.svg" />
            </ArrowLeft>
            <ArrowRight className="letterfilter_swiper_next">
              <SVG src="/icons/arrow_right.svg" />
            </ArrowRight>
            <SwiperOverrides>
              <Swiper
                direction="horizontal"
                slidesPerView={windowWidth > 540 ? 14 : windowWidth / 50}
                spaceBetween={20}
                slidesPerGroup={5}
                style={{ maxHeight: '100%' }}
                modules={[Navigation]}
                navigation={{
                  prevEl: '.letterfilter_swiper_prev',
                  nextEl: '.letterfilter_swiper_next',
                }}
              >
                {letters.map((letter: string) => (
                  <SwiperSlide key={uuid()}>
                    <RenderLetter
                      key={letter}
                      letters={letters}
                      letter={letter}
                      setLetter={setLetter}
                      selectedLetter={selectedLetter}
                      activeLetter={!!activeLetters?.includes(letter)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperOverrides>
          </Box>
        ) : (
          <LetterList>
            {letters.map((letter: string) => (
              <RenderLetter
                key={letter}
                letters={letters}
                letter={letter}
                setLetter={setLetter}
                selectedLetter={selectedLetter}
                activeLetter={!!activeLetters?.includes(letter)}
              />
            ))}
          </LetterList>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default LetterFilter;
