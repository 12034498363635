import React from 'react';
import { Box } from 'components';
import { MobileContext } from 'utils/mobileContext';

interface IProps {
  color?: string;
  barColor?: string;
  progress: number;
}

export const ProgressBar: React.FC<IProps> = ({
  color = 'main.secondary',
  barColor = 'neutral.lightGray',
  progress,
}) => {
  const { isTablet } = React.useContext(MobileContext);
  return (
    <Box
      height="5px"
      width="100%"
      borderRadius="8px"
      backgroundColor={barColor}
      marginLeft={isTablet ? 2 : 3}
    >
      <Box
        height="100%"
        style={{ width: `${progress * 100}%` }}
        borderRadius="8px"
        backgroundColor={color}
      />
    </Box>
  );
};
