/* eslint-disable no-nested-ternary */
import { Box, Link, Typography, Button, SectionTitle } from 'components';
import { ArticleItem } from 'models';
import useHover from 'utils/useHover';
import React from 'react';
import SVG from 'react-inlinesvg';
import Image from 'next/image';
import { MobileContext } from 'utils/mobileContext';

export const BannerSpecialService: React.FC<{ content: ArticleItem }> = ({
  content,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const bannerProps = Object.fromEntries(
    content?.customFields?.map(Object.values) || []
  );
  const { isDesktop, isTablet, isMobile } = React.useContext(MobileContext);

  return (
    <Box ref={hoverRef} width="100%" height="100%" position="relative">
      {isMobile && (
        <Box
          position="absolute"
          bottom="0"
          left="50%"
          transform="translate(-50%,50%)"
          zIndex={1}
        >
          <Link href={bannerProps?.link}>
            <Button
              color="main.primary"
              decorator={<SVG src="/icons/arrow_right.svg" />}
            >
              więcej
            </Button>
          </Link>
        </Box>
      )}
      {content?.attachments?.[0]?.fileName && (
        <Image
          src={content?.attachments?.[0]?.file}
          width="100%"
          height={isDesktop ? '22%' : isTablet ? '60%' : '100%'}
          layout="responsive"
          objectFit="cover"
          alt="alt"
          objectPosition="top center"
        />
      )}
      <Box
        height="100%"
        width="100%"
        position="absolute"
        top="0"
        left="0"
        backgroundImage="linear-gradient(to left, rgba(0,0,0,0.2), rgba(0,0,0,0.4))"
      />
      <Box
        height="100%"
        width="100%"
        position="absolute"
        top="0"
        left="0"
        backgroundImage="linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.7))"
        opacity={isHover ? 1 : 0}
        transition="0.3s"
      />
      <Box
        position="absolute"
        top="-40px"
        width="100%"
        borderBottom="1px solid"
        borderColor="neutral.grayWithOpacity"
      >
        <SectionTitle borderColor="main.primary" borderWidth={5} fontSize={3}>
          {bannerProps?.header}
        </SectionTitle>
      </Box>
      <Box
        position="absolute"
        top="50%"
        left={4}
        transform="translateY(-50%)"
        width={!isDesktop ? '80%' : '45%'}
        display="flex"
        flexDirection="column"
        alignItems="baseline"
      >
        <Typography
          variant={!isMobile ? 'header1' : 'header1mobile'}
          color="neutral.white"
          mb={isMobile ? 2 : 4}
        >
          {bannerProps?.tytuł}
        </Typography>
        <Typography
          variant={!isMobile ? 'lead2' : 'onAir'}
          color="neutral.white"
          mb={isMobile ? 2 : 4}
        >
          {content?.lead}
        </Typography>
        {!isMobile && (
          <Box>
            <Link href={bannerProps?.link}>
              <Button
                color="main.primary"
                decorator={<SVG src="/icons/arrow_right.svg" />}
              >
                więcej
              </Button>
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BannerSpecialService;
