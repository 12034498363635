import React from 'react';
import { Link, Box, Typography } from 'components';

export interface IProps {
  id: number;
  name: string;
}

const SPECIAL_TAGS_REDIRECT = [
  {
    name: 'Katarzyna Jankowska – PODCAST',
    url: 'https://podcasty.polskieradio.pl/podcast/254',
  },
];

export const ArticleTag: React.FC<IProps> = ({ id, name }) => {
  const parseName = (name: string) =>
    name
      .replace(/[.,:?!/"]/g, '')
      .split(' ')
      .join('-');

  const specialTag = SPECIAL_TAGS_REDIRECT.find((tag) => tag.name === name);

  const getHref = () => {
    if (specialTag) {
      return specialTag.url;
    }
    return `/tagi/${id},${parseName(name)}`;
  };

  return (
    <Link href={getHref()} prefetch={false} style={{ textDecoration: 'none' }}>
      <Box
        backgroundColor="neutral.lightGray"
        py={2}
        px={3}
        height={32}
        display="flex"
        minWidth="fit-content"
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        overflow="hidden"
        color="black"
      >
        <Typography variant="caption2" height={16} whiteSpace="nowrap">
          {name}
        </Typography>
      </Box>
    </Link>
  );
};
