import { Banner, Box, Typography } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { MobileContext } from 'utils/mobileContext';

export const BannerSection: React.FC<{
  content: ArticleItem;
  background?: string;
  paddingBottom?: boolean;
}> = ({ content, background = 'neutral.white', paddingBottom = false }) => {
  const bannerProps = Object.fromEntries(
    content?.customFields?.map(Object.values) || []
  );
  const { isMobile } = React.useContext(MobileContext);
  return (
    <section>
      <Box backgroundColor={background} pt={4} pb={paddingBottom ? 4 : 0}>
        <Container fluid md>
          <Row gutterWidth={0}>
            <Col xs={12}>
              {bannerProps.ad && (
                <Typography opacity="0.5" fontSize="10px" marginBottom="5px">
                  REKLAMA
                </Typography>
              )}
              {(bannerProps.desktop && bannerProps.desktop2) ||
              (bannerProps.mobile && bannerProps.mobile2) ? (
                <Swiper modules={[Autoplay]} loop autoplay={{ delay: 5000 }}>
                  <SwiperSlide>
                    <Banner
                      link={bannerProps?.link || ''}
                      src={
                        (isMobile
                          ? bannerProps?.mobile
                          : bannerProps?.desktop) || ''
                      }
                      alt={bannerProps?.alt}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Banner
                      link={bannerProps?.link2 || ''}
                      src={
                        (isMobile
                          ? bannerProps?.mobile2
                          : bannerProps?.desktop2) || ''
                      }
                      alt={bannerProps?.alt2}
                    />
                  </SwiperSlide>
                </Swiper>
              ) : (
                (bannerProps.desktop || bannerProps.mobile) && (
                  <Banner
                    link={bannerProps?.link || ''}
                    src={
                      (isMobile ? bannerProps?.mobile : bannerProps?.desktop) ||
                      ''
                    }
                    alt={bannerProps?.alt}
                  />
                )
              )}
            </Col>
          </Row>
        </Container>
      </Box>
    </section>
  );
};

export default BannerSection;
