import { Box } from 'components';
import React from 'react';

interface IProps {
  color: string;
}

export const CirclesBox: React.FC<IProps> = ({ color }) => {
  return (
    <Box
      position="relative"
      overflow="hidden"
      backgroundColor="transparent"
      width={111}
      height={111}
    >
      <Box
        borderColor={color}
        backgroundColor="transparent"
        border="10px solid"
        opacity={0.5}
        width={43}
        height={43}
        borderRadius="50%"
        position="absolute"
        top={0}
        right={0}
        transform="translate(50%,-50%)"
      />
      <Box
        borderColor={color}
        border="10px solid"
        opacity={0.5}
        width={77}
        height={77}
        borderRadius="50%"
        position="absolute"
        top={0}
        right={0}
        transform="translate(50%,-50%)"
      />{' '}
      <Box
        borderColor={color}
        border="10px solid"
        opacity={0.5}
        width={111}
        height={111}
        borderRadius="50%"
        position="absolute"
        top={0}
        right={0}
        transform="translate(50%,-50%)"
      />
    </Box>
  );
};
