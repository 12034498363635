import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import useHover from 'utils/useHover';
import { Link } from 'components';

export interface IProps {
  imgOff: string;
  imgOn?: string;
  height?: string | number;
  width?: string | number;
  href: string;
}

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const Container = styled.div`
  position: relative;
`;

export interface ILargeSocial {
  height?: string | number;
  width?: string | number;
  src: string;
}

const StyledImage = styled.img<ILargeSocial>`
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width || '100%'};
  content: ${({ src }) => `url(${src})`};
  object-fit: contain;
  cursor: pointer;
`;

export const LargeSocial: React.FC<IProps> = ({
  imgOff,
  imgOn,
  height,
  width,
  href,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  return (
    <Link href={href}>
      <Container ref={hoverRef}>
        <AnimatePresence>
          {isHover && imgOn && (
            <motion.div
              key="on"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={variants}
              style={{ position: 'absolute', width: '100%' }}
            >
              <StyledImage src={imgOn} height={height} width={width} />
            </motion.div>
          )}

          {(!isHover || !imgOn) && (
            <motion.div
              key="off"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={variants}
              style={{ position: 'absolute', width: '100%' }}
            >
              <StyledImage src={imgOff} height={height} width={width} />
            </motion.div>
          )}
        </AnimatePresence>
      </Container>
    </Link>
  );
};
