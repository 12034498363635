import { Box, Typography } from 'components';
import React from 'react';
import { Col, Row } from 'react-grid-system';
// import SVG from 'react-inlinesvg';
// import { PlayerContext } from 'utils/PlayerContext';

interface IProps {
  // audio: string;
  artist: string;
  title: string;
  // cover: string;
  // startTime: string;
}

export const PlaylistBoxItem: React.FC<IProps> = ({
  // audio,
  artist,
  title,
  // cover,
  // startTime,
}) => {
  // const playerContext = React.useContext(PlayerContext);
  // const onPlayClick = () => {
  //   playerContext.setPlayerContext({
  //     type: 'SET_PLAYER',
  //     payload: {
  //       track: {
  //         title,
  //         url: audio,
  //         artist,
  //         cover: cover ?? '/images/cover.png',
  //         airTime: `${new Date(startTime).toLocaleString('pl-pl', {
  //           day: 'numeric',
  //           month: 'long',
  //           year: 'numeric',
  //           hour: 'numeric',
  //           minute: 'numeric',
  //         })}`,
  //       },
  //       isPlaying: true,
  //       isVisible: true,
  //     },
  //   });
  // };

  return (
    <Box backgroundColor="neutral.light" p={2} mx="6px">
      <Row gutterWidth={20}>
        <Col xs={1}>
          {/* <Box
            as="button"
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            border="none"
            backgroundColor="transparent"
            cursor={audio ? 'pointer' : 'auto'}
            onClick={() => audio && onPlayClick()}
            style={{ filter: !audio ? 'grayscale(1)' : undefined }}
          >
            <SVG src="/icons/playlist_play.svg" />
          </Box> */}
        </Col>
        <Col xs={10}>
          <Box display="flex" flexDirection="column" height="100%">
            <Typography
              color="neutral.dark"
              opacity="0.8"
              variant="playerArtist"
              fontFamily="Roboto"
            >
              {artist}
            </Typography>
            <Typography
              color="neutral.dark"
              opacity="0.8"
              variant="listCaption"
              fontFamily="Roboto"
            >
              {title}
            </Typography>
          </Box>
        </Col>
      </Row>
    </Box>
  );
};
