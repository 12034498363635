import { Slider, Box, WasRead, Link, Button } from 'components';
import React from 'react';
import { BoxInstanceId } from 'models';
import { MobileContext } from 'utils/mobileContext';
import SVG from 'react-inlinesvg';

export const WasReadSlider: React.FC<{
  readBooks: BoxInstanceId[];
  header: string;
  currentBook?: boolean;
}> = ({ readBooks, header, currentBook = false }) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <Box
      height="100%"
      borderColor="main.primary"
      borderStyle="solid"
      borderWidth="2px 2px 7px 2px"
      pt={3}
      pb={isMobile ? 3 : 0}
      position="relative"
    >
      <Slider
        header={header}
        decorator
        decoratorTop={isMobile ? '17%' : '12%'}
        decoratorHeight={isMobile ? 13 : 14}
        slides={1}
        headline
        navigation={!isMobile}
        pagination
        paddingLeft={2}
        paddingRight={2}
        books
        data={readBooks}
        render={(book) => <WasRead book={book} currentBook={currentBook} />}
      />
      {!currentBook && (
        <Box
          width={120}
          position="absolute"
          bottom={0}
          left="50%"
          transform="translate(-50%, 50%)"
        >
          <Link href="/artykuly/9725">
            <Button
              color="main.primary"
              decorator={<SVG src="/icons/arrow_right.svg" />}
            >
              Więcej
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default WasReadSlider;
