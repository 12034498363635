import { Box, Typography, Link, ArticleTag } from 'components';
import Image from 'next/image';
import React from 'react';
import { ArticleItem } from 'models';
import { MobileContext } from 'utils/mobileContext';

interface IProps {
  article: ArticleWithTags;
  isTags?: boolean;
  index: number;
  total: number;
  title: string;
}

interface ArticleWithTags extends ArticleItem {
  subjectTags?: Array<{ name: string; id: number }>;
  url?: string;
}

export const SearchBox: React.FC<IProps> = ({
  article,
  index,
  total,
  isTags,
}) => {
  const parseDate = (date: string) => {
    return `${date.slice(8, 10)}${date.slice(4, 8)}${date.slice(
      0,
      4
    )} ${date.slice(11, 16)}`;
  };
  const { isMobile } = React.useContext(MobileContext);
  // const getHighlightedText = (text: string, highlight: string) => {
  //   const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
  //   return (
  //     <>
  //       {parts?.map((part) =>
  //         part.toLowerCase() === highlight.toLowerCase() ? (
  //           <Typography backgroundColor="main.primary" as="span">
  //             {part}
  //           </Typography>
  //         ) : (
  //           part
  //         )
  //       )}
  //     </>
  //   );
  // };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      minHeight="290px"
      width="100%"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="290px"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box width={isMobile ? '100%' : '25%'}>
          <Image
            src={article?.photo || '/images/shareCard.jpeg'}
            width="365px"
            height="199px"
            objectFit="cover"
            objectPosition="top center"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width={isMobile ? '100%' : '75%'}
          px={isMobile ? 0 : 4}
        >
          <Link
            href={
              !article?.url?.includes('polskieradio.pl/7/') && article?.url
                ? article.url
                : `/artykul/${article?.id},${article?.title
                    .replace(/[.,:?!/"]/g, '')
                    .split(' ')
                    .join('-')}`
            }
          >
            {!isTags && (
              <Typography marginBottom={2} variant="date" color="main.primary">
                {parseDate(article.datePublic)}
              </Typography>
            )}

            <Typography marginBottom={3} variant="h3">
              <span
                style={{
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  display: '-webkit-inline-box',
                }}
              >
                {article.title}
              </span>
            </Typography>
            <Typography variant="lead2" height="100px">
              <span
                style={{
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  display: '-webkit-inline-box',
                }}
              >
                {/* {getHighlightedText(article.lead, title)} */}
                {article.lead}
              </span>
            </Typography>
            {!isTags && (
              <Typography
                variant="caption"
                color="main.primary"
                marginBottom={isMobile ? '20px' : 0}
              >
                {article.categoryName}
              </Typography>
            )}
          </Link>
          {isTags && (
            <Box display="flex" width="100%" flexWrap="wrap">
              {article?.subjectTags?.map(
                (tag: { id: number; name: string }) => {
                  return (
                    <Box marginRight={2} marginBottom={2}>
                      <ArticleTag id={tag.id} name={tag.name} />
                    </Box>
                  );
                }
              )}
            </Box>
          )}
        </Box>
      </Box>
      {!(index === 15 || total % 16 === index + 1) ? (
        <Box width="100%" height="2px" backgroundColor="neutral.gray" />
      ) : null}
    </Box>
  );
};

export default SearchBox;
