import { StyledButton, Box } from 'components';
import React from 'react';
import useHover from 'utils/useHover';

interface IProps {
  color: string;
  baseColor?: string;
  decorator?: React.ReactNode | null;
  isCircle?: boolean;
  onClick?: () => void;
}

export const ButtonRounded: React.FC<IProps> = ({
  children,
  baseColor = 'white',
  color,
  decorator = null,
  isCircle = false,
  onClick,
}) => {
  const [hoverRef, isHover] = useHover<HTMLButtonElement>();
  const determineFill = () => {
    if (isCircle) {
      return isHover ? baseColor : color;
    }
    return isHover ? color : baseColor;
  };

  return (
    <StyledButton
      ref={hoverRef}
      color={isHover ? color : baseColor}
      borderColor={isHover ? color : 'transparent'}
      backgroundColor={isHover ? baseColor : color}
      borderRadius={22}
      justifyContent={decorator ? 'space-between' : 'center'}
      height={40}
      tabIndex={0}
      fill={determineFill()}
      onClick={onClick}
    >
      <span>{children}</span>

      {isCircle ? (
        <Box
          borderRadius="50%"
          backgroundColor={isHover ? color : baseColor}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="20px"
          height="20px"
          position="relative"
        >
          <Box position="absolute" top="4px" left="6px">
            {decorator}
          </Box>
        </Box>
      ) : (
        <Box>{decorator}</Box>
      )}
    </StyledButton>
  );
};
