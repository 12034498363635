import {
  Box,
  SectionTitle,
  ArticleBox,
  ArticleMobileBox,
  Dropdown,
  RecordOfTheWeek,
  RecommendedRecords,
  Link,
  Button,
} from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import theme from 'theme';
import { IMusicMosaicSection } from 'components/organisms/MusicMosaicSection/MusicMosaicSection';
import { MobileContext } from 'utils/mobileContext';
import SVG from 'react-inlinesvg';

interface IOption {
  name: string;
  content: ArticleItem[];
  id?: number;
  isRadioShow?: boolean;
}

export const MusicMosaic: React.FC<IMusicMosaicSection> = ({
  tabs,
  recordOfTheWeek,
  recommendedRecords,
}) => {
  const [activeOption, setActiveOption] = React.useState<IOption>(
    tabs[tabs.length - 1]
  );
  const articles = activeOption.content;
  const { isMobile, isTablet, isDesktop } = React.useContext(MobileContext);
  const isSmallDesktop = !isMobile && !isTablet && !isDesktop;

  if (!articles) return null;

  return (
    <Box
      p={3}
      backgroundColor="neutral.white"
      border="1px solid"
      borderColor="neutral.mediumGray"
      height="100%"
    >
      <Box
        mx="15px"
        borderBottom="1px solid"
        borderColor="neutral.mediumGray"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        position="relative"
      >
        <SectionTitle
          fontSize={isMobile ? 3 : 4}
          borderWidth={5}
          borderColor="main.primary"
        >
          muzyka
        </SectionTitle>
        <Dropdown
          options={tabs}
          activeOption={activeOption}
          setActiveOption={setActiveOption}
        />
      </Box>
      <Box pt={20} pb={4}>
        {isMobile ? (
          <Container fluid md>
            <Row>
              <Col xs={12}>
                <Box>
                  <ArticleBox article={articles[0]} isMosaic />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={articles[1]} />
                </Box>
              </Col>
            </Row>
            <Row style={{ position: 'relative' }}>
              <Col xs={12}>
                <Box
                  pb={3}
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={articles[2]} />
                </Box>
              </Col>
              <Box
                width={120}
                position="absolute"
                bottom={0}
                left="50%"
                transform="translate(-50%, 50%)"
              >
                <Link
                  href={
                    activeOption.isRadioShow
                      ? `/audycje/${activeOption.id}`
                      : `/artykuly/${activeOption.id}`
                  }
                >
                  <Button
                    color="main.primary"
                    decorator={<SVG src="/icons/arrow_right.svg" />}
                  >
                    Więcej
                  </Button>
                </Link>
              </Box>
            </Row>
            <Row style={{ marginTop: 50 }}>
              <Col xs={12}>
                <RecordOfTheWeek recordOfTheWeek={recordOfTheWeek} />
              </Col>
            </Row>
            <Row style={{ marginTop: theme.space[4] }}>
              <Col xs={12}>
                <RecommendedRecords content={recommendedRecords} />
              </Col>
            </Row>
          </Container>
        ) : (
          <>
            <Container fluid md>
              <Row style={{ position: 'relative' }}>
                <Col xs={4}>
                  <Box mb={5}>
                    <ArticleBox article={articles[0]} isMosaic />
                  </Box>
                </Col>
                <Col xs={4}>
                  <Box mb={5}>
                    <ArticleBox article={articles[1]} isMosaic />
                  </Box>
                </Col>
                <Col xs={4}>
                  <Box mb={5}>
                    <ArticleBox article={articles[2]} isMosaic />
                  </Box>
                </Col>
                <Box
                  width={120}
                  position="absolute"
                  bottom={0}
                  left="50%"
                  transform="translate(-50%, 50%)"
                >
                  <Link
                    href={
                      activeOption.isRadioShow
                        ? `/audycje/${activeOption.id}`
                        : `/artykuly/${activeOption.id}`
                    }
                  >
                    <Button
                      color="main.primary"
                      decorator={<SVG src="/icons/arrow_right.svg" />}
                    >
                      Więcej
                    </Button>
                  </Link>
                </Box>
              </Row>
            </Container>
            {isTablet || isSmallDesktop ? (
              <Container fluid md>
                <Row style={{ marginTop: 50 }}>
                  <Col xs={12}>
                    <RecordOfTheWeek recordOfTheWeek={recordOfTheWeek} />
                  </Col>
                </Row>
                <Row style={{ marginTop: theme.space[4] }}>
                  <Col xs={12}>
                    <RecommendedRecords content={recommendedRecords} />
                  </Col>
                </Row>
              </Container>
            ) : (
              <Container fluid md>
                <Row style={{ marginTop: 50 }}>
                  <Col xs={6} style={{ paddingRight: theme.space[2] }}>
                    <RecordOfTheWeek recordOfTheWeek={recordOfTheWeek} />
                  </Col>
                  <Col xs={6} style={{ paddingLeft: theme.space[2] }}>
                    <RecommendedRecords content={recommendedRecords} />
                  </Col>
                </Row>
              </Container>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default MusicMosaic;
