import { Banner, Box, Link, Typography } from 'components';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { MobileContext } from 'utils/mobileContext';
import { useTimer } from 'reactjs-countdown-hook';
import styled from 'styled-components';

const SwiperSectionWrapper = styled(Box)`
  & .swiper-wrapper {
    align-items: center;
  }
  @media screen and (min-width: 768px) {
    & .swiper-wrapper {
      align-items: stretch;
    }
  }
`;

const targetDate = new Date('March 14, 2022 09:29:59').getTime();

const TimeBox: React.FC<{ value: number; label: string; isMobile?: boolean }> =
  ({ value, label, isMobile }) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="clamp(55px, 6.5vw, 96px)"
        // width={isMobile ? 55 : 96}
        mx={isMobile ? 1 : 3}
      >
        <Box
          height="66%"
          width="100%"
          backgroundColor="neutral.dark"
          borderTopLeftRadius={20}
          borderTopRightRadius={20}
        >
          <Typography
            as="p"
            fontSize="clamp(28px, 4.5vw, 56px)"
            // fontSize={isMobile ? 28 : 56}
            lineHeight={1.5}
            fontWeight={700}
            color="neutral.white"
            variant="body2"
            textAlign="center"
          >
            {value}
          </Typography>
        </Box>
        <Box
          height="34%"
          width="100%"
          backgroundColor="main.primary"
          borderBottomLeftRadius={20}
          borderBottomRightRadius={20}
          py={isMobile ? 0 : 2}
          mt={1}
        >
          <Typography
            as="p"
            color="neutral.white"
            variant="body2"
            textAlign="center"
            fontSize="clamp(10px, 1.5vw, 17px)"
            // fontSize={isMobile ? 10 : 17}
            fontWeight={600}
            lineHeight={1.5}
          >
            {label}
          </Typography>
        </Box>
      </Box>
    );
  };

export const LotteryBannerSection: React.FC = () => {
  // const [localTime, setLocalTime] = React.useState<IRemoteDate>();
  // React.useEffect(() => {
  //   const fetchTime = async () => {
  //     const res = await axios.get<IRemoteDate>(
  //       'https://worldtimeapi.org/api/timezone/Europe/Warsaw'
  //     );
  //     setLocalTime(res.data);
  //   };
  //   fetchTime();
  // }, []);

  // React.useEffect(() => {
  //   if (localTime) {
  //     reset();
  //   }
  // }, [localTime]);

  const { seconds, minutes, hours, days } = useTimer(
    targetDate / 1000 - (new Date().getTime() / 1000 || 0)
  );

  const { isMobile } = React.useContext(MobileContext);
  if (targetDate / 1000 - (new Date().getTime() / 1000 || 0) <= 0) {
    return null;
  }
  return (
    <section>
      <SwiperSectionWrapper backgroundColor="neutral.white">
        <Container fluid md>
          <Row gutterWidth={0}>
            <Col xs={12}>
              <Swiper
                modules={[Autoplay]}
                autoplay={{ delay: 5000 }}
                loop
                style={{ alignItems: 'center' }}
              >
                <SwiperSlide>
                  <Banner
                    link="https://loteria.polskieradio.pl"
                    src={
                      isMobile
                        ? 'https://static.prsa.pl/1cb5b4e1-d5fd-4b6d-8baa-3c190506f680.file'
                        : 'https://static.prsa.pl/336eef53-e24b-4c64-a7bc-bcfa5ce259d3.file'
                    }
                    alt="Loteria Jedynki"
                  />
                </SwiperSlide>
                <SwiperSlide style={{ height: 'auto' }}>
                  <Link href="https://loteria.polskieradio.pl">
                    <Box
                      height="100%"
                      width="100%"
                      backgroundColor="main.primary"
                      display="flex"
                      alignItems="center"
                      flexDirection={isMobile ? 'column' : 'row'}
                    >
                      <Box
                        width={isMobile ? '100%' : '60%'}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        px={isMobile ? 4 : '10%'}
                        mr={isMobile ? 0 : 5}
                        py={isMobile ? 3 : 0}
                      >
                        <Typography
                          color="neutral.white"
                          as="p"
                          variant="body2"
                          fontFamily="Montserrat"
                          fontSize={isMobile ? 18 : 24}
                          textAlign="center"
                          lineHeight={1.5}
                        >
                          LOSOWANIE NAGRODY GŁÓWNEJ LOTERII JEDYNKI ZA
                        </Typography>
                      </Box>
                      <Box
                        height="100%"
                        width={isMobile ? '100%' : '40%'}
                        backgroundColor="neutral.white"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                      >
                        {!isMobile && (
                          <Box
                            height="320%"
                            width="150px"
                            position="absolute"
                            left="0"
                            top="0"
                            backgroundColor="neutral.white"
                            transform="translate3d(-45%, -50%, 0) rotate(20deg)"
                            zIndex={1}
                          />
                        )}
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          zIndex={2}
                          pr={isMobile ? 0 : 5}
                          pt={isMobile ? 2 : 0}
                        >
                          <TimeBox
                            isMobile={isMobile}
                            label="dni"
                            value={days}
                          />
                          <TimeBox
                            isMobile={isMobile}
                            label="godzin"
                            value={hours}
                          />
                          <TimeBox
                            isMobile={isMobile}
                            label="minut"
                            value={minutes}
                          />
                          <TimeBox
                            isMobile={isMobile}
                            label="sekund"
                            value={Math.floor(seconds)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </SwiperSlide>
              </Swiper>
            </Col>
          </Row>
        </Container>
      </SwiperSectionWrapper>
    </section>
  );
};

export default LotteryBannerSection;
