import { Box, Slider, Typography } from 'components';
import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { MobileContext } from 'utils/mobileContext';

export const Wrapper = styled.div`
  div.swiper-button-prev,
  div.swiper-button-next {
    top: initial;
    bottom: 20px;
    display: none;
    @media screen and (min-width: 768px) {
      width: ${({ theme }) => `${theme.space[5]}`}px;
      height: ${({ theme }) => `${theme.space[5]}`}px;
      display: flex;
      &::after {
        font-size: ${({ theme }) => `${theme.fluidFontSizes[3]}`};
      }
    }
  }
  div.swiper-button-prev {
    @media screen and (min-width: 768px) {
      right: ${({ theme }) => `${theme.space[5] + 16}`}px;
    }
  }
  div.swiper {
    padding-bottom: ${({ theme }) => `${theme.space[5] + theme.space[4]}`}px;
    padding-top: ${({ theme }) => `${theme.space[5] + theme.space[4]}`}px;
    div:last-of-type {
      width: fit-content;
    }
    overflow: visible;
    @media screen and (min-width: 768px) {
      overflow: hidden;
    }
  }
  .swiper-slide-next {
    overflow: visible;
    @media screen and (min-width: 768px) {
      overflow: hidden;
    }
  }
  .swiper-slide-active {
    overflow: visible;
    @media screen and (min-width: 768px) {
      overflow: hidden;
    }
  }
`;
export const Preview = styled.img`
  cursor: pointer;
  objectfit: cover;
  max-width: 40vw;
  max-height: 40vh;
`;
export const CloseBtn = styled.img`
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  margin-top: 25px;
  margin-left: 25px;
  padding: 5px;
  cursor: pointer;
  fill: #b3dfc8;
  max-width: 40vw;
  max-height: 40vh;
  border-radius: 50%;
  background-color: ${({ theme }) => `${theme.palette.main.primary}}`};
  height: ${({ theme }) => `${theme.space[4] + 10}`}px;
  width: ${({ theme }) => `${theme.space[4] + 10}`}px;
`;

export interface IImage {
  fileUid: string;
  author: string;
  description: string;
  photo: string;
}

export interface IProps {
  images: IImage[];
  title?: string;
}

export const ArticleGallery: React.FC<IProps> = ({ images, title }) => {
  const { isMobile } = React.useContext(MobileContext);
  const initialState = {
    status: false,
    image: {
      fileUid: '',
      author: '',
      description: '',
      photo: '',
    },
  };
  const [isActive, setIsActive] = React.useState(initialState);
  const [currentSlide, setCurrentSlide] = React.useState(1);

  const closeModal = (e: KeyboardEvent) => {
    if (e?.key === 'Escape') {
      setIsActive(initialState);
    }
  };
  React.useEffect(() => {
    document.addEventListener('keydown', (e) => closeModal(e));
  }, []);

  if (!images) {
    return null;
  }

  return (
    <Box
      as="section"
      my={5}
      mb={{ mobile: '-100px', tablet: 5 }}
      backgroundImage={{
        tablet: `linear-gradient(180deg,${theme.palette.main.primary} 50%,${theme.palette.neutral.white} 50%, ${theme.palette.neutral.white} 100%)`,
        mobile: `linear-gradient(180deg,${theme.palette.main.primary} 60%,${theme.palette.neutral.white} 60%, ${theme.palette.neutral.white} 100%)`,
      }}
      position="relative"
    >
      <Box
        backgroundColor="neutral.white"
        height={40}
        width={131}
        position="absolute"
        ml={{ mobile: 3, tablet: 5 }}
        transform="translate3d(0, -50%, 0)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="header1" as="h2">
          GALERIA
        </Typography>
      </Box>

      {isActive.status ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          zIndex={10}
          width="100vw"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            setIsActive(initialState);
          }}
          style={{ backdropFilter: isActive.status ? 'blur(10px)' : 'none' }}
        >
          <Box
            width="100%"
            maxWidth={1510}
            mt={5}
            mx="auto"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <CloseBtn src="/icons/close.svg" alt="close" />
            <Box transform="scale(2)" maxWidth="40vw">
              <Preview
                src={isActive.image.photo}
                alt={isActive.image.description}
              />
            </Box>
          </Box>
        </Box>
      ) : null}
      <Box overflowX="hidden" overflowY="visible" width="100%">
        <Wrapper>
          <Slider
            data={images}
            navigation
            headline={!isMobile}
            slidesBreakpoints={{
              0: {
                slidesPerView: 1.2,
              },
              740: {
                slidesPerView: 1.5,
                spaceBetween: theme.space[4],
              },
              1200: {
                slidesPerView: 2,
                spaceBetween: theme.space[6],
              },
              1440: {
                slidesPerView: 2.2,
                spaceBetween: theme.space[4],
              },
            }}
            headlineAbsolute
            paddingLeft={1}
            paddingRight={1}
            setCurrentSlide={setCurrentSlide}
            render={(image) => (
              <Box
                onClick={() => {
                  setIsActive({ status: true, image: { ...image } });
                }}
              >
                <img
                  src={image.photo}
                  alt={image.description}
                  style={{
                    cursor: 'pointer',
                    maxHeight: isMobile ? 190 : 365,
                  }}
                />
              </Box>
            )}
          >
            <Box>
              <Box
                position="absolute"
                bottom={5}
                maxWidth={{ tablet: '60%', mobile: '100%' }}
                display={{ tablet: 'block', mobile: 'none' }}
              >
                <Typography variant="caption">{title}</Typography>
              </Box>
              <Box position="absolute" top={3} right={0}>
                <Typography variant="lead3" color="neutral.white">
                  {currentSlide}/{images?.length}
                </Typography>
              </Box>
            </Box>
          </Slider>
        </Wrapper>
      </Box>
    </Box>
  );
};
