/* eslint-disable object-curly-newline */
import { Slider, AvatarBox, Link } from 'components';
import React from 'react';
import { TeamMember } from 'models';
import { MobileContext } from 'utils/mobileContext';

export const TeamSlider: React.FC<{ content: TeamMember[] }> = ({
  content,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  if (!content) return null;
  return (
    <Slider
      header="Zespół"
      slides={isMobile ? 1 : 5}
      group={isMobile ? 1 : 5}
      pagination={!isMobile}
      headline
      borderWidth={5}
      borderColor="main.primary"
      paddingLeft="0"
      paddingRight="0"
      navigation
      centered={isMobile}
      data={content}
      render={(member) => (
        <Link href={`/zespol/${member.articleId}`} key={member.id}>
          <AvatarBox
            src={`https://static.prsa.pl/images/${member?.fileUid}.jpg?format=700`}
            caption={`${member.name} ${member.surname}`}
            size="200px"
            centered={isMobile}
            alt={`${member.name} ${member.surname}`}
          />
        </Link>
      )}
    />
  );
};
