import { Link, Box, Typography } from 'components';
import React from 'react';
import useHover from 'utils/useHover';
import Image from 'next/image';

interface IProps {
  href: string;
  image?: string | null;
  hour?: string;
  title: string;
  borderColor: string;
  date?: string;
  onAir?: boolean;
  category?: string;
  newCard?: boolean;
}

export const NewsListItem: React.FC<IProps> = ({
  href,
  hour,
  image,
  title,
  borderColor = 'neutral.grayWithOpacity',
  date,
  onAir,
  category,
  newCard = false,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();

  return (
    <Link href={href} passHref newCard={newCard}>
      <Box
        ref={hoverRef}
        transition="0.3s"
        borderBottomWidth={1}
        borderBottomStyle="solid"
        borderColor={borderColor}
        py={2}
        paddingRight={3}
      >
        <Box display="flex" flexDirection={image && !onAir ? 'row' : 'column'}>
          {hour && (
            <Typography variant="listCaption2" color="main.primary" pb={2}>
              {hour}
            </Typography>
          )}
          {image && !onAir && (
            <Box
              width={94}
              height={54}
              flexShrink={0}
              backgroundSize="contain"
              position="relative"
            >
              <Image
                src={image || '/images/shareCard.jpeg'}
                alt={title}
                layout="fill"
                objectFit="contain"
              />
            </Box>
          )}
          {image && onAir && (
            <Box
              width="100%"
              aspectRatio="16 / 9"
              flexShrink={0}
              backgroundSize="cover"
              position="relative"
              mb={2}
            >
              <Image
                src={image || '/images/shareCard.jpeg'}
                alt={title}
                layout="fill"
                objectFit="contain"
              />
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            pl={image && !onAir ? 2 : 0}
          >
            {date && (
              <Typography variant="onAirDate" color="main.primary" pb={2}>
                {date}
              </Typography>
            )}
            <Typography
              variant="onAir"
              color="neutral.dark"
              opacity={isHover ? 0.7 : 1}
            >
              {title}
            </Typography>
            {onAir && category && (
              <Box display="flex" alignItems="center" pt={2}>
                <Box flexShrink={0}>
                  <Typography variant="onAirCategory" color="main.primary">
                    {category}
                  </Typography>
                </Box>
                <Box
                  height="1px"
                  width="100%"
                  backgroundColor="neutral.grayWithOpacity"
                  ml={2}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default NewsListItem;
