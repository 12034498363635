import { Box, Link, Button } from 'components';
import React from 'react';
import SVG from 'react-inlinesvg';
import Image from 'next/image';
import { MobileContext } from 'utils/mobileContext';

interface IProps {
  src: string;
  alt?: string;
  link: string;
  mb?: number;
}

export const PromoBox: React.FC<IProps> = ({ src, alt, link, mb }) => {
  const { isDesktop } = React.useContext(MobileContext);

  return (
    <Box
      position="relative"
      mt="18px"
      mb={{ tablet: mb ?? 5, mobile: mb ?? 4 }}
    >
      <Image
        src={src}
        layout="responsive"
        objectFit="cover"
        alt={alt}
        height="50%"
        width="100%"
        objectPosition="top left"
      />
      <Box
        position="absolute"
        left={isDesktop ? '10%' : '4%'}
        top="66%"
        width={120}
      >
        <Link href={link}>
          <Button
            color="main.primary"
            decorator={<SVG src="/icons/arrow_right.svg" />}
          >
            więcej
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default PromoBox;
