import { Typography } from 'components';
import React from 'react';
import {
  DAY_TIMESTAMP,
  getDate,
  getDateFromQuery,
  getDateWithSiblings,
  getDayShortOfWeek,
  getNextDay,
  getPrevDay,
} from 'utils/date';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import { MobileContext } from 'utils/mobileContext';
import { useRouter } from 'next/router';
import qs from 'qs';

interface IDateBlock {
  isActive?: boolean;
  isDisable?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  user-select: none;

  svg {
    opacity: 0.8;
    cursor: pointer;
    &:nth-of-type(1) {
      transform: rotate(180deg);
    }
    width: 24px;
    height: 24px;

    fill: ${({ theme }) => theme.palette.main.primary} !important;

    .st0 {
      fill: ${({ theme }) => theme.palette.main.primary} !important;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

const DateBlock = styled.div<IDateBlock>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.palette.main.primary : theme.palette.neutral.lightGray};
  width: 64px;
  height: 65px;
  cursor: pointer;
  ${({ isDisable, theme }) =>
    isDisable &&
    css`
      background-color: ${theme.palette.neutral.placeholder};
      opacity: 0.8;
      pointer-events: none;
    `}

  &:hover {
    color: ${({ theme, isActive }) => !isActive && theme.palette.main.primary};
  }
`;

interface IDayPicker {
  range?: number;
  maxToday?: boolean;
}

export const DayPicker: React.FC<IDayPicker> = ({ range = 7, maxToday }) => {
  const { isMobile } = React.useContext(MobileContext);
  const router = useRouter();
  const currentDate = getDateFromQuery(router.query.date);
  const dates = getDateWithSiblings(currentDate, isMobile ? 1 : range);

  const updateQuery = (date: Date) => {
    router.push(
      `${router.pathname}?${qs.stringify({
        ...router.query,
        date: date.getTime(),
      })}`
    );
  };

  const setNextDay = () => {
    if (maxToday) {
      return (
        currentDate.getTime() <= new Date().getTime() - DAY_TIMESTAMP &&
        updateQuery(getNextDay(currentDate))
      );
    }
    return (
      !maxToday &&
      currentDate !== new Date() &&
      updateQuery(getNextDay(currentDate))
    );
  };

  const setPrevDay = () => updateQuery(getPrevDay(currentDate));

  return (
    <Wrapper>
      <SVG src="/icons/arrow_right.svg" onClick={setPrevDay} />
      {dates.map((d: Date) => (
        <DateBlock
          key={String(d)}
          onClick={() => updateQuery(d)}
          isActive={currentDate === d}
          isDisable={maxToday && d > new Date()}
        >
          <Typography variant="h1">{getDate(d)}</Typography>
          <Typography variant="lead">
            {getDayShortOfWeek(d).toUpperCase()}
          </Typography>
        </DateBlock>
      ))}
      <SVG src="/icons/arrow_right.svg" onClick={setNextDay} />
    </Wrapper>
  );
};
