import { Box, Mosaic, PromoSection } from 'components';
import { noPadding } from 'helpers';
import { ArticleItem } from 'models';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { MobileContext } from 'utils/mobileContext';

export const MosaicSection: React.FC<{
  content: ArticleItem[];
  promo1?: ArticleItem;
  promo2?: ArticleItem;
}> = ({ content, promo1, promo2 }) => {
  const hasPromos = promo1 || promo2;
  const { isMobile, isDesktop } = React.useContext(MobileContext);
  return (
    <section>
      <Box pt={50} pb={3} backgroundColor="neutral.light">
        {isDesktop ? (
          <Container fluid md>
            <Row>
              <Col xs={hasPromos ? 8 : 12} style={{ paddingRight: 0 }}>
                <Mosaic content={content} />
              </Col>
              {hasPromos && (
                <Col xs={4} style={{ paddingLeft: 0 }}>
                  <PromoSection promo1={promo1} promo2={promo2} />
                </Col>
              )}
            </Row>
          </Container>
        ) : (
          <Container fluid md style={isMobile ? noPadding : {}}>
            <Row>
              <Col xs={12} style={isMobile ? noPadding : {}}>
                <Mosaic content={content} />
              </Col>
            </Row>
            {hasPromos && (
              <Row>
                <Col xs={12} style={isMobile ? noPadding : {}}>
                  <PromoSection promo1={promo1} promo2={promo2} />
                </Col>
              </Row>
            )}
          </Container>
        )}
      </Box>
    </section>
  );
};

export default MosaicSection;
