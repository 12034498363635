import themeConfig from 'theme';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  space,
  SpaceProps,
  variant,
  typography,
  TypographyProps,
  LayoutProps,
  layout,
  position,
  PositionProps,
  color,
  ColorProps,
  system,
} from 'styled-system';

export type TypographyType = {
  variant?: keyof typeof themeConfig.textStyles;
  children: React.ReactNode;
  fluidFontSize?: number;
  transition?: string;
  fill?: string;
  whiteSpace?: string;
} & SpaceProps &
  TypographyProps &
  PositionProps &
  LayoutProps &
  ColorProps;
export const Typography = styled.div<TypographyType>(
  variant({
    scale: 'textStyles',
  }),
  typography,
  space,
  layout,
  position,
  color,
  () => {
    return css`
      & > * {
        font-weight: inherit;
        font-family: inherit;
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        background-color: inherit;
        display: inherit;
        padding: inherit;
        padding-bottom: inherit;
        box-decoration-break: inherit;
      }
      svg,
      svg * {
        transition: 0.3s;
        ${system({
          fill: {
            property: 'fill',
            scale: 'palette',
          },
        })}
      }
    `;
  },
  system({
    color: {
      property: 'color',
      scale: 'palette',
    },
    backgroundColor: {
      property: 'backgroundColor',
      scale: 'palette',
    },
    fluidFontSize: {
      property: 'fontSize',
      scale: 'fluidFontSizes',
    },
    transition: true,
    whiteSpace: true,
  })
);
