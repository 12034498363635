import styled from 'styled-components';
import { motion } from 'framer-motion';

export const LiveTickerHeader = styled.p`
  font-weight: 600;
  position: relative;
  padding-left: 20px;
  line-height: 1;
  overflow: visible;
  &::before {
    content: '';
    background-color: #f23030;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 5px;
    top: 15%;
    border-radius: 50%;
    animation: pulse 2s infinite ease-in-out;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.5);
      opacity: 0.1;
    }

    50% {
      transform: scale(1.25);
      opacity: 1;
    }

    100% {
      transform: scale(0.5);
      opacity: 0.1;
    }
  }
`;

export const EventContainer = motion(styled.div`
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 0;
  border-color: ${({ theme }) => theme.palette.neutral.grayWithOpacity};
  &:first-of-type {
    border-top: 1px solid;
    border-color: ${({ theme }) => theme.palette.neutral.grayWithOpacity};
  }
  p {
    margin: 0 !important;
  }
  iframe {
    width: 50vw !important;
  }
`);
