import React from 'react';
import { MobileContext } from 'utils/mobileContext';
import { PlayerDesktop } from './desktop';
import { PlayerMobile } from './mobile';

interface Props {
  isHotTopic: boolean;
}

export const Player: React.FC<Props> = ({ isHotTopic }) => {
  const { isMobile } = React.useContext(MobileContext);
  if (isMobile) return <PlayerMobile isHotTopic={isHotTopic} />;
  return <PlayerDesktop isHotTopic={isHotTopic} />;
};
