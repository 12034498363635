import { Header, Footer, Box, Player, HotTopic } from 'components';
import { ThemeProvider } from 'styled-components';
import PlayerContextWrapper from 'utils/PlayerContext';
import theme, { paletteDark, paletteLight } from 'theme';
import React, { useState } from 'react';
import { ColorMode, PaletteContext } from 'utils/paletteContext';
import { MobileContext } from 'utils/mobileContext';

const getPaletteByMode = (mode: ColorMode) => {
  switch (mode) {
    case ColorMode.default:
      return paletteLight;
    case ColorMode.contrast:
      return paletteDark;
    default:
      return paletteLight;
  }
};

export const Layout: React.FC = ({ children }) => {
  const [isHotTopic, setHotTopic] = useState<boolean>(false);
  const { mode } = React.useContext(PaletteContext);
  const { isMobile } = React.useContext(MobileContext);
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document!.querySelector('body')!.style!.backgroundColor =
      getPaletteByMode(mode).neutral.white;
  }, [mode]);

  return (
    <PlayerContextWrapper>
      {/* <RodoBox /> */}
      <ThemeProvider theme={{ ...theme, palette: getPaletteByMode(mode) }}>
        <Header />
        <Box mt={isMobile ? '60px' : 0}>{children}</Box>
        <Footer />
        <Player isHotTopic={isHotTopic} />
        <HotTopic setHotTopic={setHotTopic} />
      </ThemeProvider>
    </PlayerContextWrapper>
  );
};
