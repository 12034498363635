import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import styled from 'styled-components';
import { Box } from 'components';

interface IProps {
  link: string;
}

const ShareIcon = styled.img`
  height: 30px;
  width: 30px;
  :hover {
    opacity: 0.8;
    transition: 0.3s;
  }
`;

export const ShareLinks: React.FC<IProps> = ({ link }) => {
  const [baseLink, setBaseLink] = React.useState(
    'https://www.jedynka.polskieradio.pl'
  );

  React.useEffect(() => {
    if (typeof window !== undefined) {
      setBaseLink(window?.location?.hostname);
    }
  }, [window]);
  const encodedLink = encodeURIComponent(link);

  return (
    <Box display="flex" mb="-4px" alignItems="center">
      <Box mr={1}>
        <FacebookShareButton url={`${baseLink}/${link}`}>
          <ShareIcon
            src="/icons/facebook-share.svg"
            alt="Udostępnij na Facebooku"
          />
        </FacebookShareButton>
      </Box>
      <Box mr={1}>
        <TwitterShareButton url={`${baseLink}/${link}`}>
          <ShareIcon src="/icons/x_active.png" alt="Udostępnij na X" />
        </TwitterShareButton>
      </Box>
      <Box mr={1}>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() =>
            window.open(
              `https://www.addtoany.com/add_to/wykop?linkurl=${encodeURIComponent(
                baseLink
              )}/${encodedLink}`,
              'popup',
              'width=550,height=400'
            )
          }
        >
          <ShareIcon src="/icons/wykop-share.svg" alt="Udostępnij na Wykopie" />
        </Box>
      </Box>
      <EmailShareButton url={`${baseLink}/${link}`}>
        <ShareIcon
          src="/icons/email-share.svg"
          alt="Udostępnij w wiadomości mailowej"
        />
      </EmailShareButton>
    </Box>
  );
};

export default ShareLinks;
