/* eslint-disable no-nested-ternary */
import React from 'react';
import styled, { css } from 'styled-components';
import { MobileContext } from 'utils/mobileContext';
import { system } from 'styled-system';

interface IProps {
  bgUrl?: string;
  records?: boolean;
  books?: boolean;
  pagination?: boolean;
  mobileScrollBar?: boolean;
}

interface IWrapper {
  arrowsColor: string;
  arrowsBorderColor: string;
  hoverArrowsColor: string;
  hoverArrowsBg: string;
  bulletsColor: string;
  hoverBulletColor: string;
  activeBulletColor: string;
  pb: number;
  pt: number;
  recordsPb?: number;
  recordsPt?: number;
  pagination?: boolean;
  swiperPb?: number;
}

const Wrapper = styled.div<IWrapper>(
  ({ pagination }) => css`
    width: 100%;
    position: relative;

    .swiper {
      ${system({
        pt: {
          property: 'paddingTop',
          scale: 'space',
        },
        pb: {
          property: 'paddingBottom',
          scale: 'space',
        },
      })}
    }

    .swiper-pagination {
      bottom: 0;
      display: ${pagination ? 'block' : 'none'};
    }

    .swiper-initialized {
      ${system({
        recordsPt: {
          property: 'paddingTop',
          scale: 'space',
        },
        recordsPb: {
          property: 'paddingBottom',
          scale: 'space',
        },
      })}
    }

    .swiper-pagination-bullet {
      opacity: 1;
      width: 10px;
      height: 10px;
      margin: 0 10px !important;
      :hover {
        ${system({
          hoverBulletColor: {
            property: 'backgroundColor',
            scale: 'palette',
          },
        })}
      }
      ${system({
        bulletsColor: {
          property: 'backgroundColor',
          scale: 'palette',
        },
      })}
    }
    .swiper-pagination-bullet-active {
      ${system({
        activeBulletColor: {
          property: 'backgroundColor',
          scale: 'palette',
        },
      })}
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 32px;
      height: 32px;
      border: 1px solid;
      border-color: transparent;
      top: 0;
      margin: 0;
      ${system({
        arrowsColor: {
          property: 'color',
          scale: 'palette',
        },
        arrowsBorderColor: {
          property: 'borderColor',
          scale: 'palette',
        },
      })}
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      ${system({
        hoverArrowsBg: {
          property: 'backgroundColor',
          scale: 'palette',
        },
        hoverArrowsColor: {
          property: 'color',
          scale: 'palette',
        },
      })}
    }

    .swiper-button-prev {
      right: 35px;
      left: auto;
    }
    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 12px;
      font-weight: 700;
    }
    .swiper-button-disabled {
      pointer-events: all;
    }
  `
);

export const SwiperContainer: React.FC<IProps> = ({
  bgUrl,
  records,
  books,
  children,
  pagination,
  mobileScrollBar,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <Wrapper
      arrowsColor={bgUrl ? 'neutral.light' : 'main.primary'}
      arrowsBorderColor={bgUrl ? 'neutral.light' : 'main.primary'}
      hoverArrowsColor={bgUrl ? 'main.primary' : 'neutral.light'}
      hoverArrowsBg={bgUrl ? 'neutral.light' : 'main.primary'}
      bulletsColor={bgUrl ? 'neutral.lightGray' : 'neutral.gray'}
      hoverBulletColor="main.primary"
      activeBulletColor="main.primary"
      pb={books ? 45 : 5}
      pt={books ? 45 : 5}
      recordsPb={
        isMobile && mobileScrollBar
          ? 3
          : (isMobile && !pagination) || records
          ? 0
          : books
          ? 45
          : 5
      }
      recordsPt={books || records ? 45 : 5}
      pagination={pagination}
    >
      {children}
    </Wrapper>
  );
};
