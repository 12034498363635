import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

interface IProps {
  title: string;
  description: string;
  image?: string;
  siteName?: string;
  canonUrl?: string;
}

const descriptionTrim = (text: string) => {
  let descriptionTrimmed = text;
  if (text.length > 150) {
    descriptionTrimmed = text.substring(0, 150);
    descriptionTrimmed = descriptionTrimmed.substr(
      0,
      Math.min(descriptionTrimmed.length, descriptionTrimmed.lastIndexOf(' '))
    );
  }
  return descriptionTrimmed;
};

export const SEO: React.FC<IProps> = ({
  children,
  title,
  description,
  image,
  siteName = 'Jedynka - Polskie Radio',
  canonUrl,
}) => {
  const router = useRouter();
  const canonicalURL =
    canonUrl || `https://jedynka.polskieradio.pl${router.asPath}`;
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={descriptionTrim(description)} />
      <meta property="og:title" content={title} />
      {image ? <meta property="og:image" content={image} /> : null}
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:description" content={descriptionTrim(description)} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta name="robots" content="index, follow" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="canonical"
        href={(typeof window !== 'undefined' && canonicalURL) || ''}
        key="canonical"
      />

      {children}
    </Head>
  );
};
