/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Hover, Typography, ButtonRounded, ImageLink } from 'components';
import { Row, Col } from 'react-grid-system';
import SVG from 'react-inlinesvg';

import styled from 'styled-components';
import Link from 'next/link';
import fbOn from 'public/icons/facebook_active.svg';
import igOn from 'public/icons/instagram_active.svg';
import ttOn from 'public/icons/x_active.png';
import ytOn from 'public/icons/youtube_active.svg';

const StyledUl = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: space-around;
`;

const StyledLi = styled.li`
  display: inline-block;
  margin-right: 25px;
  text-transform: uppercase;
`;

interface IProps {
  setOpen: (arg0: boolean) => void;
  open: boolean;
}

const NavLink: React.FC<{ href: string }> = ({ children, href }) => {
  return (
    <StyledLi>
      <Typography variant="recordCaption2" color="main.primary">
        &bull;&nbsp;
        <Hover opacity={0.5}>
          <Link href={href} passHref prefetch={false}>
            <a>{children}</a>
          </Link>
        </Hover>
      </Typography>
    </StyledLi>
  );
};

export const MobileMenu: React.FC<IProps> = ({ open, setOpen }) => {
  return (
    <Box
      width="80vw"
      height="100vh"
      position="relative"
      backgroundColor="neutral.lightGray"
      backgroundImage="url(images/NavbarMobileBG.png)"
      backgroundSize="cover"
      zIndex={4}
      flexDirection="column"
      paddingTop="25vh"
      paddingLeft="22%"
    >
      <Box display="flex" flexDirection="column" as="nav">
        <StyledUl>
          <NavLink href="/audycje">audycje</NavLink>
          <NavLink href="/playlista">playlista</NavLink>
          <NavLink href="/zespol">zespół</NavLink>
          <NavLink href="/artykuly/169">konkursy</NavLink>
          <NavLink href="/ramowka?current=JEDYNKA">ramówka</NavLink>
          <NavLink href="/czestotliwosci">częstotliwości</NavLink>
          <NavLink href="/kontakt">kontakt</NavLink>
        </StyledUl>
      </Box>
      {open && (
        <Box
          width="50px"
          height="50px"
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top="50%"
          left="0"
          backgroundColor="main.primary"
          transform="translate(-50%, -50%)"
          onClick={() => {
            setOpen(false);
          }}
        >
          <SVG src="/icons/close.svg" />
        </Box>
      )}
      <Box width="70%" marginTop="25%" marginBottom="15%">
        <a
          href="https://podcasty.polskieradio.pl/antena/jedynka"
          aria-label="Podcasty Polskiego Radia"
        >
          <ButtonRounded
            color="main.tertiary"
            decorator={<SVG src="/icons/mic.svg" />}
          >
            PODCASTY
          </ButtonRounded>
        </a>
      </Box>
      <Box display="flex" height="50px" width="100%">
        <Row
          style={{ height: '100%', width: '100%' }}
          gutterWidth={6}
          justify="center"
          align="center"
        >
          <Col xs={3} style={{ height: '100%' }}>
            <ImageLink
              href="https://www.instagram.com/jedynka_polskieradio/?hl=pl"
              imgOff={igOn}
              alt="Instagram"
            />
          </Col>
          <Col xs={3} style={{ height: '100%' }}>
            <ImageLink
              href="https://twitter.com/RadiowaJedynka"
              imgOff={ttOn}
              alt="Twitter"
            />
          </Col>
          <Col xs={3} style={{ height: '100%' }}>
            <ImageLink
              href="https://www.facebook.com/jedynka"
              imgOff={fbOn}
              alt="Facebook"
            />
          </Col>
          <Col xs={3} style={{ height: '100%' }}>
            <ImageLink
              href="https://www.youtube.com/channel/UCCWEiWQWpi7RUVZxzxTNtIA"
              imgOff={ytOn}
              alt="Youtube"
            />
          </Col>
        </Row>
      </Box>
    </Box>
  );
};
