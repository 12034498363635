/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { Layout } from 'layout';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { setConfiguration, ScreenClassProvider } from 'react-grid-system';
import { MobileProvider } from 'utils/mobileContext';
import Script from 'next/script';
import { SEO } from 'components';
import { PaletteProvider } from 'utils/paletteContext';
import NextNProgress from 'nextjs-progressbar';
import { useRouter } from 'next/router';
// import { ApplicationInsights } from '@microsoft/applicationinsights-web';

require('@openfonts/fira-sans_latin-ext');
require('@openfonts/montserrat_latin-ext');
require('@openfonts/montserrat_cyrillic-ext');
require('@openfonts/roboto_latin-ext');

// const appInsights = new ApplicationInsights({
//   config: {
//     instrumentationKey: process.env.API_KEY || '',
//   },
// });
// appInsights.loadAppInsights();
// appInsights.trackPageView();

interface IApp extends AppProps {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
}
declare global {
  interface Window {
    pp_gemius_hit: (identifier: string) => void;
    pp_gemius_identifier: string;
    gemius_init: any;
    gemius_player_data: any;
    GemiusPlayer: any;
  }
}

const loadGemiusScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = 'https://gapl.hit.gemius.pl/gplayer.js';
  document.head.appendChild(script);
};

function MyApp({ Component, pageProps }: IApp) {
  setConfiguration({
    breakpoints: [576, 768, 992, 1000, 1257],
    containerWidths: [540, 740, 960, 1257, 1257],
  });
  const router = useRouter();
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      loadGemiusScript();
      window.gemius_init =
        window.gemius_init ||
        function () {
          // eslint-disable-next-line no-multi-assign
          const x = (window.gemius_player_data =
            window.gemius_player_data || []);
          // eslint-disable-next-line prefer-rest-params
          x.push(arguments);
        };
      (window as Window).pp_gemius_hit(
        'bV.r28_AAI4V_sTmH4vPNNWb76bZOI7_5H80oSKzXuP.R7'
      );
    }
  }, [router.asPath]);
  return (
    <PaletteProvider>
      <SEO
        description="Jedynka - polskieradio.pl"
        title="Jedynka - polskieradio.pl"
      />
      <MobileProvider>
        <ScreenClassProvider>
          <Layout>
            <Script strategy="afterInteractive" id="gemiusScript">
              {`function gemius_pending(i) { window[i] = window[i] || function() {var x = window[i+'_pdata'] = window[i+'_pdata'] || []; x[x.length]=Array.prototype.slice.call(arguments, 0);};};
                (function(cmds) { var c; while(c = cmds.pop()) gemius_pending(c)})(['gemius_cmd', 'gemius_hit', 'gemius_event', 'gemius_init', 'pp_gemius_hit', 'pp_gemius_event', 'pp_gemius_init']);
                window.pp_gemius_cmd = window.pp_gemius_cmd || window.gemius_cmd;
                (function(d,t) {try {var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],l='http'+((location.protocol=='https:')?'s':''); gt.setAttribute('async','async');
                gt.setAttribute('defer','defer'); gt.src=l+'://gapl.hit.gemius.pl/xgemius.js'; s.parentNode.insertBefore(gt,s);} catch (e) {}})(document,'script');`}
            </Script>
            <Script
              id="googleAnalyticsScript1"
              strategy="lazyOnload"
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            />
            <Script strategy="lazyOnload" id="googleAnalyticsScript2">
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
                `}
            </Script>
            <NextNProgress color="#F68E1E" />
            <Component {...pageProps} />
          </Layout>
        </ScreenClassProvider>
      </MobileProvider>
    </PaletteProvider>
  );
}
export default MyApp;
