import { Box, Link, Typography } from 'components';
import { ShowItem } from 'models';
import Image from 'next/image';
import useHover from 'utils/useHover';

export const ShowMobileBox: React.FC<{ show: ShowItem }> = ({ show }) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();

  return (
    <Link
      href={`/artykul/${show?.categoryId},${show?.name
        .replace(/[.,:?!/"]/g, '')
        .split(' ')
        .join('-')}`}
    >
      <Box ref={hoverRef} display="flex" py={2}>
        <Box
          height={70}
          width={127}
          backgroundColor="#ddd"
          flexShrink={0}
          mr={3}
          position="relative"
        >
          <Image
            src={show?.photo || '/images/shareCard.jpeg'}
            alt={show?.name}
            layout="fill"
            objectFit="cover"
          />
        </Box>

        <Typography
          variant="leadMobile"
          color={isHover ? 'main.primary' : 'neutral.dark'}
          transition="0.3s"
        >
          {show?.name}
        </Typography>
      </Box>
    </Link>
  );
};
