/* eslint-disable object-curly-newline */
import { SwiperBox, PlaylistBoxItem } from 'components';
import { ShowPlaylist } from 'models';
import React from 'react';

export const PlaylistFetchBox: React.FC<{ list: ShowPlaylist[] }> = ({
  list,
}) => {
  return (
    <SwiperBox
      id="playlist"
      rounded="tr"
      data={
        list
          ?.map((e) => e.playlistItems)
          .flat()
          .slice(-10) ?? []
      }
      title="PLAYLISTA"
      onSwiper={(swiper) => swiper.slideTo(swiper.slides.length)}
      render={(audio) =>
        audio && (
          <PlaylistBoxItem
            key={audio.id}
            // audio={audio.file?.split('api')[0]}
            artist={audio.artist}
            title={audio.title}
            // cover={audio.cover}
            // startTime={audio.startTime}
          />
        )
      }
    />
  );
};

export default PlaylistFetchBox;
