/* eslint-disable no-nested-ternary */
import { Box, Typography, Link } from 'components';
import { ArticleItem, BoxInstanceId } from 'models';
import React from 'react';
import SVG from 'react-inlinesvg';
import useHover from 'utils/useHover';
import Image from 'next/image';
import { MobileContext } from 'utils/mobileContext';

const isNotEmptyString = (str: string | undefined) => {
  if (str === '') {
    return false;
  }
  return str;
};

interface IProps {
  decoratorTop?: JSX.Element;
  decoratorBottom?: boolean;
  color?: string;
  hoveredColor?: string;
  article: ArticleItem | BoxInstanceId;
  ultrawide?: boolean;
  lineClamp?: number;
  isMosaic?: boolean;
  isSlider?: boolean;
  isLatest?: boolean;
}

export const ArticleBox: React.FC<IProps> = ({
  decoratorTop = true,
  decoratorBottom = true,
  color = 'neutral.dark',
  hoveredColor = 'main.primary',
  article,
  ultrawide,
  lineClamp = 3,
  isMosaic = false,
  isSlider = false,
  isLatest = false,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const { isMobile, isDesktop } = React.useContext(MobileContext);
  const imgSrc =
    isNotEmptyString(article?.path) || isNotEmptyString(article?.photo);

  const customFieldsExtra = article?.customFields?.find(
    (el) => el.name === 'extra'
  );
  const customFieldsDateTime = article?.customFields?.find(
    (el) => el.name.toLowerCase() === 'naantenie'
  );

  return (
    <Link
      href={
        article?.customFields?.find(
          (customField) => customField.name === 'link'
        )?.value ||
        article?.url ||
        `/artykul/${article?.id},${article?.title
          .replace(/[.,:?!/"]/g, '')
          .split(' ')
          .join('-')}`
      }
    >
      <Box ref={hoverRef} transition="0.3s" height="100%">
        <Box
          height={
            (isMosaic && isDesktop) || isSlider
              ? undefined
              : isMobile
              ? '100%'
              : '80%'
          }
          maxWidth="100%"
          position="relative"
          overflow="hidden"
          backgroundSize="cover"
          backgroundPosition={ultrawide ? 'top center' : 'center'}
          backgroundRepeat="no-repeat"
          aspectRatio={ultrawide ? '62/26' : '16/9'}
        >
          <Image
            src={imgSrc || '/images/shareCard.jpeg'}
            alt="miniatura artykułu"
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
            objectPosition={ultrawide ? 'top center' : 'center'}
          />
          <Box
            height="100%"
            width="100%"
            position="absolute"
            overflow="hidden"
            backgroundColor={isHover ? 'rgba(0, 0, 0, 0.4)' : 'tranparent'}
            transition="0.3s"
          />
          {decoratorTop && article?.customFields && customFieldsExtra && (
            <Typography
              variant="caption3"
              fontFamily="Montserrat"
              backgroundColor="main.secondary"
              color="neutral.white"
              position="absolute"
              py={2}
              px={3}
              top={0}
              left={0}
            >
              {customFieldsExtra.value}
            </Typography>
          )}
          {decoratorBottom && !isLatest && (
            <Box
              display="flex"
              position="absolute"
              bottom={0}
              width="100%"
              left={0}
            >
              <Typography
                variant="caption3"
                fontFamily="Montserrat"
                backgroundColor="main.primary"
                color="neutral.white"
                py={2}
                px={3}
                height="fit-content"
              >
                {article?.categoryName?.toUpperCase()}
              </Typography>
              {article?.customFields && customFieldsDateTime && isMosaic && (
                <Typography
                  variant="caption3"
                  fontFamily="Montserrat"
                  backgroundColor="#D05620"
                  color="neutral.white"
                  py={2}
                  px={3}
                >
                  {customFieldsDateTime.value}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <Typography
            variant={isMobile ? 'leadMobile' : 'lead'}
            color={isHover ? hoveredColor : color}
            display="inline"
            transition="0.3s"
            fill={isHover ? hoveredColor : color}
          >
            <span
              style={{
                WebkitLineClamp: lineClamp,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-inline-box',
              }}
            >
              <Box display="inline-flex !important" alignItems="center">
                {article?.hasAudio && (
                  <span style={{ marginRight: 10 }}>
                    <SVG src="/icons/audio.svg" />
                  </span>
                )}
                {article?.hasVideo && (
                  <span style={{ marginRight: 10 }}>
                    <SVG src="/icons/video.svg" />
                  </span>
                )}
              </Box>
              {article?.title}
            </span>
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default ArticleBox;
