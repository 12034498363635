import { Box, Link, Typography } from 'components';
import React from 'react';
import useHover from 'utils/useHover';
import Image from 'next/image';
import { MobileContext } from 'utils/mobileContext';

interface IProps {
  backgroundImage: string;
  href: string;
  title: string;
}

export const SinglePodcast: React.FC<IProps> = ({
  backgroundImage,
  href,
  title,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const { isDesktop } = React.useContext(MobileContext);
  return (
    <Link href={href}>
      <Box
        ref={hoverRef}
        width="100%"
        height={0}
        paddingBottom="118%"
        position="relative"
        borderRadius="10px"
      >
        <Image
          src={backgroundImage || '/images/shareCard.jpeg'}
          alt={title}
          layout="fill"
          objectFit="cover"
          className="rounded-image"
        />
        <Typography
          as="h3"
          variant={isDesktop ? 'h3' : 'lead'}
          color="neutral.white"
          position="absolute"
          bottom={0}
          opacity={isHover ? 0 : 1}
          transition="0.3s"
          p={3}
          zIndex={2}
        >
          {title}
        </Typography>
        <Box
          width="100%"
          height="100%"
          position="absolute"
          borderRadius="10px"
          bottom="0"
          backgroundImage="radial-gradient(circle at 40% 140%, #2b2c41 60%, transparent 62%)"
          opacity={isHover ? 0 : 0.7}
          transition="0.3s"
        />
      </Box>
    </Link>
  );
};

export default SinglePodcast;
