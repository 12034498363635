/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import { SwiperContainer, Box, SectionTitle, Link, Button } from 'components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, Grid, Scrollbar } from 'swiper';
import { Container } from 'react-grid-system';
import theme from 'theme';
import Image from 'next/image';
import SVG from 'react-inlinesvg';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { MobileContext } from 'utils/mobileContext';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

interface ISwiper {
  dark: boolean;
}

interface IProps {
  header?: string;
  headerSize?: number;
  decorator?: boolean;
  decoratorHeight?: string | number;
  decoratorTop?: string;
  decoratorColor?: string;
  borderWidth?: number;
  borderColor?: string;
  seeMore?: string;
  seeMoreLeft?: string;
  paddingLeft?: number | string;
  paddingRight?: number | string;
  paddingContainer?: number;
  slides?: number;
  group?: number;
  bgUrl?: string;
  pagination?: boolean;
  navigation?: boolean;
  headline?: boolean;
  headlineAbsolute?: boolean;
  autoplay?: number;
  column?: number;
  spaceBetween?: number;
  disableSwipe?: boolean;
  records?: boolean;
  books?: boolean;
  centered?: boolean;
  reportage?: boolean;
  theater?: boolean;
  autoHeight?: boolean;
  data: Array<any>;
  children?: JSX.Element;
  loop?: boolean;
  setCurrentSlide?: React.Dispatch<React.SetStateAction<number>>;
  slidesBreakpoints?: {
    [key: number]: {
      width?: number;
      slidesPerView: number;
      spaceBetween?: number;
    };
  };
  render: (...args: any[]) => ReactNode;
  mobileScrollBar?: boolean;
  dark?: boolean;
}

const SwiperOverrides = styled.div<ISwiper>`
  width: 100%;
  height: 100%;
  .swiper-scrollbar {
    background: ${({ dark }) =>
      dark ? 'rgba(245, 245, 245, 0.2)' : theme.palette.neutral.white};
    border-radius: 0;
    height: 2px;
    width: 100%;
    left: 0;
    .swiper-scrollbar-drag {
      height: 2px;
      background-color: ${({ theme }) => theme.palette.main.primary};
      border-radius: 0;
    }
  }
`;

export const Slider: React.FC<IProps> = ({
  header,
  headerSize = 2,
  decorator,
  decoratorHeight,
  decoratorColor,
  decoratorTop,
  borderWidth,
  borderColor,
  seeMore,
  seeMoreLeft,
  paddingLeft,
  paddingRight,
  paddingContainer,
  slides,
  loop,
  group = 1,
  column,
  bgUrl,
  pagination,
  navigation,
  headline,
  headlineAbsolute,
  autoplay,
  data,
  records,
  books,
  render,
  children,
  setCurrentSlide,
  slidesBreakpoints,
  spaceBetween = theme.space[4],
  disableSwipe,
  centered,
  autoHeight,
  reportage,
  theater,
  mobileScrollBar = false,
  dark = false,
}) => {
  const isContainerFullWidth = paddingLeft === 0 || paddingRight === 0;
  const { isDesktop, isMobile } = React.useContext(MobileContext);
  return (
    <SwiperContainer
      bgUrl={bgUrl}
      records={records}
      books={books}
      pagination={pagination}
      mobileScrollBar={mobileScrollBar}
    >
      {seeMore && (
        <Box
          width={120}
          position="absolute"
          bottom={0}
          left={seeMoreLeft || '50%'}
          transform="translate(-50%, 50%)"
          zIndex={2}
        >
          <Link href={seeMore}>
            <Button
              color="main.primary"
              decorator={<SVG src="/icons/arrow_right.svg" />}
            >
              Więcej
            </Button>
          </Link>
        </Box>
      )}
      <Box
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        pt={bgUrl ? 5 : 0}
        pb={
          isMobile && mobileScrollBar
            ? 4
            : pagination && !books
            ? 5
            : records || books
            ? 0
            : 4
        }
        width="100%"
      >
        {bgUrl && (
          <Image src={bgUrl} alt={header} layout="fill" objectFit="cover" />
        )}
        {reportage || theater ? (
          <Container
            fluid
            md
            style={{
              padding: isContainerFullWidth ? 0 : '',
              paddingLeft: paddingContainer || 15,
              paddingRight: paddingContainer || 15,
              position: 'relative',
              marginLeft: reportage ? 0 : 'auto',
              marginRight: theater ? 0 : 'auto',
              maxWidth: isDesktop ? 683 : '100%',
            }}
          >
            <Box
              position="absolute"
              top="-30px"
              right={isDesktop ? 3 : isMobile ? '' : 4}
              left={isMobile ? 3 : ''}
              zIndex={2}
            >
              <SVG
                src="/icons/audio_slider_icon.svg"
                height={isMobile ? 32 : 'auto'}
              />
            </Box>
            <Box
              paddingLeft={paddingLeft ?? 4}
              paddingRight={paddingRight ?? 4}
            >
              <Box
                borderBottom="1px solid"
                borderColor={
                  headline
                    ? bgUrl
                      ? 'neutral.white'
                      : records
                      ? 'neutral.grayWithOpacity'
                      : 'neutral.lightGray'
                    : 'transparent'
                }
                display="flex"
                alignItems="flex-end"
                mb={-4}
                position={headlineAbsolute ? 'absolute' : 'initial'}
                bottom={headlineAbsolute ? '80px' : 'auto'}
                width={{
                  tablet: headlineAbsolute ? '70%' : '94%',
                  mobile: '94%',
                }}
              >
                {bgUrl ? (
                  <Box
                    position="absolute"
                    top={-theme.space[5]}
                    mt={-3}
                    backgroundColor="neutral.white"
                    py={2}
                    px={{ tablet: 4, mobile: 2 }}
                    zIndex={2}
                  >
                    <SectionTitle fontSize={headerSize}>{header}</SectionTitle>
                  </Box>
                ) : (
                  <SectionTitle
                    fontSize={headerSize}
                    decorator={decorator}
                    decoratorHeight={decoratorHeight}
                    decoratorTop={decoratorTop}
                    decoratorColor={decoratorColor}
                    borderWidth={borderWidth}
                    borderColor={borderColor}
                  >
                    {header}
                  </SectionTitle>
                )}
              </Box>
              {isMobile && mobileScrollBar ? (
                <SwiperOverrides dark={dark}>
                  <Swiper
                    direction="horizontal"
                    slidesPerView={1.5}
                    spaceBetween={20}
                    slidesPerGroup={1}
                    style={{ maxHeight: '100%' }}
                    modules={[Navigation, Scrollbar]}
                    scrollbar={{ hide: false }}
                    navigation={navigation}
                    loop={loop}
                  >
                    {data?.map((elem) => (
                      <SwiperSlide key={uuid()}>{render(elem)}</SwiperSlide>
                    ))}
                  </Swiper>
                </SwiperOverrides>
              ) : (
                <Swiper
                  modules={[
                    ...(pagination ? [Pagination] : []),
                    ...(navigation ? [Navigation] : []),
                    ...(autoplay ? [Autoplay] : []),
                    ...(column ? [Grid] : []),
                  ]}
                  spaceBetween={spaceBetween}
                  slidesPerGroup={group}
                  breakpoints={slidesBreakpoints}
                  slidesPerView={slides}
                  centeredSlides={centered}
                  grid={{ rows: column, fill: 'row' }}
                  navigation={navigation}
                  pagination={{ clickable: true }}
                  simulateTouch={!disableSwipe}
                  autoHeight={autoHeight}
                  loop={loop}
                  autoplay={{
                    delay: autoplay,
                    disableOnInteraction: false,
                  }}
                  onSlideChange={(swiper) =>
                    setCurrentSlide
                      ? setCurrentSlide(swiper.activeIndex + 1)
                      : null
                  }
                >
                  {data?.map((el, idx: number) =>
                    records ? (
                      idx > 0 && (
                        <SwiperSlide key={el.id}>{render(el)}</SwiperSlide>
                      )
                    ) : (
                      <SwiperSlide key={el.id}>{render(el)}</SwiperSlide>
                    )
                  )}
                  {children}
                </Swiper>
              )}
            </Box>
          </Container>
        ) : (
          <Container
            fluid
            md
            style={{
              padding: isContainerFullWidth ? 0 : '',
              paddingLeft: paddingContainer || 15,
              paddingRight: paddingContainer || 15,
              position: 'relative',
            }}
          >
            <Box
              paddingLeft={paddingLeft ?? 4}
              paddingRight={paddingRight ?? 4}
            >
              <Box
                borderBottom="1px solid"
                borderColor={
                  headline
                    ? bgUrl
                      ? 'neutral.white'
                      : records
                      ? 'neutral.grayWithOpacity'
                      : 'neutral.lightGray'
                    : 'transparent'
                }
                display="flex"
                alignItems="flex-end"
                mb={-4}
                position={headlineAbsolute ? 'absolute' : 'initial'}
                bottom={headlineAbsolute ? '80px' : 'auto'}
                width={{
                  tablet: headlineAbsolute ? '70%' : '94%',
                  mobile: '94%',
                }}
              >
                {bgUrl ? (
                  <Box
                    position="absolute"
                    top={-theme.space[5]}
                    mt={-3}
                    backgroundColor="neutral.white"
                    py={2}
                    px={{ tablet: 4, mobile: 2 }}
                    zIndex={2}
                  >
                    <SectionTitle fontSize={headerSize}>{header}</SectionTitle>
                  </Box>
                ) : (
                  <SectionTitle
                    fontSize={headerSize}
                    decorator={decorator}
                    decoratorHeight={decoratorHeight}
                    decoratorTop={decoratorTop}
                    decoratorColor={decoratorColor}
                    borderWidth={borderWidth}
                    borderColor={borderColor}
                  >
                    {header}
                  </SectionTitle>
                )}
              </Box>
              {isMobile && mobileScrollBar ? (
                <SwiperOverrides dark={dark}>
                  <Swiper
                    direction="horizontal"
                    slidesPerView={1.5}
                    spaceBetween={20}
                    slidesPerGroup={1}
                    style={{ maxHeight: '100%' }}
                    modules={[Navigation, Scrollbar]}
                    scrollbar={{ hide: false }}
                    navigation={navigation}
                    loop={loop}
                  >
                    {data?.map((elem) => (
                      <SwiperSlide key={uuid()}>{render(elem)}</SwiperSlide>
                    ))}
                  </Swiper>
                </SwiperOverrides>
              ) : (
                <Swiper
                  modules={[
                    ...(pagination ? [Pagination] : []),
                    ...(navigation ? [Navigation] : []),
                    ...(autoplay ? [Autoplay] : []),
                    ...(column ? [Grid] : []),
                  ]}
                  spaceBetween={spaceBetween}
                  slidesPerGroup={group}
                  breakpoints={slidesBreakpoints}
                  slidesPerView={slides}
                  centeredSlides={centered}
                  grid={{ rows: column, fill: 'row' }}
                  navigation={navigation}
                  pagination={{ clickable: true }}
                  simulateTouch={!disableSwipe}
                  autoHeight={autoHeight}
                  loop={loop}
                  autoplay={{
                    delay: autoplay,
                    disableOnInteraction: false,
                  }}
                  onSlideChange={(swiper) =>
                    setCurrentSlide
                      ? setCurrentSlide(swiper.activeIndex + 1)
                      : null
                  }
                >
                  {data?.map((el, idx: number) =>
                    records ? (
                      idx > 0 && (
                        <SwiperSlide key={el.id}>{render(el)}</SwiperSlide>
                      )
                    ) : (
                      <SwiperSlide key={el.id}>{render(el)}</SwiperSlide>
                    )
                  )}
                  {children}
                </Swiper>
              )}
            </Box>
          </Container>
        )}
      </Box>
    </SwiperContainer>
  );
};
