import React, { useEffect } from 'react';
import GrapReact from './GrapReact';

const GrapPlayer = ({ onPlay, onChange }) => {
  useEffect(() => {
    window.grapConfig = {
      // Wymagane: Identyfikator wydawcy
      pub_id: 'radio-90',

      // Wymagane: Identyfikator miejsca, playera
      place_id: 'preroll',

      // Wymagane: Kontener, w którym ma być wyrenderowany player
      container: '#player-area',

      // Opcjonalnie: TCString (z IAB TCF)
      tcstring: null,
      width: 0,
      height: 0,

      vast_url:
        'https://rmf.deliveryengine.adswizz.com/vast/4.0/request/alias/polskie-radio-preroll?aw_0_req.gdpr=true',

      // Opcjonalnie: Logo

      // Opcjonalnie: Kolory
      colors: {
        background: 'transparent',
        controls: '#F68E1D',
        text: 'transparent',
        // background_bar: 'rgba(255,255,255,.4)',
        // text_bar: '#777',
      },

      // Opcjonalnie: Teksty
      texts: {
        play_now: 'Włącz radio',
      },

      // Wymagane: Funkcja zwrotna uruchamiająca player radia
      callback() {
        console.log('Zdarzenie po odtworzeniu');
        onPlay();
      },
      state_change_callback(state) {
        onChange(state);
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    GrapReact.init();
  }, []);
  return (
    <>
      <div id="player-area" style={{ maxWidth: '700px' }} />
    </>
  );
};

export default GrapPlayer;
