/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { Box, StyledLabel, StyledInput, Typography } from 'components';
import React, { ChangeEvent } from 'react';

interface IProps {
  name: string;
  placeholder: string;
  value: string;
  setValue: (x: any) => void;
  error?: string | null;
}

export const Input: React.FC<IProps> = ({
  placeholder,
  name,
  value,
  setValue,
  error,
}) => {
  const changeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setValue((state: Record<string, unknown>) => ({
      ...state,
      [name]: e.target.value,
    }));
  };

  return (
    <Box width="100%">
      <StyledLabel htmlFor={name} />
      <StyledInput
        width="100%"
        variant="input"
        color="neutral.dark"
        placeholderColor="neutral.placeholder"
        borderBottom="2px solid"
        borderColor={
          error
            ? 'main.secondary'
            : value
            ? 'main.primary'
            : 'neutral.placeholder'
        }
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={changeValue}
      />
      {error && (
        <Typography variant="input" color="main.secondary">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default Input;
