interface SPrograms {
  name: string;
  color: string;
  href: string;
  invertTextColor?: boolean;
}

export const schedulePrograms: SPrograms[] = [
  {
    name: 'JEDYNKA',
    color: '#f68e1e',
    href: '/',
  },
  {
    name: 'DWÓJKA',
    color: '#009e61',
    href: 'https://polskieradio.pl/8,Dwojka',
  },
  {
    name: 'TRÓJKA',
    color: '#d80372',
    href: 'https://polskieradio.pl/9,Trojka',
  },
  {
    name: 'CZWÓRKA',
    color: '#fff402',
    href: 'https://polskieradio.pl/10,Czworka',
    invertTextColor: true,
  },
  {
    name: 'PR24',
    color: '#e31936',
    href: 'https://polskieradio24.pl/130,PR24',
  },
  {
    name: 'RADIO CHOPIN',
    color: '#e31936',
    href: 'https://www.polskieradio.pl/326,Radio-Chopin',
  },
  {
    name: 'RADIO DZIECIOM',
    color: '#951b81',
    href: 'https://polskieradio.pl/18,Radio-dzieciom',
  },
  {
    name: 'RADIO POLAND',
    color: '#e31936',
    href: 'https://thenews.pl',
  },
  {
    name: 'POLISH RADIO EXTERNAL SERVICE DAB+',
    color: '#e31936',
    href: 'https://thenews.pl/',
  },
  {
    name: 'EXTERNAL WSCHÓD',
    color: '#e31936',
    href: 'https://thenews.pl/',
  },
];
