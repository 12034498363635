import { Box, Typography, Link, Button, SectionTitle } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import SVG from 'react-inlinesvg';
import { MobileContext } from 'utils/mobileContext';
import Image from 'next/image';

interface IProps {
  recordOfTheWeek: ArticleItem[];
}

export const RecordOfTheWeek: React.FC<IProps> = ({ recordOfTheWeek }) => {
  const record = recordOfTheWeek[0];
  const { isMobile } = React.useContext(MobileContext);
  return (
    <Box
      p={isMobile ? '20px 8px' : '26px 20px'}
      display="flex"
      borderColor="main.primary"
      borderStyle="solid"
      borderWidth="2px 2px 7px 2px"
      height="100%"
    >
      <Box
        height={isMobile ? 196 : 266}
        width={isMobile ? 196 : 266}
        minWidth={isMobile ? 196 : 266}
        position="relative"
      >
        <Image
          src={record?.photo || '/images/shareCard.jpeg'}
          alt={record?.title}
          layout="fill"
          objectFit="cover"
          objectPosition="bottom"
        />
      </Box>
      <Box
        ml={3}
        display="flex"
        flexDirection="column"
        height={isMobile ? 196 : 266}
      >
        <SectionTitle
          borderWidth={1}
          decorator
          fontSize={isMobile ? 0 : 2}
          decoratorTop={isMobile ? '24%' : '12%'}
          decoratorHeight={isMobile ? 11 : 13}
        >
          płyta tygodnia
        </SectionTitle>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
          pt={3}
        >
          <Box>
            <Typography variant="recordCaption" color="neutral.dark">
              {record.shortTitle}
            </Typography>
            <Typography variant="recordCaption2" color="neutral.dark">
              {record.title}
            </Typography>
          </Box>
          {!isMobile && (
            <Box>
              <Typography
                variant="caption3"
                lineHeight="15px"
                color="neutral.dark"
              >
                {record.lead}
              </Typography>
            </Box>
          )}
          <Box width={120}>
            <Link
              href={`/artykul/${record?.id},${record?.title
                .replace(/[.,:?!/"]/g, '')
                .split(' ')
                .join('-')}`}
            >
              <Button
                color="main.primary"
                decorator={<SVG src="/icons/arrow_right.svg" />}
              >
                więcej
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RecordOfTheWeek;
