import styled, { css } from 'styled-components';

interface IProps {
  color?: string;
  baseColor?: string;
  opacity?: number;
}

export const Hover = styled.div<IProps>`
  ${({ color, baseColor, opacity }) => css`
    display: inline-block;
    color: ${baseColor};
    background-color: ${color};
    & > *,
    svg *,
    & {
      transition: 0.3s;
    }
    svg * {
      fill: ${baseColor};
    }
    &:hover > *,
    &:hover {
      color: ${color};
      background-color: ${baseColor};
      border-color: ${color};
      opacity: ${opacity};
      svg * {
        fill: ${color};
      }
    }
  `}
`;
