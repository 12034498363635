/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Hover, SearchBar, Typography } from 'components';
import { Container } from 'react-grid-system';
import { motion } from 'framer-motion';
import SVG from 'react-inlinesvg';

import styled from 'styled-components';
import Link from 'next/link';

const StyledUl = styled.ul`
  padding: 0;
`;

const StyledLi = styled.li`
  display: inline-block;
  margin-right: 25px;
  text-transform: uppercase;
`;

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(120%);
  }
`;

interface SVGProps {
  direction?: 'up' | 'down';
  color?: string;
}

const NavArrow = styled(SVG)<SVGProps>`
  transform: rotate(
    ${({ direction }) => (direction === 'up' ? '180deg' : '0deg')}
  );
  path {
    stroke: ${({ color }) => (color === 'neutral.white' ? '#FFF' : '#242424')};
  }
`;

const variants = {
  open: {
    opacity: 1,
  },
  collapsed: { opacity: 0 },
};

interface IProps {
  links?: Array<{ name: string; href: string }>;
  scrolled?: boolean;
  setOpen: (arg0: boolean) => void;
  setDirection?: (arg0: 'up' | 'down') => void;
  isHomepage?: boolean;
  direction?: 'up' | 'down';
  open?: boolean;
  backgroundColor?: string;
  color?: string;
}

const NavLink: React.FC<{ href: string; color: string }> = ({
  children,
  href,
  color,
}) => {
  return (
    <StyledLi>
      <Typography variant="caption" color={color}>
        &bull;&nbsp;
        <Hover opacity={0.8}>
          <Link href={href} passHref prefetch={false}>
            <a>{children}</a>
          </Link>
        </Hover>
      </Typography>
    </StyledLi>
  );
};

export const Navbar: React.FC<IProps> = ({
  scrolled,
  isHomepage,
  setOpen,
  open,
  direction,
  setDirection,
  backgroundColor = '#F68E1E',
  links,
  color = 'neutral.white',
}) => {
  let animation = scrolled || (!isHomepage && !open) ? 'open' : 'collapsed';
  let directionCheck = direction === 'up';

  return (
    <Box
      backgroundColor={backgroundColor}
      py={1}
      as="nav"
      position="fixed"
      left="0"
      width="100vw"
    >
      <Container fluid md>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" height="100%" alignItems="center" py={3}>
            <StyledUl>
              {links?.map((link) => {
                return (
                  <NavLink color={color} href={link.href}>
                    {link.name}
                  </NavLink>
                );
              })}
            </StyledUl>
            {(!isHomepage || scrolled) && (
              <StyledButton
                onClick={() => {
                  if (setDirection) {
                    setDirection(directionCheck ? 'down' : 'up');
                  } else {
                    return null;
                  }
                  setOpen(!open);
                }}
              >
                <NavArrow
                  color={color}
                  src="/icons/navbar_arrow.svg"
                  direction={direction}
                />
              </StyledButton>
            )}
          </Box>
          {!open && scrolled && (
            <Box
              width="20%"
              minWidth="200px"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <motion.div
                variants={variants}
                animate={animation}
                style={{ width: '100%' }}
              >
                <SearchBar />
              </motion.div>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};
