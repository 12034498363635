/* eslint-disable no-nested-ternary */
import React from 'react';
import SVG from 'react-inlinesvg';
import { Box, Audio, Typography, Button, Link, SectionTitle } from 'components';
import { ShowSound } from 'models';
import { MobileContext } from 'utils/mobileContext';

interface IProps {
  audio: ShowSound[];
}

export const NewsAudioBox: React.FC<IProps> = ({ audio }) => {
  const currentNewsAudio = audio[0];
  const { isTablet, isMobile } = React.useContext(MobileContext);

  return (
    <Box
      height="100%"
      overflow="hidden"
      backgroundColor="neutral.grayWithOpacity"
      backgroundImage="url(/images/news-audio-bg.png)"
      backgroundSize="cover"
      backgroundPosition="right, bottom right"
      backgroundRepeat="no-repeat"
      p={3}
    >
      <SectionTitle
        decorator
        decoratorTop={isTablet ? '24%' : '10%'}
        decoratorHeight={isTablet ? 11 : 13}
        decoratorColor="main.primary"
        fontSize={isMobile ? 12 : isTablet ? 1 : 2}
        borderWidth={1}
      >
        Aktualności Radiowej Jedynki
      </SectionTitle>
      <Typography variant="date" my={3} display="flex" letterSpacing="0.6px">
        Ostatnia audycja:&nbsp;
        <span style={{ fontWeight: 'normal' }}>
          {new Date(currentNewsAudio.datePublic)
            .toLocaleString('pl-pl', {
              day: 'numeric',
              month: 'long',
              hour: 'numeric',
              minute: 'numeric',
            })
            .split(',')
            .join(' godz. ')}
        </span>
      </Typography>
      <Box display="flex" alignItems="center" width="100%">
        <Box mr={3}>
          <Audio show={currentNewsAudio} />
        </Box>
        <Link href="/audycje/5069" width="auto">
          <Button
            color="main.primary"
            decorator={<SVG src="/icons/arrow_right.svg" />}
          >
            więcej
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default NewsAudioBox;
