import useInView from 'react-cool-inview';

export const LazyComponent: React.FC<{ height: number }> = ({
  children,
  height,
}) => {
  const { observe, inView } = useInView({
    onEnter: ({ unobserve }) => unobserve(),
    rootMargin: '50px',
  });

  return (
    <div style={{ minHeight: inView ? 0 : height }} ref={observe}>
      {inView && children}
    </div>
  );
};
