import { Box, Typography, Link, Button } from 'components';
import { Container } from 'react-grid-system';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Loader from 'react-loader-spinner';
import themeConfig from 'theme';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import React from 'react';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorPage = () => {
  const router = useRouter();

  if (router.isFallback) {
    return (
      <Wrapper>
        <Loader type="TailSpin" color={themeConfig.palette.main.primary} />
      </Wrapper>
    );
  }

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <Box as="section">
        <Container fluid md>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection={{ desktop: 'row', mobile: 'column' }}
            mb={5}
            mt={3}
          >
            <Box width="100%" height="100%">
              <img
                width="100%"
                height="70%"
                src="/images/404.png"
                alt="404"
                style={{ objectFit: 'cover' }}
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <Box borderBottom="5px solid" borderColor="main.primary" pb={1}>
                <Typography
                  variant="errorPage"
                  color="neutral.dark"
                  fontSize={{ tablet: 3, mobile: 1 }}
                >
                  Strona nie została odnaleziona
                </Typography>
              </Box>
              <Typography
                variant="errorPage"
                color="neutral.dark"
                fontSize={{ tablet: 18, mobile: 0 }}
                pt={1}
              >
                Wygląda na to, że strona, której szukasz nie istnieje.
              </Typography>
              <Box width={200} mt={{ tablet: 4, mobile: 3 }}>
                <Link href="/" target="_self">
                  <Button
                    color="main.primary"
                    decorator={<SVG src="/icons/arrow_right.svg" />}
                  >
                    wróć na jedynkę
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};
