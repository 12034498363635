/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios';
import { Box, Typography } from 'components/atoms';
import React, { useEffect, useState } from 'react';

interface Props {
  quizId: number;
  question: string;
}

interface QuizOption {
  description: string;
  id: number;
  imagePath: string;
  name: string;
  sound: string;
}

export const Quiz: React.FC<Props> = ({ quizId, question }) => {
  const [quizOptions, setQuizOptions] = useState<QuizOption[]>([]);
  const [isVotedProbe, setVotedProbe] = useState<boolean>(false);
  const [isVoted, setVoted] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(
        `https://votingresults.polskieradio.pl/api/probe/getoptions/${quizId}`
      )
      // @ts-ignore
      .then(({ data }) => data?.options && setQuizOptions(data.options));
  }, [quizId]);

  useEffect(() => {
    axios
      .get(`https://votingresults.polskieradio.pl/api/probe/start/${quizId}`)
      // @ts-ignore
      .then(({ data }) => data?.length !== 0 && setVotedProbe(true));
  }, []);

  const onVoteClick = (id: string | number) => {
    if (!isVotedProbe) {
      axios.post(
        `https://votingresults.polskieradio.pl/api/probe/vote/${quizId}`,

        JSON.stringify([id]),
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      setVoted(true);
    }
  };
  return (
    <Box
      borderRadius="12px"
      border="2px solid"
      borderColor={isVoted ? '#1ec4d6' : '#707070'}
      p="36px 24px 24px"
      position="relative"
      mt={5}
    >
      <Box
        boxShadow="0 3px 6px rgba(0, 0, 0, 0.16)"
        backgroundColor="#1ec4d6"
        borderRadius="15px"
        px="24px"
        py="10px"
        position="absolute"
        top="-8px"
        left="50%"
        transform="translate(-50%,-50%)"
      >
        <Typography
          fontSize={18}
          fontWeight={600}
          color="#FFF"
          fontFamily="Roboto"
        >
          SONDA
        </Typography>
      </Box>
      {isVoted ? (
        <>
          <Box py={5}>
            <Typography
              color="#1ec4d6"
              fontSize="50px"
              fontWeight={500}
              textAlign="center"
            >
              dziękujemy
            </Typography>
          </Box>
          <Box
            backgroundImage="url(/images/quizClose.png)"
            backgroundSize="cover"
            backgroundPosition="center"
            minWidth={56}
            width={56}
            height={59}
            position="absolute"
            right="-10px"
            top="-10px"
            transform="translate(-50%,-50%)"
            onClick={() => {
              setVoted(false);
              setVotedProbe(true);
            }}
            cursor="pointer"
          />
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center">
            <Box
              backgroundImage="url(/images/quizIcon.png)"
              backgroundSize="cover"
              backgroundPosition="center"
              minWidth={76}
              width={76}
              height={86}
            />
            <Typography
              fontSize={22}
              fontWeight={500}
              color="#f77a0f"
              fontFamily="Roboto"
              fontStyle="italic"
              lineHeight="26px"
              ml="40px"
            >
              {question}
            </Typography>
          </Box>
          <Box
            mt={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {quizOptions?.map(({ id, name }, index) => (
              <Box
                width={`${100 / quizOptions.length - 2}%`}
                border="1px solid #707070"
                backgroundColor={index % 2 !== 0 ? '#707070' : undefined}
                borderRadius="7px"
                p="24px"
                cursor="pointer"
                onClick={() => onVoteClick(id)}
                opacity={isVotedProbe ? 0.5 : 1}
              >
                <Typography
                  fontSize={16}
                  fontWeight={500}
                  color={index % 2 !== 0 ? '#fff' : '#707070'}
                  fontFamily="Roboto"
                  lineHeight="22px"
                >
                  {name}
                </Typography>
              </Box>
            ))}
          </Box>
          {isVotedProbe && (
            <Typography variant="lead3" textAlign="center" width="100%" mt={3}>
              Możesz oddać tylko jeden głos.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
