import { Box, Typography } from 'components';
import React from 'react';
import styled from 'styled-components';
import { MobileContext } from 'utils/mobileContext';
import useHover from 'utils/useHover';
import NextImage from 'next/image';

interface IProps {
  src: string;
  size: string;
  caption?: string;
  fontSize?: number;
  centered?: boolean;
  alt?: string;
}

interface Img {
  filter: string;
}

const Image = styled(NextImage)<Img>`
  border-radius: 50%;
  object-fit: cover;
  object-position: center top;
  filter: ${({ filter }) => filter};
  transition: 0.3s;
`;

export const AvatarBox: React.FC<IProps> = ({
  src,
  caption,
  size,
  fontSize = 14,
  centered,
  alt,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const { isMobile } = React.useContext(MobileContext);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="fit-content"
      marginBottom="24px"
      ref={hoverRef}
      mx={centered ? 'auto' : undefined}
    >
      <Box display="flex" justifyContent="center">
        <Image
          alt={alt}
          src={src}
          height={size}
          width={size}
          filter={isHover ? 'brightness(80%)' : 'none'}
        />
      </Box>
      {caption && (
        <Box
          display="flex"
          justifyContent="center"
          height={isMobile ? '70px' : 'initial'}
        >
          <Typography
            as="p"
            variant="avatarCaption"
            fontSize={fontSize}
            color={isHover ? 'main.primary' : 'neutral.dark'}
            marginTop="24px"
            marginBottom="10px"
            textAlign="center"
          >
            {caption}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
