import { TeamSlider, Box } from 'components';
import { Container } from 'react-grid-system';
import { TeamMember } from 'models';
import React from 'react';
import { MobileContext } from 'utils/mobileContext';

export const TeamSection: React.FC<{ teamContent: TeamMember[] }> = ({
  teamContent,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <section>
      <Box mt={isMobile ? 4 : 0}>
        <Container fluid md>
          <TeamSlider content={teamContent} />
        </Container>
      </Box>
    </section>
  );
};

export default TeamSection;
