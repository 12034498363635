import { Box, SectionTitle, ArticleBox, ShowArticleBox } from 'components';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { ArticleItem, ShowItem } from 'models';
import { v4 as uuid } from 'uuid';
import isLast from 'utils/isLast';
import { MobileContext } from 'utils/mobileContext';
import { ShowMobileBox } from '../ShowMobileBox';
import { ArticleMobileBox } from '../ArticleMobileBox';

interface IProps {
  title: string;
  articles?: Array<ArticleItem>;
  shows?: Array<ShowItem>;
}

export const RecommendedBox: React.FC<IProps> = ({
  title,
  articles,
  shows,
}) => {
  const { isMobile } = React.useContext(MobileContext);

  return (
    <Box
      pt={3}
      pb={5}
      backgroundColor="neutral.mediumGray"
      height="100%"
      px={isMobile ? 3 : 0}
    >
      <Container fluid md>
        <Row>
          <Col xs={12}>
            <Box
              borderBottom="1px solid"
              borderColor="neutral.grayWithOpacity"
              width="100%"
            >
              <SectionTitle borderWidth={5} borderColor="main.primary">
                {title}
              </SectionTitle>
            </Box>
          </Col>
        </Row>
        <Box pt={20}>
          {articles && (
            <Row gutterWidth={20}>
              {articles.map((_, ix) => (
                <Col
                  key={uuid()}
                  xs={12}
                  md={6}
                  lg={3}
                  style={{
                    marginBottom: isMobile ? 0 : undefined,
                  }}
                >
                  <Box
                    height={isMobile ? 'auto' : 205}
                    mb={isMobile ? 0 : 4}
                    borderBottom="1px solid"
                    borderColor={
                      !isMobile || (isMobile && isLast(ix, articles.length))
                        ? 'transparent'
                        : 'neutral.grayWithOpacity'
                    }
                  >
                    {isMobile ? (
                      <ArticleMobileBox article={articles[ix]} />
                    ) : (
                      <ArticleBox article={articles[ix]} />
                    )}
                  </Box>
                </Col>
              ))}
            </Row>
          )}
          {shows && (
            <Row gutterWidth={20}>
              {shows.map((_, ix) => (
                <Col key={uuid()} xs={12} md={6} lg={3}>
                  <Box height={isMobile ? 'auto' : 205} mb={isMobile ? 0 : 4}>
                    {isMobile ? (
                      <ShowMobileBox show={shows[ix]} />
                    ) : (
                      <ShowArticleBox show={shows[ix]} />
                    )}
                  </Box>
                </Col>
              ))}
            </Row>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default RecommendedBox;
