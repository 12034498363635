import { Box, LatestMosaic, OnAirList } from 'components';
import { noPadding } from 'helpers';
import { ArticleItem } from 'models';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { MobileContext } from 'utils/mobileContext';

export interface ILatest {
  mosaicContent: ArticleItem[];
  listContent: ArticleItem[];
  latestBox: ArticleItem;
}

export const LatestSection: React.FC<ILatest> = ({
  mosaicContent,
  listContent,
  latestBox,
}) => {
  const { isMobile, isDesktop } = React.useContext(MobileContext);
  return (
    <Box py={4} as="section">
      <Container fluid md>
        <Row>
          <Col xs={isDesktop ? 9 : 12} style={isMobile ? noPadding : {}}>
            <LatestMosaic content={mosaicContent} />
          </Col>
          {isDesktop && (
            <Col xs={3} style={{ paddingLeft: 0 }}>
              <OnAirList content={listContent} latestBox={latestBox} />
            </Col>
          )}
        </Row>
        {!isDesktop && (
          <Row>
            <Col xs={12} style={isMobile ? noPadding : {}}>
              <OnAirList content={listContent} latestBox={latestBox} />
            </Col>
          </Row>
        )}
      </Container>
    </Box>
  );
};

export default LatestSection;
