import React from 'react';
import { withCookies, useCookies } from 'react-cookie';
import { Box, Typography, Link, Button } from 'components';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import theme from 'theme';

const Logo = styled(SVG)`
  width: 110px;
`;

export const RodoBox: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [cookies, setCookies] = useCookies(['rodo-accept']);
  const rodoData = [
    'Administratorem Danych jest Polskie Radio S.A. z siedzibą w Warszawie, al. Niepodległości 77/85, 00-977 Warszawa',
    'W sprawach związanych z Pani/a danymi należy kontaktować się z Inspektorem Ochrony Danych, e-mail: iod@polskieradio.pl, tel. 22 645 34 03',
    'Dane osobowe będą przetwarzane w celach marketingowych na podstawie zgody.',
    'Dane osobowe mogą być udostępniane wyłącznie w celu prawidłowej realizacji usług określonych w polityce prywatności.',
    'Dane osobowe nie będą przekazywane poza Europejski Obszar Gospodarczy lub do organizacji międzynarodowej.',
    'Dane osobowe będą przechowywane przez okres 5 lat od dezaktywacji konta, zgodnie z przepisami prawa.',
    'Ma Pan/i prawo dostępu do swoich danych osobowych, ich poprawiania, przeniesienia, usunięcia lub ograniczenia przetwarzania.',
    'Ma Pan/i prawo do wniesienia sprzeciwu wobec dalszego przetwarzania, a w przypadku wyrażenia zgody na przetwarzanie danych osobowych do jej wycofania. Skorzystanie z prawa do cofnięcia zgody nie ma wpływu na przetwarzanie, które miało miejsce do momentu wycofania zgody.',
    'Przysługuje Pani/u prawo wniesienia skargi do organu nadzorczego.',
    'Polskie Radio S.A. informuje, że w trakcie przetwarzania danych osobowych nie są podejmowane zautomatyzowane decyzje oraz nie jest stosowane profilowanie.',
  ];

  React.useEffect(() => {
    if (!cookies['rodo-accept']) {
      setIsVisible(true);
    }
  }, []);

  const accept = () => {
    setIsVisible(false);
    setCookies('rodo-accept', true, {
      path: '/',
      maxAge: 2147483647,
    });
  };

  const close = () => {
    setIsVisible(false);
  };

  return isVisible ? (
    <Box
      width="100vw"
      height="100vh"
      position="fixed"
      zIndex={99999999}
      backgroundColor="rgba(0, 0, 0, 0.9)"
      padding={{ tablet: '2rem', mobile: 0 }}
      left={0}
      top={0}
      overflow="auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        maxHeight="90vh"
        width={{ tablet: '70%', mobile: '100%' }}
        maxWidth="1190px"
        borderRadius={10}
        overflow="auto"
        backgroundColor={theme.palette.neutral.white}
        position="relative"
      >
        <Box
          as="button"
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="none"
          backgroundColor="transparent"
          cursor="pointer"
          position="absolute"
          top={34}
          right={20}
          onClick={close}
        >
          <SVG src="/icons/close-letter-filter.svg" />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          pt={3}
          px={{ tablet: 4, mobile: 3 }}
        >
          <Box mr={3}>
            <Link href="/" ariaLabel="Jedynka" width="auto" target="_self">
              <Logo src="/logos/jedynka.svg" />
            </Link>
          </Box>
          <Link
            href="https://www.polskieradio.pl/"
            ariaLabel="Polskie-Radio"
            width="auto"
          >
            <Logo src="/logos/logo_PR.svg" />
          </Link>
        </Box>
        <Box
          backgroundColor={theme.palette.neutral.mediumGray}
          px={{ tablet: 4, mobile: 3 }}
          py={3}
        >
          <Typography fontSize={25} fontWeight={700} pb={3}>
            SZANOWNY UŻYTKOWNIKU
          </Typography>
          <Typography fontSize={16} lineHeight="22px" fontFamily="Roboto">
            25 maja 2018 roku zaczęło obowiązywać Rozporządzenie Parlamentu
            Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r (RODO).
            Zachęcamy do zapoznania się z informacjami dotyczącymi przetwarzania
            danych osobowych w Portalu PolskieRadio.pl
          </Typography>
        </Box>
        <Box
          as="ol"
          backgroundColor={theme.palette.neutral.white}
          px={{ tablet: 4, mobile: 3 }}
          py={3}
        >
          {rodoData.map((text: string) => (
            <Typography
              as="li"
              key={text}
              fontSize={14}
              fontFamily="Roboto"
              fontWeight={700}
              lineHeight="21px"
              ml={3}
            >
              {text}
            </Typography>
          ))}
        </Box>
        <Box
          backgroundColor={theme.palette.neutral.mediumGray}
          px={{ tablet: 4, mobile: 3 }}
          py={3}
        >
          <Box display="flex">
            <Typography
              fontSize={18}
              fontWeight={700}
              lineHeight="24px"
              fontFamily="Roboto"
            >
              Więcej informacji na ten temat znajdziesz na stronach&nbsp;
              <a
                href="https://www.polskieradio.pl/13/6788"
                style={{
                  display: 'inline',
                  color: theme.palette.main.secondary,
                }}
              >
                dane osobowe&nbsp;
              </a>
              oraz&nbsp;
              <a
                href="https://www.polskieradio.pl/13/53/Artykul/254368"
                style={{
                  display: 'inline',
                  color: theme.palette.main.secondary,
                }}
              >
                polityka prywatności
              </a>
            </Typography>
          </Box>
          <Box width={130} mt={3} mb={2} pb={4}>
            <Button color={theme.palette.main.primary} onClick={accept}>
              Rozumiem
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default withCookies(RodoBox);
