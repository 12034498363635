import { Box, Typography, Link, Embed } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import SVG from 'react-inlinesvg';
import useHover from 'utils/useHover';

interface IProps {
  decoratorTop?: JSX.Element;
  color?: string;
  hoveredColor?: string;
  article: ArticleItem;
}

export const EmbedBox: React.FC<IProps> = ({
  decoratorTop,
  color = 'neutral.dark',
  hoveredColor = 'main.primary',
  article,
}) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  // TODO: get rid of this.
  // eslint-disable-next-line no-param-reassign
  if (!article.path) article.path = article.photo;
  return (
    <Box ref={hoverRef} transition="0.3s" height="100%">
      <Box position="relative" overflow="hidden">
        <Embed
          aspectHeight={10.8}
          link={article.customFields?.find((x) => x.name === 'yt')?.value || ''}
        />
        <Box
          height="100%"
          width="100%"
          position="absolute"
          overflow="hidden"
          transition="0.3s"
        />
        <>{decoratorTop}</>
      </Box>
      <Link href={`/artykul/${article?.id}`}>
        <Box mt={2} ref={hoverRef}>
          <Typography
            variant="lead"
            color={isHover ? hoveredColor : color}
            display="inline"
            transition="0.3s"
            fill={isHover ? hoveredColor : color}
          >
            <span
              style={{
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-inline-box',
              }}
            >
              {article?.hasAudio && (
                <span style={{ marginRight: 10 }}>
                  <SVG src="/icons/audio.svg" />
                </span>
              )}
              {article?.hasVideo && (
                <span style={{ marginRight: 10 }}>
                  <SVG src="/icons/video.svg" />
                </span>
              )}
              {article?.title}
            </span>
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default EmbedBox;
