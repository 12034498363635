import { Box, SectionTitle, LargeSocial } from 'components';
import React from 'react';
import theme from 'theme';
import { MobileContext } from 'utils/mobileContext';

export const SocialsLarge: React.FC = () => {
  const { isDesktop } = React.useContext(MobileContext);

  return (
    <Box
      display="flex"
      justifyContent={{ desktop: 'space-between', mobile: 'initial' }}
      alignItems={{ desktop: 'center', mobile: 'initial' }}
      width={{ desktop: 300, mobile: '100%' }}
      height={{ desktop: 174, mobile: `calc(45vw + ${theme.space[4]}px)` }}
      flexDirection="column"
      my={{ desktop: 1, mobile: 4 }}
    >
      <SectionTitle
        decorator
        decoratorHeight="12px"
        decoratorColor="main.primary"
        lineHeight="24px"
        fontSize={2}
        borderWidth={1}
        borderColor="neutral.lightGray"
      >
        bądź na bieżąco
      </SectionTitle>
      <Box
        marginTop={4}
        width={{ desktop: 300, mobile: '100%' }}
        height={{ desktop: theme.space[6], mobile: '100%' }}
      >
        <Box
          width="100%"
          height="100%"
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="1fr 1fr"
          gridGap={isDesktop ? 3 : 4}
        >
          <Box
            height={{ desktop: 70, mobile: 'auto' }}
            width={{ desktop: 145, mobile: '100%' }}
          >
            <LargeSocial
              imgOff="/icons/YoutubeBig.svg"
              imgOn="/icons/YoutubeBig.svg"
              width={isDesktop ? 145 : '100%'}
              href="https://www.youtube.com/channel/UCCWEiWQWpi7RUVZxzxTNtIA"
            />
          </Box>
          <Box
            height={{ desktop: 70, mobile: 'auto' }}
            width={{ desktop: 145, mobile: '100%' }}
          >
            <LargeSocial
              imgOff="/icons/XBig.svg"
              imgOn="/icons/XBig.svg"
              width={isDesktop ? 145 : '100%'}
              href="https://twitter.com/RadiowaJedynka"
            />
          </Box>
          <Box
            height={{ desktop: 70, mobile: 'auto' }}
            width={{ desktop: 145, mobile: '100%' }}
          >
            <LargeSocial
              imgOff="/icons/InstagramBig.svg"
              imgOn="/icons/InstagramBig.svg"
              width={isDesktop ? 145 : '100%'}
              href="https://www.instagram.com/jedynka_polskieradio/?hl=pl"
            />
          </Box>
          <Box
            height={{ desktop: 70, mobile: 'auto' }}
            width={{ desktop: 145, mobile: '100%' }}
          >
            <LargeSocial
              imgOff="/icons/FacebookBig.svg"
              imgOn="/icons/FacebookBig.svg"
              width={isDesktop ? 145 : '100%'}
              href="https://www.facebook.com/jedynka"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
