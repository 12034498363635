import { Box, Podcasts } from 'components';
import { Podcast } from 'models';
import React from 'react';
import { Container } from 'react-grid-system';
import { MobileContext } from 'utils/mobileContext';
import { noPadding } from 'helpers';

export const PodcastSection: React.FC<{ podcastsContent: Podcast[] }> = ({
  podcastsContent,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <section>
      <Container fluid md style={isMobile ? noPadding : {}}>
        <Box py={3}>
          <Podcasts content={podcastsContent} />
        </Box>
      </Container>
    </section>
  );
};

export default PodcastSection;
