/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
import { Slider, ArticleBox, Box, EmbedBox } from 'components';
import React from 'react';
import { ArticleItem } from 'models';
import { MobileContext } from 'utils/mobileContext';

export const ConcertsSlider: React.FC<{ content: ArticleItem[] }> = ({
  content,
}) => {
  const { isMobile, isTablet, isDesktop } = React.useContext(MobileContext);
  if (!content) return null;
  const articleHasCustomFields = (article: ArticleItem) => {
    return article?.customFields?.find((x) => x.name === 'yt')?.value;
  };
  return (
    <Slider
      header="KONCERTY JEDYNKI"
      headerSize={isDesktop ? 3 : 1}
      seeMore="/artykuly/3729"
      slides={isMobile ? 1 : isTablet ? 2 : 3}
      mobileScrollBar
      headline
      navigation
      pagination
      paddingLeft={4}
      paddingRight={4}
      paddingContainer={isMobile ? 1 : 0}
      bgUrl="/images/concerts_bg.png"
      data={content}
      dark
      render={(article) => (
        <Box
          height={!isDesktop ? 'auto' : 210}
          width={!isDesktop ? '100%' : undefined}
        >
          {articleHasCustomFields(article) ? (
            <EmbedBox article={article} color="neutral.white" />
          ) : (
            <ArticleBox article={article} color="neutral.white" isSlider />
          )}
        </Box>
      )}
    />
  );
};
