import dynamic from 'next/dynamic';
import React from 'react';
import styled from 'styled-components';

const HeaderDesktop = dynamic(() => import('./desktop'));
const HeaderMobile = dynamic(() => import('./mobile'));

const MobileView = styled.header`
  display: none;
  @media (max-width: 768px) {
    display: initial;
  }
`;
const DesktopView = styled.header`
  display: none;
  @media (min-width: 769px) {
    display: initial;
  }
`;

export const Header: React.FC = () => {
  return (
    <>
      <DesktopView>
        <HeaderDesktop />
      </DesktopView>
      <MobileView>
        <HeaderMobile />
      </MobileView>
    </>
  );
};
