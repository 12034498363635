import React from 'react';
import styled, { css } from 'styled-components';
import { system } from 'styled-system';

interface IContainer {
  inactiveColor: string;
  activeColor: string;
  borderColor: string;
}

const Container = styled.div<IContainer>(
  () => css`
    width: 100%;

    .react-tabs__tab-list {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 20px;
      ${system({
        borderColor: {
          property: 'borderColor',
          scale: 'palette',
        },
      })}
    }

    .react-tabs__tab {
      padding: 10px 15px;
      text-transform: uppercase;
      opacity: 0.7;
      border-radius: 0;
      ${system({
        inactiveColor: {
          property: 'color',
          scale: 'palette',
        },
      })}
      &:hover {
        transition: 0.3s;
        opacity: 1;
        ${system({
          activeColor: {
            property: 'color',
            scale: 'palette',
          },
        })}
      }
    }

    .react-tabs__tab--selected {
      opacity: 1;
      ${system({
        activeColor: {
          property: 'color',
          scale: 'palette',
        },
        borderColor: {
          property: 'borderColor',
          scale: 'palette',
        },
      })}
    }
  `
);

export const TabsContainer: React.FC<{ activeColor?: string }> = ({
  activeColor = 'main.primary',
  children,
}) => {
  return (
    <Container
      inactiveColor="neutral.dark"
      activeColor={activeColor}
      borderColor="main.primary"
    >
      {children}
    </Container>
  );
};
