import { Box, Typography, Link, Embed, Button, CirclesBox } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import SVG from 'react-inlinesvg';
import { MobileContext } from 'utils/mobileContext';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const StreamBox: React.FC<{
  streamContent: ArticleItem;
  streamFallback?: boolean;
}> = ({ streamContent, streamFallback }) => {
  const streamProps = Object.fromEntries(
    streamContent?.customFields?.map(Object.values) || []
  );

  const { isMobile } = React.useContext(MobileContext);

  const isOrange = +streamProps?.isOrange === 1;

  const imageLinked = streamProps?.image;
  const linkedTo = streamProps?.link;

  const articleSrc = streamProps?.artykuł;

  const getBoxPaddingBottom = () => {
    if (imageLinked) {
      return 0;
    }
    if (!articleSrc) {
      return 3;
    }
    return 4;
  };

  return (
    <Box
      display="flex"
      border="1px solid"
      borderColor="neutral.gray"
      flexDirection={{ tablet: 'row', mobile: 'column' }}
    >
      <Box
        width={{ desktop: '50%', tablet: '100%' }}
        height="fit-content"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {streamFallback && (
          <>
            {streamProps?.stream && <Embed link={streamProps?.stream || ''} />}
            {!streamProps?.stream && (
              <Wrapper>
                <embed
                  src={
                    streamProps?.fallback
                      ? streamProps.fallback
                      : 'https://embed.polskieradio.pl/stream/jedynka-stream4.html'
                  }
                />
              </Wrapper>
            )}
          </>
        )}
        {!streamFallback && (
          <Embed
            link={streamProps?.stream?.replace('/watch?v=', '/embed/') || ''}
          />
        )}
      </Box>
      <Box
        width={{ tablet: '50%', mobile: '100%' }}
        p={{ desktop: imageLinked ? 0 : 3, mobile: 0 }}
        pb={{ desktop: getBoxPaddingBottom(), mobile: 0 }}
      >
        {imageLinked ? (
          <>
            {linkedTo && (
              <a href={linkedTo}>
                <img
                  src={imageLinked}
                  alt="baner"
                  style={{
                    width: '100%',
                    maxHeight: '360px',
                  }}
                />
              </a>
            )}
            {!linkedTo && (
              <img
                src={imageLinked}
                alt="baner"
                style={{
                  width: '100%',
                  maxHeight: '360px',
                }}
              />
            )}
          </>
        ) : (
          <Box
            backgroundColor={isOrange ? 'main.primary' : 'neutral.lightGray'}
            mb={{ desktop: 3, mobile: 0 }}
            py={4}
            px={3}
            height="100%"
            position="relative"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {isMobile ? (
              <Box position="absolute" top={0} right={0}>
                <CirclesBox
                  color={isOrange ? 'neutral.lightGray' : 'neutral.gray'}
                />
              </Box>
            ) : null}

            <Typography
              variant="header1"
              color="neutral.dark"
              as="h2"
              pb={4}
              textAlign="center"
            >
              {streamContent?.title}
            </Typography>
            <Typography
              variant="lead2"
              color="neutral.dark"
              pb={4}
              fontFamily="Roboto"
              textAlign="center"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: streamContent?.content || '',
                }}
              />
            </Typography>
            {streamProps?.start && (
              <Box
                backgroundColor={isOrange ? 'neutral.white' : 'neutral.gray'}
                px={4}
                py={2}
                borderRadius={15}
              >
                <Typography
                  variant="caption2"
                  color="neutral.dark"
                  textAlign="center"
                >
                  START GODZ {streamProps?.start}
                </Typography>
              </Box>
            )}
            {articleSrc && (
              <>
                <Box
                  backgroundColor="neutral.white"
                  position="absolute"
                  left="50%"
                  transform="translate(-50%, 50%)"
                  width={128}
                  height={43}
                  bottom={0}
                />

                <Box
                  position="absolute"
                  bottom={0}
                  left="50%"
                  transform="translate(-50%, 50%)"
                  width={120}
                  height={35}
                >
                  <Link href={`${articleSrc}`} passHref>
                    <Button
                      color="main.primary"
                      decorator={<SVG src="/icons/arrow_right.svg" />}
                    >
                      więcej
                    </Button>
                  </Link>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StreamBox;
