/* eslint-disable @typescript-eslint/no-explicit-any */
import { TabsContainer, Box, Typography, Dropdown } from 'components';
import dynamic from 'next/dynamic';
import { Tab, TabList, TabPanel, TabsProps } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ITab } from 'components/organisms/NewsSection/NewsSection';
import { MobileContext } from 'utils/mobileContext';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import React from 'react';

const StyledButton = styled.button<{
  rotated: boolean;
  isActive: boolean;
}>(
  ({ theme, rotated, isActive }) => css`
    display: flex;
    border: none;
    justify-content: center;
    background: transparent;
    width: 41px;
    height: 41px;
    align-items: center;
    svg * {
      stroke: ${isActive
        ? theme.palette.main.primary
        : theme.palette.neutral.dark};
    }
    transform: ${rotated ? 'rotate(180deg)' : 'unset'};
  `
);

const Tabs = dynamic<TabsProps>(
  import('react-tabs').then((mod) => mod.Tabs),
  { ssr: false }
);

interface ITabs {
  tabIndex: number;
  onSelect?: (x: any) => void;
  onPrevClick: () => void;
  onNextClick: () => void;
  titles?: Array<any>;
  dropdown?: boolean;
  options?: Array<ITab>;
  activeOption?: ITab;
  setActiveOption?: (x: ITab) => void;
  borderRight?: boolean;
}

export const TabsBox: React.FC<ITabs> = ({
  tabIndex,
  onSelect,
  onPrevClick,
  onNextClick,
  titles,
  dropdown,
  options,
  activeOption,
  setActiveOption,
  children,
  borderRight = false,
}) => {
  const { isDesktop } = React.useContext(MobileContext);
  return (
    <TabsContainer activeColor={!isDesktop ? 'neutral.dark' : 'main.primary'}>
      {!isDesktop ? (
        <Tabs defaultIndex={0} key="tabs-tablet">
          {dropdown ? (
            <Box display="flex" alignItems="flex-end" position="relative">
              <TabList
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {titles && (
                  <Tab style={{ position: 'relative', boxShadow: 'none' }}>
                    <Typography variant="h3">{titles[tabIndex]}</Typography>
                    <Box
                      position="absolute"
                      left="-20px"
                      top="50%"
                      transform="translate(-100%, -50%)"
                    >
                      <StyledButton
                        isActive={tabIndex > 0 ?? false}
                        onClick={onPrevClick}
                        rotated
                        aria-label="wstecz"
                      >
                        <SVG src="/icons/arrow-tab.svg" />
                      </StyledButton>
                    </Box>
                    <Box
                      position="absolute"
                      right="-20px"
                      top="50%"
                      transform="translate(100%, -50%)"
                    >
                      <StyledButton
                        isActive={tabIndex < titles.length - 1 ?? false}
                        onClick={onNextClick}
                        rotated={false}
                        aria-label="dalej"
                      >
                        <SVG src="/icons/arrow-tab.svg" />
                      </StyledButton>
                    </Box>
                  </Tab>
                )}
              </TabList>
              {options && activeOption && setActiveOption && (
                <Box
                  mb={20}
                  position="absolute"
                  bottom="-4px"
                  width="100%"
                  transform="translateY(100%)"
                  zIndex={2}
                  px={15}
                >
                  <Dropdown
                    options={options}
                    activeOption={activeOption}
                    setActiveOption={setActiveOption}
                    borderRight={borderRight}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <TabList
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {titles && (
                <Tab style={{ position: 'relative', boxShadow: 'none' }}>
                  <Typography variant="h3">{titles[tabIndex]}</Typography>
                  <Box
                    position="absolute"
                    left="-20px"
                    top="50%"
                    transform="translate(-100%, -50%)"
                    onClick={onPrevClick}
                  >
                    <Typography>prev</Typography>
                  </Box>
                  <Box
                    position="absolute"
                    right="-20px"
                    top="50%"
                    transform="translate(100%, -50%)"
                    onClick={onNextClick}
                  >
                    <Typography>next</Typography>
                  </Box>
                </Tab>
              )}
            </TabList>
          )}
          {titles?.map((el) => (
            <TabPanel key={el}>{children}</TabPanel>
          ))}
        </Tabs>
      ) : (
        <Tabs selectedIndex={tabIndex} onSelect={onSelect} key="tabs-mobile">
          {dropdown ? (
            <Box display="flex" alignItems="flex-end" position="relative">
              <TabList style={{ width: '100%', marginRight: 133 }}>
                {titles?.map((el) => (
                  <Tab key={el}>
                    <Typography variant="h3">{el}</Typography>
                  </Tab>
                ))}
              </TabList>
              {options && activeOption && setActiveOption && (
                <Box
                  mb={20}
                  position={{ tablet: 'absolute' }}
                  right={{ tablet: 15 }}
                  bottom={{ tablet: 0 }}
                >
                  <Dropdown
                    options={options}
                    activeOption={activeOption}
                    setActiveOption={setActiveOption}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <TabList>
              {titles?.map((el) => (
                <Tab key={el}>
                  <Typography variant="h3">{el}</Typography>
                </Tab>
              ))}
            </TabList>
          )}
          {titles?.map((el) => (
            <TabPanel key={el}>{children}</TabPanel>
          ))}
        </Tabs>
      )}
    </TabsContainer>
  );
};
