import { Box, Typography } from 'components';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled, { css } from 'styled-components';

export interface IProps {
  rounded?: 'tr' | 'tl';
  title?: string;
  height?: string | number;
}

const StyledThumbs = styled.div`
  ${({ theme }) => css`
    height: 100%;
    & .simplebar-scrollbar::before {
      background-color: ${theme.palette.main.primary};
      width: 2px;
      left: unset !important;
      right: 2px !important;
      opacity: 1 !important;
    }
  `}
`;

export const ScrollBox: React.FC<IProps> = ({
  rounded,
  title,
  height = 300,
  children,
}) => {
  return (
    <Box height={height}>
      <Box
        backgroundColor="main.primary"
        borderTopLeftRadius={rounded === 'tl' ? '16px' : undefined}
        borderTopRightRadius={rounded === 'tr' ? '16px' : undefined}
        height="48px"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        pl={3}
      >
        <Typography variant="caption" color="neutral.white">
          {title}
        </Typography>
      </Box>
      <Box
        border="1px solid"
        borderColor="neutral.gray"
        borderTop="none"
        height="calc(100% - 48px)"
      >
        <StyledThumbs>
          <SimpleBar style={{ height: '100%' }} autoHide={false}>
            <Box px={2}>{children}</Box>
          </SimpleBar>
        </StyledThumbs>
      </Box>
    </Box>
  );
};
