import React, { useRef } from 'react';
import styled from 'styled-components';
import { Box, Slider, Typography, Link } from 'components';
import { MobileContext } from 'utils/mobileContext';
import { ISchedule } from 'views/schedule/Schedule';
import { ScheduleItem, ScheduleItemSingle } from 'models';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { convertTime12to24 } from 'utils/date';
import { schedulePrograms } from './consts';

const hexToRgbA = (hex: string, alpha: number) => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

interface IHeader {
  bgColor: string;
}

const ScheduleWrapper = styled.section`
  margin-top: ${({ theme }) => `-${theme.space[3]}px`};
  .swiper-button-prev,
  .swiper-button-next {
    top: 114px;
    transform: ${({ theme }) => `translateY(-${theme.space[3] + 25}px)`};
    background-color: ${({ theme }) => theme.palette.neutral.white};
  }

  .swiper-button-prev {
    left: 9px;
    right: auto;
  }
  .swiper-button-next {
    right: 9px;
  }
`;

const ProgramHeader = styled.a<IHeader>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
  padding: 15px 0;
  width: 100%;
  max-height: 50px;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }
`;

const Hoverable = styled.div<IHeader>`
  &:hover > div {
    transition: 0.2s;
    background-color: ${({ bgColor }) => bgColor} !important;
    & > * {
      transition: 0.2s;
      color: black !important;
    }
  }
`;

interface IProps extends ISchedule {
  highlight?: string | string[];
  currentStation?: string | string[];
}

export const ScheduleList: React.FC<IProps> = ({
  schedule,
  highlight,
  currentStation,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  const scrollRef = useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [scrollRef]);
  const findFirstHighlight = (list: ScheduleItemSingle[]) =>
    list?.findIndex(({ title }) => title === highlight);
  const getSchedulesByTitle = (title: string): ScheduleItem =>
    schedule?.find(
      ({ station }) => station.toLowerCase() === title.toLocaleLowerCase()
    ) as ScheduleItem;

  const getScrollRef = (
    name: string,
    isOnAir: boolean,
    scheduleIndex: number
  ) => {
    const isCurrentStationRef = currentStation === name && isOnAir && scrollRef;
    const isSelectedDateRef =
      findFirstHighlight(getSchedulesByTitle(name)?.schedules) ===
        scheduleIndex && !scrollRef.current;

    return isCurrentStationRef || isSelectedDateRef ? scrollRef : undefined;
  };

  const linksConfig = [
    { label: 'JEDYNKA', url: '/audycje/' },
    { label: 'DWÓJKA', url: 'https://www.polskieradio.pl/8/' },
    { label: 'TRÓJKA', url: 'https://trojka.polskieradio.pl/audycja/' },
    { label: 'CZWÓRKA', url: 'https://www.polskieradio.pl/10/' },
    { label: 'PR24', url: 'https://www.polskieradio24.pl/130/' },
    { label: 'RADIO CHOPIN', url: 'https://www.polskieradio.pl/326/' },
    { label: 'RADIO DZIECIOM', url: 'https://www.polskieradio.pl/18/' },
    { label: 'RADIO POLAND', url: 'https://www.polskieradio.pl/395/' },
    {
      label: 'POLISH RADIO EXTERNAL SERVICE DAB+',
      url: 'https://www.polskieradio.pl/395/',
    },
    { label: 'EXTERNAL WSCHÓD', url: 'https://www.polskieradio.pl/395/' },
  ];

  const getStationLink = (name: string, categoryId: number) =>
    `${
      linksConfig.find((element) => element.label === name)?.url
    }${categoryId}`;
  return (
    <ScheduleWrapper>
      <Slider
        key="schedule"
        slides={isMobile ? 1 : 4}
        data={schedulePrograms}
        spaceBetween={4}
        disableSwipe
        paddingLeft={0}
        paddingRight={0}
        navigation
        autoHeight
        render={({ name, color, href, invertTextColor }) => (
          <>
            <ProgramHeader href={href} bgColor={color}>
              <Typography
                variant="scheduleTitle"
                color={!invertTextColor ? 'neutral.white' : 'nautral.dark'}
              >
                {name}
              </Typography>
            </ProgramHeader>
            <Box
              key={name}
              style={
                isMobile ? undefined : { height: '700px', overflow: 'auto' }
              }
            >
              {isMobile ? (
                getSchedulesByTitle(name)?.schedules?.map(
                  (
                    { title, startTime, isOnAir, categoryId },
                    scheduleIndex
                  ) => {
                    return categoryId ? (
                      <Link
                        href={getStationLink(name, categoryId)}
                        newCard={name !== 'JEDYNKA'}
                      >
                        <Box
                          display="grid"
                          gridTemplateColumns="1fr 5fr"
                          ref={getScrollRef(name, isOnAir, scheduleIndex)}
                          p={3}
                          backgroundColor={
                            isOnAir ? 'neutral.dark' : 'neutral.light'
                          }
                          borderBottom="1px solid"
                          border={highlight === `${title}` ? '3px solid' : ''}
                          borderColor={
                            highlight === `${title}`
                              ? 'main.primary'
                              : 'neutral.lightGray'
                          }
                          borderRadius={highlight === `${title}` ? '5px' : 0}
                        >
                          <Typography
                            variant="scheduleAudition"
                            color={isOnAir ? 'neutral.white' : 'neutral.dark'}
                          >
                            {convertTime12to24(startTime)}
                          </Typography>
                          <Typography
                            variant="scheduleAudition"
                            color={isOnAir ? 'neutral.white' : 'neutral.dark'}
                          >
                            {title}
                          </Typography>
                        </Box>
                      </Link>
                    ) : (
                      <Box
                        display="grid"
                        gridTemplateColumns="1fr 5fr"
                        ref={getScrollRef(name, isOnAir, scheduleIndex)}
                        p={3}
                        backgroundColor={
                          isOnAir ? 'neutral.dark' : 'neutral.light'
                        }
                        borderBottom="1px solid"
                        border={highlight === `${title}` ? '3px solid' : ''}
                        borderColor={
                          highlight === `${title}`
                            ? 'main.primary'
                            : 'neutral.lightGray'
                        }
                        borderRadius={highlight === `${title}` ? '5px' : 0}
                      >
                        <Typography
                          variant="scheduleAudition"
                          color={isOnAir ? 'neutral.white' : 'neutral.dark'}
                        >
                          {convertTime12to24(startTime)}
                        </Typography>
                        <Typography
                          variant="scheduleAudition"
                          color={isOnAir ? 'neutral.white' : 'neutral.dark'}
                        >
                          {title}
                        </Typography>
                      </Box>
                    );
                  }
                )
              ) : (
                <SimpleBar style={{ height: '100%' }} autoHide={false}>
                  {getSchedulesByTitle(name)?.schedules?.map(
                    (
                      { title, startTime, isOnAir, categoryId },
                      scheduleIndex
                    ) => {
                      return categoryId ? (
                        <Link
                          href={getStationLink(name, categoryId)}
                          newCard={name !== 'JEDYNKA'}
                        >
                          <Hoverable bgColor={hexToRgbA(color, 0.5)}>
                            <Box
                              display="grid"
                              gridTemplateColumns="1fr 5fr"
                              gridGap={1}
                              ref={getScrollRef(name, isOnAir, scheduleIndex)}
                              p={3}
                              backgroundColor={
                                isOnAir ? 'neutral.dark' : 'neutral.light'
                              }
                              borderBottom="1px solid"
                              border={
                                highlight === `${title}` ? '3px solid' : ''
                              }
                              borderColor={
                                highlight === `${title}`
                                  ? 'main.primary'
                                  : 'neutral.lightGray'
                              }
                              borderRadius={
                                highlight === `${title}` ? '5px' : 0
                              }
                            >
                              <Typography
                                variant="scheduleAudition"
                                color={
                                  isOnAir ? 'neutral.white' : 'neutral.dark'
                                }
                              >
                                {convertTime12to24(startTime)}
                              </Typography>
                              <Typography
                                variant="scheduleAudition"
                                color={
                                  isOnAir ? 'neutral.white' : 'neutral.dark'
                                }
                              >
                                {title}
                              </Typography>
                            </Box>
                          </Hoverable>
                        </Link>
                      ) : (
                        <Box
                          display="grid"
                          gridTemplateColumns="1fr 5fr"
                          gridGap={1}
                          ref={getScrollRef(name, isOnAir, scheduleIndex)}
                          p={3}
                          backgroundColor={
                            isOnAir ? 'neutral.dark' : 'neutral.light'
                          }
                          borderBottom="1px solid"
                          border={highlight === `${title}` ? '3px solid' : ''}
                          borderColor={
                            highlight === `${title}`
                              ? 'main.primary'
                              : 'neutral.lightGray'
                          }
                          borderRadius={highlight === `${title}` ? '5px' : 0}
                        >
                          <Typography
                            variant="scheduleAudition"
                            color={isOnAir ? 'neutral.white' : 'neutral.dark'}
                          >
                            {convertTime12to24(startTime)}
                          </Typography>
                          <Typography
                            variant="scheduleAudition"
                            color={isOnAir ? 'neutral.white' : 'neutral.dark'}
                          >
                            {title}
                          </Typography>
                        </Box>
                      );
                    }
                  )}
                </SimpleBar>
              )}
            </Box>
          </>
        )}
      />
    </ScheduleWrapper>
  );
};
