/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box } from 'components';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import Slider from 'react-slider';
import theme from 'theme';

interface IVolumeControls {
  volume: number;
  setVolume: (x: number) => void;
  isMuted: boolean;
  setIsMuted: (x: boolean) => void;
}

const StyledSlider = styled(Slider)`
  ${({ theme }) => css`
    height: 100%;
    width: 16px;
    .vc_track {
      height: 100%;
      width: 2px;
      background-color: ${theme.palette.neutral.placeholder};
    }
    .thumb {
      background-color: ${theme.palette.neutral.dark};
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: translate3d(-4.8px, 0, 0);
    }
  `}
`;

const VolumeBox = styled.div`
  svg * {
    fill: ${theme.palette.neutral.dark};
  }
  height: 17px;
`;

const PlayerVolumeControls: React.FC<IVolumeControls> = ({
  volume,
  setVolume,
  isMuted,
  setIsMuted,
}) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [isDragged, setDragged] = React.useState<boolean>(false);

  return (
    <Box
      width={60}
      height="60px"
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
      onMouseLeave={() => !isDragged && setIsVisible(false)}
    >
      <Box
        color="neutral.gray"
        cursor="pointer"
        display="flex"
        alignItems="center"
        height="100%"
      >
        {isVisible ? (
          <StyledSlider
            value={volume}
            orientation="vertical"
            trackClassName="vc_track"
            onChange={(val) => {
              if (isMuted) setIsMuted(false);
              setDragged(true);
              setVolume(val as number);
            }}
            onAfterChange={() => {
              setIsVisible(false);
              setDragged(false);
            }}
            min={0}
            max={1}
            step={0.1}
            invert
          />
        ) : (
          <VolumeBox onMouseOver={() => setIsVisible(true)}>
            <SVG src="/icons/volume.svg" />
          </VolumeBox>
        )}
      </Box>
    </Box>
  );
};

export default PlayerVolumeControls;
