/* eslint-disable import/no-anonymous-default-export */

export const paletteLight = {
  main: {
    primary: '#F68E1E',
    secondary: '#BE0C05',
    tertiary: '#305cb4;',
    recommended: '#6c63ff',
  },
  neutral: {
    dark: '#242424',
    light: '#F5F5F5',
    white: '#FFFFFF',
    gray: '#D4D4D4',
    lightGray: '#e3e3e3',
    mediumLightGray: '#ededed',
    mediumGray: '#eaeaea',
    lighterGray: '#444',
    grayWithOpacity: 'rgba(36, 36, 36, 0.15)',
    placeholder: 'rgba(36, 36, 36, 0.5)',
    playerGray: '#bdbdbd',
  },
};

export const paletteDark = {
  main: {
    primary: '#F68E1E',
    secondary: '#BE0C05',
    tertiary: '#305cb4;',
    recommended: '#6c63ff',
  },
  neutral: {
    dark: '#f68e1e',
    light: '#000000',
    white: '#000000',
    gray: '#D4D4D4',
    lightGray: '#000000',
    mediumLightGray: '#ededed',
    mediumGray: '#eaeaea',
    lighterGray: '#444',
    grayWithOpacity: 'rgba(36, 36, 36, 0.15)',
    placeholder: 'rgba(36, 36, 36, 0.5)',
  },
};

const theme = {
  fontSizes: [12, 14, 16, 20, 24, 32],
  fluidFontSizes: ['0.5rem', '1rem', '1.5rem', '2rem', '2.5rem', '3rem'],
  space: [0, 4, 8, 16, 32, 64, 128, 232],
  breakpoints: {
    largeDesktop: '1440px',
    desktop: '1200px',
    tablet: '1025px',
    mobile: '0px',
  },
  palette: paletteLight,
  textStyles: {
    labelBig: {
      fontSize: 18,
      fontWeight: 700,
      fontFamily: 'Montserrat',
    },
    labelSmall: {
      fontSize: 9,
      fontWeight: 700,
      fontFamily: 'Montserrat',
    },
    avatarCaption: {
      fontSize: 24,
      fontWeight: 700,
      fontFamily: 'Montserrat',
      transition: '0.3s',
    },
    sectionTitle: {
      fontSize: 17,
      fontWeight: 700,
      fontFamily: 'Montserrat',
      textTransform: 'uppercase',
    },
    sectionTitleShadow: {
      fontSize: 113,
      fontWeight: 700,
      fontFamily: 'Montserrat',
      textTransform: 'uppercase',
      lineHeight: 0.72,
      opacity: 0.05,
      whiteSpace: 'nowrap',
    },
    sectionTitleShadowMobile: {
      fontSize: 50,
      fontWeight: 700,
      fontFamily: 'Montserrat',
      textTransform: 'uppercase',
      lineHeight: 0.72,
      opacity: 0.05,
    },
    showDuration: {
      fontSize: 10,
      fontWeight: 500,
      fontFamily: 'Montserrat',
      letterSpacing: 0.1,
    },
    detailsSmall: {
      fontSize: 9,
      fontWeight: 500,
      fontFamily: 'Montserrat',
      letterSpacing: '0.09px',
    },
    recordCaption: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: '0.2px',
      lineHeight: '20px',
    },
    recordCaption2: {
      fontSize: 17,
      fontWeight: 700,
      letterSpacing: '0.2px',
      lineHeight: '20px',
    },
    header1: {
      fontSize: 24,
      fontWeight: 700,
      fontFamily: 'Montserrat',
    },
    header1mobile: {
      fontSize: 15,
      lineHeight: '24px',
      fontWeight: 700,
      fontFamily: 'Roboto',
    },
    lead: {
      fontSize: 16,
      fontWeight: 700,
      fontFamily: 'Montserrat',
      lineHeight: '20px',
    },
    leadMobile: {
      fontSize: 12,
      fontWeight: 700,
      fontFamily: 'Montserrat',
      lineHeight: '17px',
    },
    lead2: {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: 'Montserrat',
      lineHeight: '28px',
    },
    lead3: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '28px',
    },
    lead4: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: 22,
      lineHeight: '30px',
    },
    caption3: {
      fontSize: 11,
      fontWeight: 700,
    },
    caption2: {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 700,
    },
    caption: {
      fontSize: 14,
      fontWeight: 700,
      fontFamily: 'Montserrat',
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
      fontFamily: 'Montserrat',
      textTransform: 'uppercase',
    },
    listCaption2: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: '0.6px',
    },
    listCaption: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '16px',
    },
    onAir: {
      fontSize: 13,
      fontWeight: 500,
      lineHeight: '16px',
      fontFamily: 'Roboto',
    },
    readAlsoMobile: {
      fontSize: 13,
      fontWeight: 500,
      lineHeight: '20px',
    },
    onAirDate: {
      fontSize: 10,
      fontWeight: 700,
      letterSpacing: '0.6px',
    },
    onAirCategory: {
      fontSize: 10,
      fontWeight: 700,
      lineHeight: '20px',
      fontFamily: 'Roboto',
    },
    playlistItemTime: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '16px',
      fontFamily: 'Roboto',
    },
    playlistItemTitle: {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: 'Roboto',
    },
    playlistItemTimeMobile: {
      fontSize: 10,
      fontWeight: 700,
      lineHeight: '16px',
      fontFamily: 'Roboto',
    },
    playlistItemTitleMobile: {
      fontSize: 12,
      fontWeight: 700,
      fontFamily: 'Roboto',
    },
    date: {
      fontSize: 12,
      fontWeight: 600,
    },
    body3: {
      fontSize: 12,
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
    },
    body: {
      fontSize: 16,
    },
    h3: {
      fontSize: 20,
      lineHeight: '20px',
      fontWeight: 700,
    },
    h2: {
      fontSize: 26,
      fontWeight: 700,
    },
    h1: {
      fontSize: 32,
      lineHeight: '28px',
      fontWeight: 700,
    },
    channelLink: {
      fontFamily: 'Fira Sans',
      fontSize: 13,
      fontWeight: 700,
    },
    input: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '30px',
    },
    headerDropdownItem: {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 700,
      lineHeight: '19px',
    },

    articleDetails: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '18px',
      opacity: 0.8,
    },
    showDetailsTitle: {
      fontSize: 41,
      fontWeight: 700,
    },
    showDetailsTitleMobile: {
      fontSize: 18,
      fontWeight: 700,
    },
    showDetailsNextEpisode: {
      fontSize: 22,
      fontWeight: 700,
      lineHeight: 2,
    },
    playerTitle: {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: '-0.16px',
      lineHeight: '23px',
    },
    playerHour: {
      fontSize: 12,
      fontWeight: 600,
    },
    playerArtist: {
      fontSize: 13,
      fontWeight: 700,
      lineHeight: '16px',
    },
    error: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '18px',
    },
    showDetailsNextEpisodeMobile: {
      fontSize: 10,
      fontWeight: 700,
      lineHeight: 1,
    },
    bodyMobile: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: '24px',
    },
    scheduleTitle: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '20px',
    },
    scheduleAudition: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '18px',
    },
    titleSideText: {
      fontSize: 50,
      fontWeight: 500,
      lineHeight: '30px',
    },
    playlistTitle: {
      fontSize: 30,
      fontWeight: 500,
      lineHeight: '36px',
    },
    galleryTitle: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '36px',
    },
    galleryTitleMobile: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '36px',
      textAlign: 'center',
      paddingTop: 16,
    },
    playlistTitleMobile: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '36px',
    },
    broadcastTime: {
      fontSize: 10,
      fontWeight: 500,
      letterSpacing: '0.5px',
    },
    bookTitle: {
      fontSize: 22,
      fontWeight: 700,
    },
    bookTitleSmall: {
      fontSize: 14,
      fontWeight: 700,
    },
    bookReading: {
      fontSize: 10,
      fontWeight: 500,
      letterSpacing: '0.12px',
    },
    bookLector: {
      fontSize: 11,
      fontWeight: 500,
      letterSpacing: '0.12px',
    },
    bookEpisodes: {
      fontSize: 15,
      fontWeight: 700,
      letterSpacing: '0.15px',
    },
    listContent: {
      fontFamily: 'Roboto',
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 400,
    },
    errorPage: {
      fontSize: 18,
      lineHeight: '26px',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    breadcrumbsMobileArticle: {
      fontSize: 8,
      fontWeight: 700,
    },
    liveTickerTimestamp: {
      fontSize: 10,
      fontWeight: 600,
    },
  },
  headerHeight: '184px',
};

export type ThemeType = typeof theme;
export default theme;
