import { Box, SectionTitle, ArticleBox, ArticleMobileBox } from 'components';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { ArticleItem } from 'models';
import { MobileContext } from 'utils/mobileContext';

export const Mosaic: React.FC<{ content: ArticleItem[] }> = ({ content }) => {
  const { isMobile } = React.useContext(MobileContext);
  if (!content) return null;

  return (
    <Box p={3} backgroundColor="neutral.white" height="100%">
      <Box mx="15px" borderBottom="1px solid" borderColor="neutral.mediumGray">
        <SectionTitle
          fontSize={isMobile ? 3 : 4}
          borderWidth={5}
          borderColor="main.primary"
        >
          Zobacz także
        </SectionTitle>
      </Box>
      <Box pt={20}>
        {isMobile ? (
          <Container fluid md>
            <Row>
              <Col xs={12}>
                <Box>
                  <ArticleBox article={content[0]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={content[1]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={content[2]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={content[3]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box>
                  <ArticleMobileBox article={content[4]} />
                </Box>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container fluid md>
            <Row style={{ marginBottom: '25px' }}>
              <Col xs={6}>
                <Box>
                  <ArticleBox article={content[0]} />
                </Box>
              </Col>
              <Col xs={6}>
                <Box>
                  <ArticleBox article={content[1]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <Box pb={3}>
                  <ArticleBox article={content[2]} />
                </Box>
              </Col>
              <Col xs={4}>
                <Box pb={3}>
                  <ArticleBox article={content[3]} />
                </Box>
              </Col>
              <Col xs={4}>
                <Box pb={3}>
                  <ArticleBox article={content[4]} />
                </Box>
              </Col>
            </Row>
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default Mosaic;
