import { Box, TabMosaic } from 'components';
import { noPadding } from 'helpers';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { BoxInstanceId } from 'models';
import { MobileContext } from 'utils/mobileContext';
import { ITab } from '../NewsSection/NewsSection';

export interface IMosaicTab {
  tabName: string;
  options: ITab[];
}

export const TabMosaicSection: React.FC<{
  tabs: IMosaicTab[];
  readBooks: BoxInstanceId[];
  currentReadBooks: BoxInstanceId[];
}> = ({ tabs, readBooks, currentReadBooks }) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <section>
      <Box py={5} backgroundColor="neutral.light">
        <Container fluid md style={isMobile ? noPadding : {}}>
          <Row>
            <Col xs={12} style={isMobile ? noPadding : {}}>
              <TabMosaic
                tabs={tabs}
                readBooks={readBooks}
                currentReadBooks={currentReadBooks}
              />
            </Col>
          </Row>
        </Container>
      </Box>
    </section>
  );
};

export default TabMosaicSection;
