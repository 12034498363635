import { Box, ImageLink } from 'components';
import { Row, Col } from 'react-grid-system';
import igOff from 'public/icons/instagram_inactive.svg';
import igOffFooter from 'public/icons/instagram_inactive_footer.svg';
import igOn from 'public/icons/instagram_active.svg';
import igAlt from 'public/icons/ig_alt.svg';
import ttOff from 'public/icons/x_inactive.png';
import ttOffFooter from 'public/icons/x_inactive.svg';
import ttOn from 'public/icons/x_active.png';
import ttAlt from 'public/icons/tt_alt.svg';
import fbOff from 'public/icons/facebook_inactive.svg';
import fbOffFooter from 'public/icons/facebook_inactive_footer.svg';
import fbOn from 'public/icons/facebook_active.svg';
import fbAlt from 'public/icons/fb_alt.svg';
import ytOff from 'public/icons/youtube_inactive.svg';
import ytOffFooter from 'public/icons/youtube_inactive_footer.svg';
import ytOn from 'public/icons/youtube_active.svg';
import ytAlt from 'public/icons/yt_alt.svg';
import tikOff from 'public/icons/TikTok_icon.svg';
import tikFooterOff from 'public/icons/TikTok_icon_footer.svg';

import tikOn from 'public/icons/TikTok.svg';
import tikFooterOn from 'public/icons/TikTokOn_footer.svg';

import React from 'react';
import { MobileContext } from 'utils/mobileContext';

interface ISocials {
  alt?: boolean;
  isFooter?: boolean;
}

const setAlt = (
  bool: boolean | undefined,
  base: StaticImageData,
  alt: StaticImageData
) => (!bool ? alt : base);

export const Socials: React.FC<ISocials> = ({ alt, isFooter }) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <Box py={isMobile ? 0 : 10} style={{ height: '100%' }}>
      <Row
        style={{ height: '100%' }}
        gutterWidth={6}
        justify="center"
        align="center"
      >
        <Col xs={2.4} style={{ height: '100%' }}>
          <ImageLink
            href="https://www.tiktok.com/@jedynka_polskieradio"
            imgOff={setAlt(alt, ytAlt, isFooter ? tikFooterOff : tikOff)}
            imgOn={setAlt(alt, ytAlt, isFooter ? tikFooterOn : tikOn)}
            alt="TikTok"
          />
        </Col>
        <Col xs={2.4} style={{ height: '100%' }}>
          <ImageLink
            href="https://www.instagram.com/jedynka_polskieradio/?hl=pl"
            imgOff={setAlt(alt, igAlt, isFooter ? igOffFooter : igOff)}
            imgOn={setAlt(alt, igAlt, igOn)}
            alt="Instagram"
          />
        </Col>
        <Col xs={2.4} style={{ height: '100%' }}>
          <ImageLink
            href="https://twitter.com/RadiowaJedynka"
            imgOff={setAlt(alt, ttAlt, isFooter ? ttOffFooter : ttOff)}
            imgOn={setAlt(alt, ttAlt, ttOn)}
            alt="Twitter"
          />
        </Col>
        <Col xs={2.4} style={{ height: '100%' }}>
          <ImageLink
            href="https://www.facebook.com/jedynka"
            imgOff={setAlt(alt, fbAlt, isFooter ? fbOffFooter : fbOff)}
            imgOn={setAlt(alt, fbAlt, fbOn)}
            alt="Facebook"
          />
        </Col>
        <Col xs={2.4} style={{ height: '100%' }}>
          <ImageLink
            href="https://www.youtube.com/channel/UCCWEiWQWpi7RUVZxzxTNtIA"
            imgOff={setAlt(alt, ytAlt, isFooter ? ytOffFooter : ytOff)}
            imgOn={setAlt(alt, ytAlt, ytOn)}
            alt="Youtube"
          />
        </Col>
      </Row>
    </Box>
  );
};
