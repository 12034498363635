import Image from 'next/image';
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import useHover from 'utils/useHover';

export interface IProps {
  imgOff: string | StaticImageData;
  imgOn?: string | StaticImageData;
  alt?: string;
}

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;

export const HoverableImage: React.FC<IProps> = ({ imgOff, imgOn, alt }) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  return (
    <Container ref={hoverRef}>
      <AnimatePresence>
        {isHover && imgOn && (
          <motion.div
            key="on"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            style={{ position: 'absolute', height: '100%', width: '100%' }}
          >
            <Image
              src={imgOn}
              alt={alt}
              layout="fill"
              objectFit="contain"
              height="100%"
            />
          </motion.div>
        )}

        {(!isHover || !imgOn) && (
          <motion.div
            key="off"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            style={{ position: 'absolute', height: '100%', width: '100%' }}
          >
            <Image
              src={imgOff}
              alt={alt}
              layout="fill"
              objectFit="contain"
              height="100%"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
};
