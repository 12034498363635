import {
  Box,
  SectionTitle,
  ArticleBox,
  ArticleMobileBox,
  Slider,
} from 'components';
import theme from 'theme';
import { noPadding } from 'helpers';
import { ArticleItem } from 'models';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { MobileContext } from 'utils/mobileContext';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: -32px;

  div.swiper-button-prev,
  div.swiper-button-next {
    top: 50%;
    transform: translate(0, -100%);
    bottom: 0;
    display: none;
    background-color: white;
    border: none;
    @media screen and (min-width: 768px) {
      width: ${({ theme }) => `${theme.space[5]}`}px;
      height: ${({ theme }) => `${theme.space[5]}`}px;
      display: flex;
      &::after {
        font-size: ${({ theme }) => `${theme.fluidFontSizes[3]}`};
      }
    }
  }
  div.swiper-button-prev {
    @media screen and (min-width: 768px) {
      left: 0;
    }
  }
  div.swiper-button-next {
    @media screen and (min-width: 768px) {
      right: 0;
    }
  }
  .swiper-pagination {
    @media screen and (min-width: 768px) {
      bottom: 15%;
    }
  }
  .swiper-pagination-bullet {
    background-color: white;
    width: 17px;
    height: 17px;
  }
  .swiper-pagination-bullet-active {
    background-color: #f68e1e;
  }
  div.swiper {
    padding: 0;
    .swiper-pagination div:last-of-type {
      width: auto;
    }
    overflow: visible;
    @media screen and (min-width: 768px) {
      overflow: hidden;
    }
  }
  .swiper-slide-next {
    overflow: visible;
    @media screen and (min-width: 768px) {
      overflow: hidden;
    }
  }
  .swiper-slide-active {
    overflow: visible;
    @media screen and (min-width: 768px) {
      overflow: hidden;
    }
  }
`;

export const LatestMosaic: React.FC<{ content: ArticleItem[] }> = ({
  content,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  if (!content) return null;
  const sliderContent = [...content.slice(0, content.length - 2)];
  return (
    <Box p={3} backgroundColor="neutral.mediumGray" height="100%">
      <Box pl={isMobile ? 0 : 3}>
        <SectionTitle
          decorator
          decoratorHeight={isMobile ? '15px' : '19px'}
          decoratorColor="main.primary"
          fontSize={isMobile ? 3 : 4}
        >
          jedynka poleca
        </SectionTitle>
      </Box>
      <Box pt={20}>
        {isMobile ? (
          <Container
            key="mobilemosaic"
            fluid
            md
            style={isMobile ? noPadding : {}}
          >
            <Row>
              <Col xs={12}>
                <Box>
                  <ArticleBox article={content[0]} isMosaic />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={content[1]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={content[2]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box
                  borderBottom="1px solid"
                  borderColor="neutral.grayWithOpacity"
                >
                  <ArticleMobileBox article={content[3]} />
                </Box>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Box>
                  <ArticleMobileBox article={content[4]} />
                </Box>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container key="desktopmosaic" fluid md>
            <Row>
              <Col xs={12}>
                <Wrapper>
                  <Slider
                    data={sliderContent}
                    navigation={sliderContent.length > 1}
                    pagination
                    centered
                    paddingLeft={0}
                    autoplay={5000}
                    paddingRight={0}
                    paddingContainer={0}
                    slides={1}
                    // loop={true}
                    spaceBetween={theme.space[2]}
                    render={(elem) => {
                      return <ArticleBox article={elem} isMosaic />;
                    }}
                  />
                </Wrapper>
                {/* <Box pb={20}>
                  <ArticleBox ultrawide article={content[0]} />
                </Box> */}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Box>
                  <ArticleBox article={content[content.length - 2]} isMosaic />
                </Box>
              </Col>
              <Col xs={6}>
                <Box>
                  <ArticleBox article={content[content.length - 1]} isMosaic />
                </Box>
              </Col>
            </Row>
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default LatestMosaic;
