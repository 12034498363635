/* eslint-disable object-curly-newline */
import { RoundedInput, Box, Link } from 'components';
import SVG from 'react-inlinesvg';
import React from 'react';
import { useRouter } from 'next/router';
import { v4 as uuid } from 'uuid';

export const SearchBar = () => {
  const [inputValue, setInputValue] = React.useState<string>('');
  const router = useRouter();

  const handlePress = (key: string) => {
    if (key === 'Enter' && inputValue.length !== 0) {
      router.push(`/wyszukiwanie/${inputValue}`);
    }
  };

  return (
    <Box position="relative" width="100%">
      <RoundedInput
        placeholder="Wyszukaj"
        id={uuid()}
        label="Wyszukaj"
        isSearch
        pLeft={2}
        pRight={5}
        borderColor="main.primary"
        onChange={setInputValue}
        inputValue={inputValue}
        onKeyPress={(event) => handlePress(event)}
      />
      <Box position="absolute" top="20%" right="20px">
        <Link
          href={`/wyszukiwanie/${inputValue}`}
          ariaLabel="Wyszukiwanie"
          style={{ pointerEvents: inputValue.length !== 0 ? 'auto' : 'none' }}
        >
          <SVG src="/icons/search.svg" />
        </Link>
      </Box>
    </Box>
  );
};

export default SearchBar;
