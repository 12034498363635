import { Box, Typography } from 'components';
import React, { RefObject } from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import Loader from 'react-loader-spinner';
import useHover from 'utils/useHover';
import themeConfig from 'theme';
import { MobileContext } from 'utils/mobileContext';

interface IProps {
  items: React.ReactElement[];
  onNext?: () => void;
  onPrev?: () => void;
  onChange?: (val: number) => void;
  pageCount?: number;
  page?: number;
  loading?: boolean;
  gridTemplateColumns?: string;
  py?: number | string;
  mx?: number | string;
  loaderMinHeight?: string;
  borderTop?: boolean;
  gridGap?: number;
}

interface IPaginationButton {
  direction: 'left' | 'right';
  onClick?: () => void;
}

const StyledButton = styled.button<{
  rotated: boolean;
  ref: RefObject<HTMLButtonElement>;
  isHovered: boolean;
}>(
  ({ theme, rotated, isHovered }) => css`
    display: flex;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    justify-content: center;
    width: 45px;
    height: 45px;
    align-items: center;
    background-color: ${theme.palette.neutral.lightGray};
    & svg polygon {
      transition: 0.3s;
      fill: ${isHovered
        ? theme.palette.main.primary
        : theme.palette.neutral.dark};
    }
    transform: ${rotated ? 'rotate(180deg)' : 'unset'};
    margin-right: ${rotated ? `${theme.space[3]}px` : 0};
    margin-left: ${rotated ? 0 : `${theme.space[3]}px`};
  `
);

const PaginationButton: React.FC<IPaginationButton> = ({
  direction,
  onClick,
}) => {
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>();
  const { isMobile } = React.useContext(MobileContext);

  return (
    <StyledButton
      ref={hoverRef}
      isHovered={isMobile ? false : isHovered}
      onClick={onClick}
      rotated={direction === 'left'}
    >
      <SVG src="/icons/arrow_right.svg" />
    </StyledButton>
  );
};

const StyledInput = styled.input(
  ({ theme }) => css`
    border: 1px solid;
    border-color: ${theme.palette.neutral.gray};
    border-radius: 7px;
    height: 45px;
    min-width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &:hover,
    &:focus {
      border-color: ${theme.palette.neutral.gray};
      outline: none;
    }
  `
);

export const PaginatedList: React.FC<IProps> = ({
  items,
  onPrev,
  onNext,
  onChange,
  pageCount,
  page = 1,
  loading = false,
  gridTemplateColumns = '1fr 1fr 1fr 1fr',
  py,
  mx,
  loaderMinHeight,
  borderTop = true,
  gridGap = 15,
}) => {
  const inputHandler = React.useCallback(
    (e) => {
      if (e.key === 'Enter' && !Number.isNaN(+e.currentTarget.value)) {
        if (onChange) onChange(+e.currentTarget.value);
      }
    },
    [onChange]
  );

  return (
    <>
      {loading ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          p={5}
          minHeight={loaderMinHeight || '50vh'}
          alignItems="center"
        >
          <Loader type="TailSpin" color={themeConfig.palette.main.primary} />
        </Box>
      ) : (
        <>
          <Box
            display="grid"
            gridGap={gridGap}
            gridTemplateColumns={gridTemplateColumns}
            py={py || 4}
            mx={mx || 0}
            width="100%"
          >
            {items.map((item) => (
              <Box
                key={item.key}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {item}
              </Box>
            ))}
          </Box>
        </>
      )}
      {pageCount && pageCount > 1 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          borderTop={borderTop ? '1px solid' : 'none'}
          borderColor="neutral.gray"
          pt={4}
        >
          <PaginationButton direction="left" onClick={onPrev} />
          <StyledInput
            key={page}
            defaultValue={page}
            type="text"
            size={1}
            onKeyDown={inputHandler}
          />
          <PaginationButton direction="right" onClick={onNext} />
          {pageCount && (
            <Typography as="span" variant="body" ml={2}>
              z {pageCount}
            </Typography>
          )}
        </Box>
      ) : null}
    </>
  );
};
