import { Box, Typography } from 'components';
import React from 'react';

export interface IProps {
  content: string;
  author?: string;
}

export const ArticleQuote: React.FC<IProps> = ({ content, author }) => {
  return (
    <Box position="relative" my={5} as="section">
      <Box
        pl={4}
        pr={3}
        py={3}
        position="relative"
        display="flex"
        flexDirection="column"
        border="5px solid"
        borderColor="main.primary"
        borderRadius="10px 10px 10px 0"
        width="100%"
      >
        <Box
          position="absolute"
          right={{ tablet: 2, mobile: 3 }}
          transform="translate(-10%, -50%)"
          backgroundColor="neutral.white"
          p={2}
        >
          <img src="/icons/quote_signs.png" alt="quotes" />
        </Box>

        <Box maxWidth={{ tablet: '85%', mobile: '75%' }} mb={3}>
          <Typography variant="header1">{content}</Typography>
        </Box>
        <Box alignSelf="flex-end">
          <Typography variant="recordCaption" as="h3">
            {author}
          </Typography>
        </Box>
      </Box>
      <Box position="relative">
        <Box
          width={0}
          height={0}
          borderTop="50px solid"
          borderTopColor="main.primary"
          borderRight="50px solid transparent"
          position="absolute"
        >
          <Box
            width={0}
            height={0}
            borderTop="45px solid"
            borderTopColor="neutral.white"
            borderRight="45px solid transparent"
            position="absolute"
            transform="translate(11%, -125%)"
          />
        </Box>
      </Box>
    </Box>
  );
};
