import { Box, Link, Typography } from 'components';
import React from 'react';
import { Col, Row } from 'react-grid-system';

interface IProps {
  startTime: string;
  title: string;
  isOnAir: boolean;
  href?: string;
}

export const ScheduleBoxItem: React.FC<IProps> = ({
  startTime,
  title,
  isOnAir,
  href,
}) => {
  return (
    <Box backgroundColor="neutral.light" p={2} mx="6px">
      <Row gutterWidth={20}>
        <Col xs={3}>
          <Box
            backgroundColor={isOnAir ? 'main.primary' : 'neutral.white'}
            p={1}
            display="flex"
            justifyContent="center"
          >
            <Typography
              variant="caption2"
              fontSize="11"
              opacity="0.8"
              color={isOnAir ? 'neutral.white' : 'neutral.dark'}
            >
              {startTime.slice(0, 5)}
            </Typography>
          </Box>
        </Col>
        <Col xs={9}>
          <Box display="flex" alignItems="center" height="100%">
            {href ? (
              <Link href={href}>
                <Typography
                  color={isOnAir ? 'main.primary' : 'neutral.dark'}
                  opacity="0.8"
                  variant="caption2"
                >
                  {title.toUpperCase()}
                </Typography>
              </Link>
            ) : (
              <Typography
                color={isOnAir ? 'main.primary' : 'neutral.dark'}
                opacity="0.8"
                variant="caption2"
              >
                {title.toUpperCase()}
              </Typography>
            )}
          </Box>
        </Col>
      </Row>
    </Box>
  );
};
