/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
import { Slider, ArticleBox, Box } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import { MobileContext } from 'utils/mobileContext';

export const ReportageSlider: React.FC<{ content: ArticleItem[] }> = ({
  content,
}) => {
  const { isDesktop, isMobile } = React.useContext(MobileContext);

  if (!content) return null;

  return (
    <>
      <Slider
        header="REPORTAŻE W JEDYNCE"
        headerSize={isDesktop ? 3 : 1}
        seeMore="/audycje/3040"
        seeMoreLeft={isDesktop ? '37%' : '50%'}
        paddingRight={!isDesktop ? 4 : 3}
        paddingLeft={isDesktop ? 3 : 4}
        paddingContainer={!isDesktop ? 1 : 0}
        slides={isMobile ? 1 : 2}
        navigation={isMobile}
        dark
        mobileScrollBar
        pagination
        reportage
        bgUrl="/images/spolecz_BG.jpg"
        data={content}
        render={(article) => (
          <Box
            height={!isDesktop ? 'auto' : 220}
            width={!isDesktop ? '100%' : undefined}
          >
            <ArticleBox
              article={article}
              decoratorBottom={false}
              color="neutral.white"
            />
          </Box>
        )}
      />
    </>
  );
};
