import { Box, Typography } from 'components';
import { ArticleDetails } from 'models';
import getReadingTime from 'utils/getReadingTime';
import React from 'react';
import SVG from 'react-inlinesvg';
import { MobileContext } from 'utils/mobileContext';

interface IProps {
  article: ArticleDetails | null;
}

export const ReadingTime: React.FC<IProps> = ({ article }) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <>
      {article?.content && (
        <Box display="flex" alignItems="center">
          {getReadingTime(article.content) !== '0 minut' && (
            <>
              <SVG src="/icons/clock.svg" />
              {isMobile ? (
                <Typography variant="labelSmall" color="neutral.dark" ml={2}>
                  {`Ten tekst przeczytasz w ${getReadingTime(article.content)}`}
                </Typography>
              ) : (
                <Typography
                  variant="articleDetails"
                  color="neutral.dark"
                  ml={2}
                >
                  {`Ten tekst przeczytasz w ${getReadingTime(article.content)}`}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ReadingTime;
