/* eslint-disable no-nested-ternary */
import { Box, SectionTitle, NewsListItem, ArticleBoxLatest } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import { MobileContext } from 'utils/mobileContext';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styled, { css } from 'styled-components';

const StyledThumbs = styled.div`
  ${({ theme }) => css`
    & .simplebar-scrollbar::before {
      background-color: ${theme.palette.main.primary};
      width: 2px;
      left: unset !important;
      right: 2px !important;
      opacity: 1 !important;
    }
  `}
`;

export const OnAirList: React.FC<{
  content: ArticleItem[];
  latestBox: ArticleItem;
}> = ({ content, latestBox }) => {
  const { isDesktop, isMobile } = React.useContext(MobileContext);
  return (
    <Box
      borderTopRightRadius="7px"
      backgroundColor="neutral.light"
      p={isDesktop ? 20 : isMobile ? 3 : 4}
      height="100%"
    >
      <SectionTitle
        borderWidth={1}
        borderColor="neutral.grayWithOpacity"
        decorator
        decoratorHeight="13px"
        decoratorTop="14%"
        decoratorColor="main.primary"
        fontSize={2}
      >
        na antenie
      </SectionTitle>
      <StyledThumbs>
        <SimpleBar style={{ maxHeight: latestBox ? 600 : 1000 }}>
          <Box py={3}>
            {content?.map((article, index) => {
              return (
                <NewsListItem
                  key={article.id}
                  onAir
                  href={`/artykul/${article?.id},${article?.title
                    .replace(/[.,:?!/"]/g, '')
                    .split(' ')
                    .join('-')}`}
                  image={index === 0 ? article.photo : null}
                  title={article.title}
                  date={
                    article?.customFields && article?.customFields[0]?.value
                  }
                  category={article.categoryName}
                  borderColor="transparent"
                />
              );
            })}
          </Box>
        </SimpleBar>
      </StyledThumbs>
      {latestBox && (
        <Box>
          <Box paddingBottom={3} paddingTop={4}>
            <SectionTitle
              borderWidth={1}
              borderColor="neutral.grayWithOpacity"
              decorator
              decoratorHeight="13px"
              decoratorTop="14%"
              decoratorColor="main.primary"
              fontSize={2}
            >
              {latestBox?.customFields?.filter(
                (x) => x.name === 'latestTitle'
              )[0]?.value || 'polecamy'}
            </SectionTitle>
          </Box>
          <ArticleBoxLatest article={latestBox} />
        </Box>
      )}
    </Box>
  );
};

export default OnAirList;
