export const DAY_TIMESTAMP = 24 * 60 * 60 * 1000;

export const formatDate = (number: number) =>
  number < 10 ? `0${number}` : number;

export const getDate = (d: Date) => {
  return d.getDate();
};

export const getNextDay = (d: Date) => {
  return new Date(d.getTime() + DAY_TIMESTAMP);
};

export const getPrevDay = (d: Date) => {
  return new Date(d.getTime() - DAY_TIMESTAMP);
};

export const getDayShortOfWeek = (d: Date) => {
  const weekday = Intl.DateTimeFormat('pl-PL', { weekday: 'short' }).format(d);
  return weekday.slice(0, weekday.length - 1);
};

export const getDateWithSiblings = (d: Date, eachSide: number) => {
  const dates: Date[] = [d];

  for (let i = 0; i < eachSide; i++) {
    const nextDay = new Date(d);
    const prevDay = new Date(d);
    nextDay.setDate(d.getDate() + 1 + i);
    prevDay.setDate(d.getDate() - 1 - i);
    dates.push(nextDay);
    dates.push(prevDay);
  }

  return dates.sort(
    (a: Date, b: Date) => Date.parse(String(a)) - Date.parse(String(b))
  );
};

export const getTodayDateString = (date = new Date()) => {
  return `${date.getDate()}.${formatDate(
    date.getMonth() + 1
  )}.${date.getFullYear()}`;
};

export const getTimeOfTheDay = (): string[] => {
  const timeArray = Array.from(Array(24).keys());

  return timeArray.map((num: number) =>
    String(num).length === 1 ? `0${num}` : String(num)
  );
};

export const getDateFromQuery = (timestamp: string | string[] | undefined) => {
  return timestamp ? new Date(Number(timestamp)) : new Date();
};

export const formatToDateTimeString = (date: string) => {
  const rawDate = new Date(date);
  const year = rawDate.getFullYear();
  const month = rawDate.getMonth() + 1;
  const day = rawDate.getDate();
  const hours = rawDate.getHours();
  const minutes = rawDate.getMinutes();

  return `${formatDate(day)}.${formatDate(month)}.${year} ${formatDate(
    hours
  )}:${formatDate(minutes)}`;
};

export const getTime = (date: Date) => {
  const strHours = String(date.getHours());
  const strMinutes = String(date.getMinutes());
  const hours = strHours.length === 1 ? `0${strHours}` : strHours;
  const minutes = strMinutes.length === 1 ? `0${strMinutes}` : strMinutes;

  return `${hours}:${minutes}`;
};

export const convertTime12to24 = (time12h: string) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (modifier === 'PM' || modifier === 'AM') {
    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      hours = parseInt(hours, 10) + 12;
    }
  }
  return `${hours}:${minutes}`;
};
