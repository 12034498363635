import { Box, Link, Typography } from 'components';
import { ArticleItem } from 'models';
import Image from 'next/image';
import useHover from 'utils/useHover';

export const ArticleMobileBox: React.FC<{
  article?: ArticleItem;
  promoArticle?: ArticleItem;
}> = ({ article, promoArticle }) => {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  // TODO: get rid of this.
  // eslint-disable-next-line no-param-reassign
  if (article && !article?.path) article.path = article.photo;
  return (
    <>
      {article && (
        <Link
          href={
            article?.url ||
            `/artykul/${article?.id},${article?.title
              .replace(/[.,:?!/"]/g, '')
              .split(' ')
              .join('-')}`
          }
        >
          <Box ref={hoverRef} display="flex" py={2}>
            <Box
              height={70}
              width={127}
              backgroundColor="#ddd"
              flexShrink={0}
              mr={3}
              position="relative"
            >
              <Image
                src={article.path || '/images/shareCard.jpeg'}
                alt={article.title}
                layout="fill"
                objectFit="cover"
              />
            </Box>

            <Typography
              variant="leadMobile"
              color={isHover ? 'main.primary' : 'neutral.dark'}
              transition="0.3s"
            >
              {article.title}
            </Typography>
          </Box>
        </Link>
      )}
      {promoArticle && (
        <Link
          href={
            promoArticle.customFields?.find((field) => field.name === 'link')
              ?.value || ''
          }
        >
          <Box ref={hoverRef} display="flex" py={2}>
            <Box
              height={70}
              width={127}
              backgroundColor="#ddd"
              flexShrink={0}
              mr={3}
              position="relative"
            >
              <Image
                src={
                  promoArticle?.attachments?.[0]?.file ||
                  '/images/shareCard.jpeg'
                }
                alt={
                  promoArticle.customFields?.find(
                    (field) => field.name === 'alt'
                  )?.value
                }
                layout="fill"
                objectFit="cover"
              />
            </Box>

            <Typography
              variant="leadMobile"
              color={isHover ? 'main.primary' : 'neutral.dark'}
              transition="0.3s"
            >
              {promoArticle.lead}
            </Typography>
          </Box>
        </Link>
      )}
    </>
  );
};
