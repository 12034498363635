const GrapReact = {
  async init() {
    if ('grapLoading' in window) return false;
    window.grapLoading = true;

    let ba = document.querySelector('body');

    if (
      typeof window.grapConfig.container === 'string' &&
      document.querySelectorAll(window.grapConfig.container).length === 0
    ) {
      let gc = document.createElement('div');
      gc.setAttribute('id', window.grapConfig.container.replace('#', ''));
      ba.append(gc);
    }

    let pa = document.createElement('script');
    pa.type = 'text/javascript';
    pa.async = !0;
    pa.src = `//rmfon.pl/work/grap/scripts/grap.js?cb=${Math.random()}`;
    ba.append(pa);
  },
  play() {
    if ('grapTrigger' in window) window.grapTrigger('run');
  },
};

export default GrapReact;
