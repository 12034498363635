import { Slider, RecordBox, Box, Link, Button } from 'components';
import React from 'react';
import { ArticleItem } from 'models';
import { MobileContext } from 'utils/mobileContext';
import SVG from 'react-inlinesvg';

export const RecommendedRecords: React.FC<{ content: ArticleItem[] }> = ({
  content,
}) => {
  const { isMobile } = React.useContext(MobileContext);

  if (!content) return null;
  return (
    <Box backgroundColor="neutral.mediumGray" px={3} py={26}>
      <Slider
        header="POLECANE PŁYTY"
        decorator
        decoratorTop={isMobile ? '24%' : '12%'}
        decoratorHeight={isMobile ? 11 : 13}
        slides={isMobile ? 2 : 3}
        headline
        navigation
        paddingLeft={1}
        paddingRight={-1}
        paddingContainer={1}
        records
        data={content}
        render={(record) => <RecordBox record={record} />}
      />
      <Box width={120} margin="0 auto">
        <Link href="/artykuly/4452">
          <Button
            color="main.primary"
            decorator={<SVG src="/icons/arrow_right.svg" />}
          >
            Więcej
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default RecommendedRecords;
