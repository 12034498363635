import { Track } from 'models';

interface ITrack {
  id?: number;
  title?: string;
  artist?: string;
  cover?: string;
  url: string;
  airTime?: string;
}

export const onPlaylistItemClick = (
  playlistItem: ITrack,
  playerContext: any
) => {
  playerContext.setPlayerContext({
    type: 'SET_PLAYER',
    payload: {
      track: playlistItem,
      isPlaying:
        playlistItem.url === playerContext?.player?.track?.url
          ? !playerContext.player.isPlaying
          : true,
    },
  });
};

export const deleteFromPlaylist = (
  playlistItem: ITrack,
  playerContext: any
) => {
  playerContext.setPlayerContext({
    type: 'SET_PLAYER',
    payload: {
      playlist: playerContext.player.playlist.filter((x: ITrack) => {
        return x.url !== playlistItem.url;
      }),
    },
  });
  localStorage.setItem(
    'playlist',
    JSON.stringify(
      playerContext.player.playlist.filter((x: ITrack) => {
        return x.url !== playlistItem.url;
      })
    )
  );
};

export const onEnded = (playerContext: any, track: Track) => {
  const getCurrentIndex = () => {
    return playerContext.player.playlist.findIndex(
      (el: ITrack) => el.url === track?.url
    );
  };
  const wasLastItem =
    getCurrentIndex() === playerContext.player.playlist.length - 1;
  if (playerContext.player.playlist.length && !wasLastItem) {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        track: playerContext.player.playlist[getCurrentIndex() + 1],
        isPlaying: true,
      },
    });
  } else {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        track: null,
        isPlaying: playerContext.player.wasStreamPlaying,
      },
    });
  }
};

export const onPlayClick = (playerContext: any, isStreamPlaying: boolean) => {
  playerContext.setPlayerContext({
    type: 'SET_PLAYER',
    payload: {
      isPlaying: !playerContext.player.isPlaying,
      wasStreamPlaying: !isStreamPlaying,
    },
  });
};

export const closePlayer = (
  playerContext: any,
  setIsPlaylistOpen: (value: boolean) => void
) => {
  playerContext.setPlayerContext({
    type: 'SET_PLAYER',
    payload: {
      track: null,
      isPlaying: false,
      isVisible: false,
      wasStreamPlaying: false,
    },
  });
  sessionStorage.setItem('isVisible', 'false');
  setIsPlaylistOpen(false);
};

export const setLocalPlayerState = (
  playerContext: any,
  isMobile: boolean,
  sessionVisible?: boolean
) => {
  const localPlaylist =
    typeof window !== 'undefined' ? localStorage.getItem('playlist') : null;
  if (localPlaylist !== null) {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        playlist: JSON.parse(localPlaylist),
      },
    });
  }
  !isMobile &&
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        isVisible: sessionVisible,
      },
    });
};
