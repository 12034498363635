import styled from 'styled-components';

import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  LayoutProps,
  layout,
  color,
  ColorProps,
  position,
  PositionProps,
  border,
  BorderProps,
  system,
  BackgroundColorProps,
  borderColor,
  gridTemplateColumns,
  BorderColorProps,
  backgroundImage,
  BackgroundImageProps,
  backgroundPosition,
  BackgroundPositionProps,
  backgroundSize,
  BackgroundSizeProps,
  backgroundRepeat,
  BackgroundRepeatProps,
  GridTemplateColumnsProps,
  GridTemplateRowsProps,
  gridTemplateRows,
  gridGap,
  boxShadow,
  BoxShadowProps,
} from 'styled-system';

export interface IBox
  extends ColorProps,
    PositionProps,
    LayoutProps,
    BorderProps,
    SpaceProps,
    BackgroundColorProps,
    BorderColorProps,
    BackgroundImageProps,
    BackgroundPositionProps,
    BackgroundSizeProps,
    BackgroundRepeatProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps,
    BoxShadowProps,
    FlexboxProps {
  aspectRatio?: string;
  transition?: string;
  transform?: string;
  cursor?: string;
  float?: string;
  gridGap?: string | number;
}

export const Box = styled.div<IBox>(
  space,
  flexbox,
  layout,
  color,
  position,
  border,
  borderColor,
  backgroundImage,
  backgroundPosition,
  backgroundSize,
  backgroundRepeat,
  gridTemplateColumns,
  gridTemplateRows,
  gridGap,
  boxShadow,
  system({
    backgroundColor: {
      property: 'backgroundColor',
      scale: 'palette',
    },
    borderColor: {
      property: 'borderColor',
      scale: 'palette',
    },
    borderTopColor: {
      property: 'borderTopColor',
      scale: 'palette',
    },
    borderLeftColor: {
      property: 'borderLeftColor',
      scale: 'palette',
    },
    borderRightColor: {
      property: 'borderRightColor',
      scale: 'palette',
    },
    borderBottomColor: {
      property: 'borderBottomColor',
      scale: 'palette',
    },
    aspectRatio: {
      property: 'aspectRatio',
    },
    transition: true,
    transform: true,
    cursor: true,
    float: true,
  })
);
