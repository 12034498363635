import { ConcertsSlider, Box } from 'components';
import { ArticleItem } from 'models';
import React from 'react';
import { MobileContext } from 'utils/mobileContext';

export const ConcertsSection: React.FC<{ concertsContent: ArticleItem[] }> = ({
  concertsContent,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <Box
      pt={3}
      px={0}
      mx={isMobile ? '-15px' : undefined}
      as="section"
      backgroundColor="neutral.light"
    >
      <ConcertsSlider content={concertsContent} />
    </Box>
  );
};

export default ConcertsSection;
