import { Box, SectionTitle, ArticleBox, Link, Button } from 'components';
import { noPadding } from 'helpers';
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { ArticleItem } from 'models';
import { MobileContext } from 'utils/mobileContext';
import SVG from 'react-inlinesvg';

export interface IPatronagesSection {
  content: ArticleItem[];
}

export const PatronagesSection: React.FC<IPatronagesSection> = ({
  content,
}) => {
  const { isMobile } = React.useContext(MobileContext);
  return (
    <section>
      <Box pt={3} pb={50} backgroundColor="neutral.light">
        <Container fluid md style={isMobile ? noPadding : {}}>
          <Row>
            <Col xs={12} style={isMobile ? noPadding : {}}>
              <Box
                p={3}
                backgroundColor="neutral.white"
                border="1px solid"
                borderColor="neutral.mediumGray"
                height="100%"
              >
                <Box
                  mx="15px"
                  borderBottom="1px solid"
                  borderColor="neutral.mediumGray"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  position="relative"
                >
                  <SectionTitle
                    fontSize={isMobile ? 3 : 4}
                    borderWidth={5}
                    borderColor="main.primary"
                  >
                    Patronaty Jedynki
                  </SectionTitle>
                </Box>
                <Box pt={20} pb={4}>
                  {isMobile ? (
                    <Container fluid md>
                      {content[0] && (
                        <Row>
                          <Col xs={12}>
                            <Box>
                              <ArticleBox article={content[0]} isMosaic />
                            </Box>
                          </Col>
                        </Row>
                      )}
                      {content[1] && (
                        <Row>
                          <Col xs={12}>
                            <Box
                              borderBottom="1px solid"
                              borderColor="neutral.grayWithOpacity"
                            >
                              <ArticleBox article={content[1]} isMosaic />
                            </Box>
                          </Col>
                        </Row>
                      )}

                      <Row style={{ position: 'relative' }}>
                        {content[2] && (
                          <Col xs={12}>
                            <Box
                              pb={3}
                              borderBottom="1px solid"
                              borderColor="neutral.grayWithOpacity"
                            >
                              <ArticleBox article={content[2]} isMosaic />
                            </Box>
                          </Col>
                        )}
                        <Box
                          width={120}
                          position="absolute"
                          bottom={0}
                          left="50%"
                          transform="translate(-50%, 50%)"
                          pt="30px"
                        >
                          <Link href="/artykuly/10269">
                            <Button
                              color="main.primary"
                              decorator={<SVG src="/icons/arrow_right.svg" />}
                            >
                              Więcej
                            </Button>
                          </Link>
                        </Box>
                      </Row>
                    </Container>
                  ) : (
                    <>
                      <Container fluid md>
                        <Row style={{ position: 'relative' }}>
                          {content[0] && (
                            <Col xs={4}>
                              <Box mb={5}>
                                <ArticleBox article={content[0]} isMosaic />
                              </Box>
                            </Col>
                          )}
                          {content[1] && (
                            <Col xs={4}>
                              <Box mb={5}>
                                <ArticleBox article={content[1]} isMosaic />
                              </Box>
                            </Col>
                          )}
                          {content[2] && (
                            <Col xs={4}>
                              <Box mb={5}>
                                <ArticleBox article={content[2]} isMosaic />
                              </Box>
                            </Col>
                          )}
                          <Box
                            width={120}
                            position="absolute"
                            bottom={0}
                            left="50%"
                            transform="translate(-50%, 50%)"
                          >
                            <Link
                              href={`/artykuly/10269
                              `}
                            >
                              <Button
                                color="main.primary"
                                decorator={<SVG src="/icons/arrow_right.svg" />}
                              >
                                Więcej
                              </Button>
                            </Link>
                          </Box>
                        </Row>
                      </Container>
                    </>
                  )}
                </Box>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
    </section>
  );
};

export default PatronagesSection;
